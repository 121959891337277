import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { Button } from 'react-bootstrap';
import DataService from '../services/requestApi'
import moment from 'moment';
import TillSummaryModal from './StoreinsightsModals/TillSummaryModal';
function Dailyops() {
    const style={
        boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        borderRadius:"4px",
        background:"white",
        color:"black",
        border:"0px"
      }

      const [Activetab, setActivetab] = useState('')
      const [modalShow, setModalShow] = useState(false);

          const [Hourlysale, setHourlysales] = useState('')
          const [date, setDate] = useState( moment(new Date()).format("YYYY-MM-DD"))
          // const [AvergeHourlySale, setAvergeHourlySale] = useState('')
          const [TillID, setTillID] = useState('123456')
          const { saasId, storeId,registerId } = JSON.parse(localStorage.getItem("User_data"))
          //Hourlysale sales Api Call
          const GetTillSummaryReport=async()=>{
            try {
              if (Activetab === 'TillSummary') {
                const response = await DataService.GetTillSummary(saasId, storeId, date,registerId,TillID);
                console.log("API Response for HourlySaleReport", response);
                if (response.data.status) {
                  setHourlysales(response.data.data);
                  setModalShow(true);
                }  } else if (Activetab === 'StoreSummary') {
                  const response = await DataService.GetStoreSummary(saasId, storeId, date);
                  console.log("API Response for AverageHourlySales", response);
                  setModalShow(true);
                  setHourlysales(response.data.data);
                }
              }
                catch (error) {
              console.log('this is catch', error)
          }
           }

           useEffect(() => {
             if(date){
               GetTillSummaryReport()
             }
           }, [date])
           


          const columns = [
         
            {
              name: `${Activetab=='TillSummary'?'till id':"Type"}`, 
              selector: (row) => Activetab=='TillSummary'?row.till_id:row.type ,
              sortable: true
            },
            {
              name: "Tender Name",
              selector:(row)=>row.tender_name,
              sortable: true,
            },
            {
              name: `${Activetab=='TillSummary'?'business date':"Customer IQD"}`, 
              selector: (row) => Activetab=='TillSummary'?row.business_date:row.total_customer_iqd ,
              sortable: true
            },
            {
              name: `${Activetab=='TillSummary'?'business date':"Customer USD"}`, 
              selector: (row) => Activetab=='TillSummary'?row.business_date:row.total_customer_usd ,
              sortable: true
            },
          
            {
              name:  'Total Sum',
              selector:(row)=>row.total_sum,
              sortable: true
            },
           
          ];
    
          useEffect(() => {
            if (Activetab) {
              console.log("Activetab changed:", Activetab);
              GetTillSummaryReport();
            }
          }, [Activetab]);
  return (
    <div>  <Container>
    <Row>
      <Col>
      <div>
        <img
        className='w-100 rounded-4'
         style={{
            background:"lightgray 50% / cover no-repeat",
            boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
            width: "95%",
            height: "350px",
        }} src={`https://s3-alpha-sig.figma.com/img/fe01/bd6a/2964c595892ef7ddf1f968edd85e3f99?Expires=1702252800&Signature=FpuTiN90A4zUzmwxYg8Hx9MGzFO8pSnNVQ2ljxCpYt936QgoplZUq5ut~0660DX4JnKUzrokRaJ5G~UIxRSLB-FQnIToPlg-rXZwBDg1fGFlaibFGTwPW7UC7yhbtuUdZ4GisYaRMHQ3MIC6RUrLvxrGCtGvCFTiW0e-1U3jmViEVp5NyV9QJ9tFvad4Xe3bz-ZH1OilsI0qWhwPxe4LQLkqEq2soOS7fpsP6R3ohslZXmExUhtbmBFdbCG0JXaLbCoGgOQBw0-5CORaGfe7ZuNRCJJVgYTbfXHklVwAUnGZJ2xFvIScnXAs2TqxnFKCaJwaQyiCSb5ibnsCwHCvNA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4`} alt="" />
      </div>
      </Col>
      <Col>
    
    

    
      {/* <Button
      style={style}
      className='w-100' variant="success" id="dropdown-basic"
      onClick={() =>
        { setActivetab('TillSummary')}}>
     Till Summary
      </Button> */}

     

      <Button
      style={style}
      className='w-100 mt-3' variant="success" id="dropdown-basic"
      onClick={() =>
        { setActivetab('StoreSummary')}}>
         Store Summary
      </Button>

    
  
 

      
    </Col>
    </Row>
    <TillSummaryModal date={date} setDate={setDate} show={modalShow} Activetab={Activetab} setActivetab={setActivetab} setModalShow={ setModalShow} columns={columns} data={Hourlysale} />

  </Container></div>
  )
}

export default Dailyops