import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {  Col } from "react-bootstrap";
import { BsArrowLeft } from 'react-icons/bs';
import { TextField } from '@mui/material';
import DataService from '../../../services/requestApi'
import Swal from 'sweetalert2';

const AddConcept = ({open,setOpenAddCon,GetConcept}) => {
    const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"))
const [ConName, setConName] = useState("")
const [ConCode, setConCode] = useState("")
  const data ={
    saas_id: saasId,
    store_id: storeId,
    conc_name:ConName,
    conc_code:ConCode
  }
   const AddNewConcepte =async()=>{
    try {
      if(ConName && ConCode){
        
        const response =await DataService.AddNewConcepte(data)
        console.log("this response for add concept",response)
        if(response.data.status){
            GetConcept()
            setOpenAddCon(false)
            setConName('')
            setConCode("")
            Swal.fire({
                title:"Concept Added Successfully",
                icon:"success",
                timer:2000
            })
        }
      }else{
        Swal.fire({
          title:"Please Fill All Field",
          icon:"info",
          timer:1000
        })
      }
  } catch (error) {
        console.log("this is catch ", error)
    }
   }
  return (
    <div><Modal
    isOpen={open}
    toggle={() => setOpenAddCon(!open)}
  >
    <ModalHeader>
      <BsArrowLeft
        onClick={() =>
            setOpenAddCon(!open)
        }
        className="mouse-pointer"
      />{" "}
      Add Concepte *
    </ModalHeader>
    <ModalBody>
      <div className="row d-flex justify-content-center">
        <div className="">
          <form
            className="form-box"
            // onSubmit={handleAddItem}
            // encType="multipart/form-data"
            encType="Content-Type"
          >
            <div
              className="d-flex flex-col"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Col md={12}>
              
              </Col>
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={ConName}
                onChange={(e) => setConName(e.target.value)}
                label="Concept Name"
                multiline
                required
                rows={1}
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={ConCode}
                onChange={(e) => setConCode(e.target.value)}
                label="Concept Code"
                multiline
                required
                rows={1}
              />
            </div>

            <div className="mt-2">
              <button
                type="button"
                style={{
                  backgroundColor: "rgb(46, 69, 175)",
                  outline: "none",
                  border: "none",
                  fontSize: "20px",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  color: "#fff",
                }}
                onClick={AddNewConcepte}
              >
                Add New Concept
              </button>

          
              <span
                // to="/retailer-dashboard"
                onClick={() => {
                    setOpenAddCon(!open);
                }}
                className="btn btn-primary mb-2"
                style={{
                  backgroundColor: "grey",
                  outline: "none",
                  border: "none",
                  marginLeft: "20px",
                  fontSize: "20px",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  color: "#fff",
                }}
              >
                Close
              </span>
            </div>
          </form>
          {/* )} */}
        </div>
      </div>
    </ModalBody>
    <ModalFooter>{/* <h1>FOOTER</h1> */}</ModalFooter>
  </Modal></div>
  )
}

export default AddConcept