import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import { Row, Col, Form } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import DataService from "./services/requestApi";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import moment from "moment";
export const Tillreconcile2 = () => {
  const { saasId, storeId, tillId, registerId } = JSON.parse(
    localStorage.getItem("User_data")
  );
  const navigate = useNavigate();
  // const {tillidForRecon}= useSelector((state)=> state.Reducer)
  const Ref = useRef();
  const [getTotal, setTotal] = useState({
    iqd_250: "",
    iqd_500: "",
    iqd_1000: "",
    iqd_5000: "",
    iqd_10000: "",
    iqd_25000: "",
    iqd_50000:"",
  });

  const [data, setData] = useState({
    till_id: tillId,
    iqd_250: "",
    iqd_500: "",
    iqd_1000: "",
    iqd_5000: "",
    iqd_10000: "",
    iqd_25000: "",
    iqd_50000:"",
    saad_id: saasId,
    store_id: storeId,
  });

  const userData = JSON.parse(localStorage.getItem("User_data"));
  const business_date = JSON.parse(localStorage.getItem("business_date"));

  const [user, setUser] = useState(userData);
  const IstillOpend = async () => {
    try {
      const response = await DataService.GetOpendTill(
        storeId,
        saasId,
        registerId
      );
      console.log(response.data);
      if (response.data.status) {
        const OldData = await DataService.GetOldData(
          saasId,
          storeId,
          registerId,
          business_date
        );
        console.log(OldData.data.data);
        if(OldData.data.data){
          setData({
            till_id: tillId,
            iqd_250: OldData.data.data.iqd250,
            iqd_500: OldData.data.data.iqd500,
            iqd_1000: OldData.data.data.iqd1000,
            iqd_5000: OldData.data.data.iqd5000,
            iqd_10000: OldData.data.data.iqd10000,
            iqd_25000: OldData.data.data.iqd25000,
            iqd_50000:OldData.data.data.iqd50000,
            saad_id: saasId,
            store_id: storeId,
          });
  
          setTotal({
            iqd_250: OldData.data.data.iqd250 * 250,
            iqd_500: OldData.data.data.iqd500 * 500,
            iqd_1000: OldData.data.data.iqd1000 * 1000,
            iqd_5000: OldData.data.data.iqd5000 * 5000,
            iqd_10000: OldData.data.data.iqd10000 * 10000,
            iqd_25000: OldData.data.data.iqd25000 * 25000,
            iqd_50000:OldData.data.data.iqd50000 *50000
          });
          
        }
        const updatedUser = { ...user, tillId: response.data.data.till_id };

        localStorage.setItem("User_data", JSON.stringify(updatedUser));
        setUser(updatedUser);
      } else {
        navigate("/tillopen");
        Swal.fire({
          title: "Please Open Till",
          icon: "error",
          timer: 2000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    IstillOpend();
    Ref.current.focus();
  }, []);
  const totalIQD = Object.values(getTotal).reduce(
    (sum, value) => sum + Number(value || 0),
    0
  );

  const date = moment(new Date()).format("YYYY-MM-DD");
  const [data1, setData1] = useState({
    cash: totalIQD,
    usd: "",
    saas_id: saasId,
    store_id: storeId,
    till_id: tillId,
    business_date: business_date,
  });
  useEffect(() => {
    console.log("thotal IQD ", totalIQD);
    data1.cash = totalIQD;
    setData1(data1);
  }, [totalIQD]);
  const handelonChange = (e, val) => {
    e.preventDefault();
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    setTotal({
      ...getTotal,
      [e.target.name]: e.target.value * val,
    });
    console.log(getTotal)
  };
  const TillReconcile = async () => {
    try {
      if (tillId) {
        const response = await DataService.TillReconcile(data1);
        if (response.data.status) {
          SaveCurrencyAmount();
          Swal.fire({
            title: response.data.message,
            icon: "success",
            timer: 2000,
          });
          // dispatch(SetTillForRecocile(data.till_id))
        } else {
          Swal.fire({
            title: "Data Already Saved",
            icon: "error",
          });
        }
        console.log("till Reconcile response", response);
      } else {
        Swal.fire({
          title: "Please Enter Till Id",
          icon: "error",
        });
      }
    } catch (error) {
      console.log("Till Reconcile error", error);
      Swal.fire({
        title: error.response.data.message,
        icon: "error",
      });
    }
  };

  const SaveCurrencyAmount = async () => {
    try {
      const resposne = await DataService.SaveCurrency(data);
      console.log("this Save Currency", resposne);
      if (resposne.data.status) {
        navigate("/tillreconcile5");
        // Swal.fire({
        //   title: "Data Saved",
        //   icon: "success",
        //   timer: 2000,
        // });
      }
    } catch (error) {
      console.log("this is Currency amount", error);
    }
  };
  const setUsdChange = (e) => {
    e.preventDefault();
    setData1({
      ...data1,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Navbar className="justify-content-Between">
        <Container>
          <Navbar.Collapse className="justify-content-center">
            <Navbar.Brand>Photon Softwares</Navbar.Brand>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <hr />
      <Container>
        <div className="d-flex my-5">
          <Link to={"/tilloptions"} className="text-dark">
            <AiOutlineArrowLeft />
          </Link>
          <h5 className="mx-4">Till Reconcile</h5>
        </div>

        <p>Enter the quantity for each type of IQD currencies </p>
        <Row>
          <Col md="6">
            <Form>
              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">IQD 250</p>
                    </Col>
                    <Col>
                      <span className="float-end py-2">
                        <RxCross2 />
                      </span>
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        <Form.Control
                          ref={Ref}
                          name="iqd_250"
                          value={data?.iqd_250}
                          type="number"
                          onChange={(e) => {
                            handelonChange(e, 250);
                          }}
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                      <span>={data?.iqd_250 * 250}</span>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">IQD 500</p>
                    </Col>
                    <Col>
                      <span className="float-end py-2">
                        <RxCross2 />
                      </span>
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        <Form.Control
                          name="iqd_500"
                          value={data?.iqd_500}
                          type="number"
                          onChange={(e) => {
                            handelonChange(e, 500);
                          }}
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                      <span>={data?.iqd_500 * 500}</span>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">IQD 1000</p>
                    </Col>
                    <Col>
                      <span className="float-end py-2">
                        <RxCross2 />
                      </span>
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        <Form.Control
                          name="iqd_1000"
                          value={data?.iqd_1000}
                          type="number"
                          onChange={(e) => {
                            handelonChange(e, 1000);
                          }}
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                      <span>={data?.iqd_1000 * 1000}</span>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">IQD 5000</p>
                    </Col>
                    <Col>
                      <span className="float-end py-2">
                        <RxCross2 />
                      </span>
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        <Form.Control
                          name="iqd_5000"
                          value={data?.iqd_5000}
                          type="number"
                          onChange={(e) => {
                            handelonChange(e, 5000);
                          }}
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                      <span>={data?.iqd_5000 * 5000}</span>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">IQD 10000</p>
                    </Col>
                    <Col>
                      <span className="float-end py-2">
                        <RxCross2 />
                      </span>
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        <Form.Control
                          name="iqd_10000"
                          value={data?.iqd_10000}
                          type="number"
                          onChange={(e) => {
                            handelonChange(e, 10000);
                          }}
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                      <span>={data?.iqd_10000 * 10000}</span>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">IQD 25000</p>
                    </Col>
                    <Col>
                      <span className="float-end py-2">
                        <RxCross2 />
                      </span>
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        <Form.Control
                          name="iqd_25000"
                          value={data?.iqd_25000}
                          type="number"
                          onChange={(e) => {
                            handelonChange(e, 25000);
                          }}
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                      <span>={data?.iqd_25000 * 25000}</span>
                    </Col>
                  </Row>
                </div>
              </div>
              
              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">IQD 50000</p>
                    </Col>
                    <Col>
                      <span className="float-end py-2">
                        <RxCross2 />
                      </span>
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        <Form.Control
                          name="iqd_50000"
                          value={data?.iqd_50000}
                          type="number"
                          onChange={(e) => {
                            handelonChange(e, 50000);
                          }}
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                      <span>={data?.iqd_50000 * 50000}</span>
                    </Col>
                  </Row>
                </div>
              </div>
              
              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">Total</p>
                    </Col>
                    <Col>
                      {/* <span className="float-end py-2">
                        <RxCross2 />
                      </span> */}
                    </Col>
                    <Col>
                      <div className="py-1 float-end"></div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                      <span>{totalIQD}</span>
                    </Col>
                  </Row>
                </div>
              </div>
              <Form.Control
              autoFocus
                style={{
                  height: "45px",
                  width: "380px",
                  background: "#f2f0f0",
                }}
                name="usd"
                value={data1.usd}
                onChange={setUsdChange}
                placeholder="Enter USD Cash"
              ></Form.Control>
            </Form>
          </Col>

          <Col md="6">
            <center>
              <div className="my-5">
                <Button
                  className="text-White w-50 "
                  onClick={TillReconcile}
                  variant="primary"
                >
                  <span className="float-start">Next</span>{" "}
                  <Button className=" border float-end">Enter</Button>
                </Button>
              </div>
            </center>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
