import React, { useState, useEffect } from 'react'
import './App.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Form } from 'react-bootstrap';
export const Discountoption = () => {
    const [isitemActive, setIsItemActive] = useState(false);
    const [istransactionActive, setIsTransactionActive] = useState(false);
  
    const handleItemClick = () => {
      setIsItemActive(true);
      setIsTransactionActive(false);
    };
  
    const handleTransactionClick = () => {
      setIsItemActive(false);
      setIsTransactionActive(true);
    };
    return (
        <div>
            <Navbar className="justify-content-Between">
                <Container >



                    <Navbar.Collapse className="justify-content-center"  >
                        <Navbar.Brand>
                            Photon Softwares
                        </Navbar.Brand>
                    </Navbar.Collapse>



                </Container>

            </Navbar>
            <hr ></hr>
            <Link to={'/itemsfordiscount'} style={{ color: "black", margin: "20px 0px 0px 70px" }}> < AiOutlineArrowLeft style={{ width: "30px", height: "20px" }} /></Link>
            <h5 style={{ margin: "-24px 0px 0px 120px" }}>Select an Option</h5>
            <div style={{ margin: "20px 0px 0px 80px", height: "500px", width: "500px" }}>
                <Form>
                    <Button variant="none" style={{ border: "solid lightgrey 1px", width: "248px", backgroundColor: isitemActive ? '#2E45AF' : 'white', color: isitemActive ? 'white' : 'black'  }}
        onClick={handleItemClick}>Item Wise</Button>
                    <Button variant="none" style={{ border: "solid lightgrey 1px", width: "248px" ,backgroundColor: istransactionActive ? '#2E45AF' : 'white', color: istransactionActive ? 'white' : 'black' }}
        onClick={handleTransactionClick}>Transaction Wise</Button>
                    <Row style={{ margin: "40px 0px 0px -10px" }}>
                        <Col sm={2}>
                            <input type="radio" name="percentage" value="percentage" style={{ height: "25px", width: "25px" }} /><div style={{ height: "50px", width: "50px", margin: "-30px 0px 0px 40px" }} >%</div>
                        </Col>
                        <Col>
                            <input type="radio" name="percentage" value="percentage" style={{ height: "25px", width: "25px" }} /><div style={{ height: "50px", width: "50px", margin: "-30px 0px 0px 40px" }} >AED</div></Col>
                    </Row>

                    <Form.Label>Discount(%)</Form.Label>
                    <Form.Control placeholder="0"></Form.Control>

                </Form>
                <Button className="text-dark"  variant="none" style={{height:"45px",width:"220px",border:"solid grey 1px",margin:"245px 0px 0px  0px"}}>Cancel <Button className="text-dark" style={{border:"solid grey 1px",margin:" 0px 0px 0px 90px ",height:"30px",width:"50px"}}  variant="none" > <Link to={'/discount'} style={{textDecoration:"none",color:"black"}}> Esc</Link></Button></Button>
                  <Button className="text-White"   style={{backgroundColor:"#2E45AF", height:"45px",width:"220px",border:"solid grey 1px",margin:"245px 0px 0px  17px"}}>Next <Button className="text-white" style={{backgroundColor:"#2E45AF", border:"solid grey 1px",margin:" 0px 0px 0px 90px ",height:"30px",width:"60px"}} ><Link to={'/discountapproval'} style={{textDecoration:"none",color:"white"}}> Enter </Link></Button></Button>
                
            </div>
        </div>
    )
}