import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { BiSearchAlt2 } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { AiOutlineCheckCircle } from 'react-icons/ai'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Include other necessary imports

function RegisterCloseModal(props) {
  return (
    <Modal
    {...props}

    aria-labelledby="contained-modal-title-vcenter"
    centered
>
    {/* <AiOutlineCheckCircle />

    <Modal.Body>
        <h4 > Register Closed Successfully</h4>
        <p > Press enter to continue </p>

    </Modal.Body> */}

    <Row>
        <Col>
            <Col><center><AiOutlineCheckCircle style={{ color: 'green', width: '70px', height: '70px' }} /></center></Col>
            <Modal.Body>
                <Col><center><h4> Register Close Successfully</h4></center></Col>
                <Col><center><p className='my-3'> Press enter to continue </p></center></Col>
            </Modal.Body>
        </Col>
    </Row>

</Modal>
  );
}

export default RegisterCloseModal;
