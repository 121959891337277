import moment from "moment";
import React from "react";
import Barcode from "react-barcode";
import { PrintProvider, Print } from "react-easy-print";
import { useSelector } from "react-redux";

function ReceiptModal({
  TotalAmount,
  barcodeData,
  ChangeDue,
  Tender,
  CreditId,
  newCredit,
  CutomerAmount,
  UsdAmount,
  newCoupon
}) {
  const customer = useSelector((state) => state.Reducer.LinkCustomer);
  // console.log("first", customer)
  const TotalDiscountonInvoice = useSelector(
    (state) => state.Reducer.DicountOnTotal
  );
  const displayData = useSelector((state) => state.Reducer.displayCart);
  const totalamount = displayData.reduce((sum, item) => sum + item.newPrice, 0);
  const totalitemDis = displayData.reduce(
    (sum, item) => sum + item.discount * item.productQty,
    0
  );
  console.log(totalamount);
  const TotalGross = parseInt(totalamount) + parseInt(totalitemDis);
  const totalProductQty = displayData.reduce(
    (total, item) => total + parseInt(item.productQty),
    0
  );
  const { storeName, userId, userName } = JSON.parse(
    localStorage.getItem("User_data")
  );
  const {
    haderLine1,
    haderLine2,
    haderLine3,
    haderLine4,
    futterLine1,
    futterLine2,
    futterLine3,
    futterLine4,
    futterLine5,
    futterLine6,
    futterLine7,
    futterLine8,
    futterLine9,
    futterLine10,
  } = JSON.parse(localStorage.getItem("Store_data"));
  const styles = {
    fontFamily: "Verdana",
    fontSize: "medium",
    fontWeight: "bold",
  };

  const customLineStyle = {
    border: "1px solid black",
    margin: "8px 0",
  };
  const table = {
    border: "1px solid black",
    margin: "8px 0",
  };
  const receiptContent = (
    <div style={styles}>
      <p
        style={{ fontSize: "1.6rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
        {storeName}
      </p>
      <p
        style={{ fontSize: "1.4rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
        {haderLine1}
      </p>
      <p
        style={{ fontSize: "1.25rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
        {" "}
        {haderLine2}
      </p>
      <p
        style={{ fontSize: "1.25rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
        {haderLine3}
      </p>
      <p
        style={{ fontSize: "1.25rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
        {haderLine4}
      </p>

      <div className="container mt-3" style={{ fontSize: "1.2rem" }}>
        {customer?.name && (
          <div className="col">
            <div className="col  fw-bold">
              <p className="fw-bold">Customer Name: {customer?.name}</p>
            </div>
            <div className="col  fw-bold">
              <p className="fw-bold">
                Customer Mobile Number: {customer?.mobile_number}
              </p>
            </div>
          </div>
        )}
        {/* {CreditId && <span>Credit Note: {CreditId}</span>} */}
      </div>
      <div className="fw-bold mt-5" style={customLineStyle}></div>

      <table className="w-100  mx-md-auto" style={{ fontSize: "1.2rem" }}>
        <thead>
          <tr className="border-dark" style={{ borderBottomWidth: "2px" }}>
            <th>Item</th>
            <th className="px-3">Qty</th>
            <th className="px-3">Rate</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {displayData &&
            displayData.map((el) => {
              return (
                <>
                  {" "}
                  {el.productQty > 0 && (
                    <>
                      <tr className="fw-bold">
                        <td style={{ fontSize: "1rem" }}>{el?.item_code}</td>
                        <td
                          className="text-center"
                          style={{ fontSize: "1rem" }}
                        >
                          {el.productQty}
                        </td>
                        <td style={{ fontSize: "1rem" }}>{el.actual_price}</td>
                        <td style={{ fontSize: "1rem" }}>
                          {el.actual_price * el.productQty}
                        </td>
                      </tr>
                      <tr className="fw-bold">
                        <td
                          colspan={el.discount == 0 ? "4" : "2"}
                          className="m-0"
                          style={{ fontSize: "1rem" }}
                        >
                          {el.item_name.slice(0, 25)}
                        </td>
                        {el.discount > 0 && (
                          <>
                            <td>
                              Disc {el?.Discountper && el.Discountper}
                              {el?.Discountper && "%"}
                            </td>
                            <td>{el.discount * el.productQty}</td>{" "}
                          </>
                        )}
                      </tr>
                    </>
                  )}
                </>
              );
            })}
        </tbody>
      </table>

      <div className="fw-bold" style={customLineStyle}></div>

      <div className="container" style={{ fontSize: "1.25rem" }}>
        <div className="d-flex justify-content-between fw-bold text-nowrap">
          {/* <div className="col"> */}
          <p>Qty</p>
          {/* </div> */}
          {/* <div className="col"> */}
          <p>{totalProductQty}</p>
          {/* </div> */}
          {/* <div className="col"> */}
          <p>Gross</p>
          {/* </div> */}
          {/* <div className="col"> */}
          <p>{TotalGross}</p>
          {/* </div> */}
        </div>
        {TotalDiscountonInvoice > 0 && (
          <div className="d-flex justify-content-between">
            <p>Invoice Discount</p>

            <p className="text-nowrap">
              {Math.ceil((TotalDiscountonInvoice * 100) / totalamount)} %{" "}
              {TotalDiscountonInvoice}
            </p>
          </div>
        )}
        {TotalDiscountonInvoice + totalitemDis > 0 && (
          <div className="d-flex justify-content-between">
            <p>Total Discount</p>
            <p>{TotalDiscountonInvoice + totalitemDis}</p>
          </div>
        )}
        <div className=" d-flex justify-content-between">
          <h5 className="fw-bold">Net Settlement: </h5>
          <h5 className="fw-bold">{TotalAmount}</h5>
        </div>
      </div>

      <div className="container mb-1 mt-1" style={{ fontSize: "1.2rem" }}>
        <p style={{ fontSize: "1.2rem" }}>Settlement</p>
        {Tender &&
          Tender.map((item) => {
            return (
              <div className="row fw-bold">
                {item.tender_name == "Cr Notes" ? (
                  <div className="col text-nowrap">
                    <p>
                      {item.tender_name}({CreditId})
                    </p>
                  </div>
                ) : (
                  <div className="col text-nowrap">
                    <p>{item.tender_name}</p>
                  </div>
                )}
                <div className="col">
                  <p className="float-end">{item.amount}</p>
                </div>
              </div>
            );
          })}
      </div>
      {/* {Tender.length>1&&<div className=" d-flex justify-content-between">
            <h5 className='fw-bold'>Net Settlement: </h5>
            <h5 className='fw-bold'>{TotalAmount}</h5>
          </div>}  */}

      <div className="fw-bold" style={customLineStyle}></div>

      <div className="container" style={{ fontSize: "1.2rem" }}>
        <div className="col">
          {ChangeDue && (
            <div className="col p-2 fw-bold d-flex justify-content-between">
              <h5 className="fw-bold">Change Due: </h5>
              <h5 className="fw-bold">{ChangeDue} </h5>
            </div>
          )}
          {CutomerAmount && (
            <div className="col p-2 fw-bold d-flex justify-content-between">
              <h5 className="fw-bold">Customer Cash IQD: </h5>
              <h5 className="fw-bold">{CutomerAmount} </h5>
            </div>
          )}
          {UsdAmount?.value && (
            <div className="col p-2 fw-bold d-flex justify-content-between">
              <h5 className="fw-bold">Customer Cash USD: </h5>
              <h5 className="fw-bold">{UsdAmount.value} </h5>
            </div>
          )}
        </div>
      </div>

      <div className="container" style={{ fontSize: "1.1rem" }}>
        <div className="row">
          <p
            className="text-nowrap text-center"
            style={{
              fontSize: "0.9rem",
            }}
          >
            Date: {moment(Date.now()).format("DD/MM/YYYY,h:mm:ss a")} (
            {userName})
          </p>
          <div className="d-flex justify-content-center">
            <Barcode value={barcodeData} width={2} height={50} />
          </div>
        </div>
      </div>

      <div className="fw-bold" style={customLineStyle}></div>

      <div className="container py-2 fw-bold" style={{ fontSize: "1.1rem" }}>
        <div className="row">
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {futterLine1}{" "}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine2}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {futterLine3}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine4}{" "}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {futterLine5}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine6}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine7}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine8}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine9}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine10}
          </p>
        </div>
      </div>

      {newCredit?.creditNote_id && (
        <div>
          <hr className="mb-5" style={{ color: "black" }} />
          <div
            className="container "
            style={{ fontSize: "1.25rem", marginTop: "5rem" }}
          >
            <p
              style={{ fontSize: "1.6rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {storeName}
            </p>
            <p
              style={{ fontSize: "1.4rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine1}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine2}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine3}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine4}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              REISSUED CREDIT NOTE
            </p>
            <div className="row  fw-bold">
              <div className="col">
                <div className="d-flex justify-content-center">
                  <Barcode
                    value={newCredit.creditNote_id}
                    width={2}
                    height={50}
                  />
                </div>
              </div>

              <div className="fw-bold">********************************</div>
            </div>
            <div className="d-flex justify-content-between">
              <p>amount</p>
              <span>{newCredit.credit_note_amount}</span>
            </div>
          </div>
          <div className="container" style={{ fontSize: "1.2rem" }}>
            <div className="col">
              <div className="col p-2 fw-bold">
                <p className="fw-bold">Authorised by : </p>
                <p className="fw-bold">Accounts : </p>
              </div>
            </div>
          </div>

          <div className="container" style={{ fontSize: "1.2rem" }}>
            <div className="col">
              <div className="col p-2 fw-bold">
                <p className="fw-bold">Not valid after 180 Days</p>

                <p className="fw-bold">
                  valid upto: {moment(newCredit.EXP_DATE).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
     {
       newCoupon && (<div>
        <hr className="mb-5" style={{ color: "black" }} />
        <div>
          <div
            className="container "
            style={{ fontSize: "1.25rem", marginTop: "5rem" }}
          >
            {/* <p
              style={{ fontSize: "1.6rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {storeName}
            </p>
            <p
              style={{ fontSize: "1.4rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine1}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine2}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine3}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine4}
            </p> */}
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              Congratulations You Got new Coupon !
            </p>
            <p
              style={{ fontSize: "1rem" }}
              className="d-flex  justify-content-around fw-bold text-center"
            >
              {newCoupon.description}
            </p>

            <div className="container" style={{ fontSize: "1.2rem" }}>
            <div className="col">
              <div className="col p-2 fw-bold">
                {/* <p className="fw-bold">Not valid after 180 Days</p> */}

                <p className="fw-bold text-center">
                  EXPIRES: {moment(newCoupon.expirationDate).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
          </div>


            <div className="row  fw-bold">
              <div className="col">
                <div className="d-flex justify-content-center">
                  <Barcode
                    value={newCoupon.couponCode}
                    width={2}
                    height={50}
                  />
                </div>
              </div>

              <div className="fw-bold text-center">********************************</div>
            </div>
            <div className="d-flex justify-content-between">
              <p>Discount Amount</p>
              <span>{newCoupon.discountAmount}</span>
            </div>
          </div>
          <div className="container" style={{ fontSize: "1.2rem" }}>
            <div className="col">
              <div className="col p-2 fw-bold">
                <p className="fw-bold">Authorised by : </p>
                <p className="fw-bold">Accounts : </p>
              </div>
            </div>
          </div>

          
        </div>
      </div>
      )}

    </div>
  );

  return (
    <PrintProvider>
      <Print>
        <div style={styles}>{receiptContent}</div>
      </Print>
    </PrintProvider>
  );
}

export default ReceiptModal;

// import React from 'react';
// import { PrintProvider, Print } from 'react-easy-print';

// function ReceiptModal() {
//   const styles = {
//     fontFamily: 'Verdana',
//     fontSize: 'medium',
//     fontWeight: 'bold',
//   };

//   const receiptContent = (
//     <div style={styles}>
//       <div className="fw-bold">
//         <p style={{ fontSize: '2rem' }}>V A S T R E T A I L</p>
//         <p>MAX, BABYSHOP, SHOEXPRESS,</p>
//         <p style={{ fontSize: '2rem' }}>SPLASH, GULAN MALL, ERBIL</p>
//         <p style={{ fontSize: '2rem' }}>SALES INVOICE</p>
//       </div>

//       <hr />

//       <div>
//         <p>Total: 9,500</p>
//         <p>(-) Discount 2%: -1,000</p>
//       </div>

//       <hr />

//       <div>
//         <p>Representative: Kim CEO</p>
//         <p>Business Registration Number: 000-00-00000</p>
//         <p>Representative Number: 0000-0000</p>
//         <p>Address: Some City, Some District, Some Dong, Some Ho</p>
//       </div>

//       <hr />

//       <br />

//       <div align="center">
//         <p style={{ fontWeight: 'bold' }}>Wifi: some-wifi / PW: 123123</p>
//       </div>
//     </div>
//   );

//   return (
//     <PrintProvider>
//       <Print
//       >
//        <div style={styles}>
//             {receiptContent}
//           </div>

//       </Print>
//     </PrintProvider>
//   );
// }

// export default ReceiptModal;
