import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { IoArrowBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import Bahikhata from "./ShopandPurchaseTab/Bahikhata";
import DebitNote from "./ShopandPurchaseTab/DebitNote";
import Purchase from "./ShopandPurchaseTab/Purchase";
import DeliveryChalan from "./ShopandPurchaseTab/DeliveryChalan";
import { AddSupplier } from "./ShopandPurchaseTab/AddSupplier";
import DataService from './services/requestApi'
const Shopandbuy = () => {
  const [taxes, settaxes] = useState()
  const Gettaxes=async()=>{
    try {
      const response = await DataService.GetTaxes()
      if (response.data.status) {
        settaxes(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <div className="d-flex justify-content-between">
        <Link to={"/"}>
          {" "}
          <IoArrowBack className="mt-2 fs-3" />{" "}
        </Link>
        <div></div>
      </div>
      <Tabs
        defaultActiveKey="Purchase"
        id="uncontrolled-tab-example"
        className="mb-3  fw-bold"
        fill
        style={{
          background: "#FFF",
          boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        }}
        onSelect={(key)=>{
          if(key=="Debit Note"){
            Gettaxes()
          }
        }}
      >
        <Tab eventKey="Purchase" title="Purchase">
          <Purchase />
        </Tab>
        <Tab eventKey="Debit Note" title="Debit Note">
          <DebitNote taxes={taxes}/>
        </Tab>
        <Tab eventKey="delhivery" title="Delhivery">
          <DeliveryChalan />
        </Tab>
        <Tab eventKey="BahiKhata" title="BahiKhata">
          <Bahikhata />
        </Tab>
        <Tab eventKey="Add Supplier" title="Add Suplier">
          <AddSupplier />
        </Tab>
      </Tabs>
    </>
  );
};

export default Shopandbuy;
