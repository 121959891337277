import React, { useState, useEffect } from "react";
import axios from "axios";
import { FormGroup, TextField } from "@mui/material";
import AsyncSelect from "react-select/async";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const Purchase = () => {
  const [addItem, setAddItem] = useState(false);
  const [billNumber, setBillNumber] = useState("");
  const [date, setDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedParty, setSelectedParty] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const makeApiCall = async (url, payload) => {
    try {
      const response = await axios.post(url, payload);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  const handleSupplierChange = async (selectedSupplier) => {
    try {
      const response = await makeApiCall(
        "https://posprdapi.photonsoftwares.com/prod/api/v1/inventory-master/inventory",
        {
          supplier_name: selectedSupplier.value,
          saas_id: "24",
          store_id: "24001",
        }
      );
      console.log("Inventory API Response:", response);
    } catch (error) {
      console.error("Error fetching inventory data:", error);
    }
  };

  const handleSave = async () => {
    try {
      const purchaseResponse = await axios.post(
        "https://posprdapi.photonsoftwares.com/prod/api/v1/purchase/create",
        {
          saas_id: "24",
          store_id: "24001",
          supplier_name: selectedSupplier.value,
          item_list: [
            {
              item_id: 123740,
              productQty: quantity,
            },
          ],
        }
      );
      console.log("Purchase API Response:", purchaseResponse);

      const inventoryResponse = await makeApiCall(
        "https://posprdapi.photonsoftwares.com/prod/api/v1/inventory-master/inventory",
        {
          supplier_name: selectedSupplier.value,
          saas_id: "24",
          store_id: "24001",
          // Add other payload properties as needed
        }
      );
      console.log("Inventory API Response:", inventoryResponse);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };



  const getBahikhataPartyDropdown = async () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("User_data"));
    try {
      const response = await axios.get(`http://103.139.59.207:8089/prod/api/v1/supplier/get-party-name/1/10001`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    // Fetch bahikhata party dropdown data using axios
    const fetchPartyDropdown = async () => {
      try {
        const data = await getBahikhataPartyDropdown();
        // Handle data as needed, e.g., update state
        setSupplierOptions(data.data.map((party) => ({ label: party, value: party })));
      } catch (error) {
        // Handle errors
        console.error("Error fetching party dropdown:", error);
      }
    };

    fetchPartyDropdown();
  }, []);



  

  const loadOptions = async (inputValue, callback) => {
    try {
      // Perform your API call here with the inputValue
      const { storeId, saasId } = JSON.parse(localStorage.getItem("User_data"));
      const response = await axios.get(`http://103.139.59.207:8089/prod/api/v1/search/get-result/${storeId}/${saasId}/${inputValue}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      const jsonData = response.data;
      console.log("SEARCH VALUE", jsonData);
  
      if (jsonData && jsonData.status === true) {
        // Map the received data to the format expected by AsyncSelect
        const options = jsonData.data.map((item) => ({
          value: item.item_id, // replace with the actual property you want to use as value
          label: item.item_name, // replace with the actual property you want to use as label
        }));
  
        callback(options);
      } else {
        console.error("Error fetching data:", jsonData.message);
        callback([]);
      }
    } catch (error) {
      console.error("Error:", error);
      callback([]);
    }
  };

  return (
    <>
      <div className="" style={{ height: "fit-content" }}>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-5 col-md-10 col-sm-12 px-5" style={{ boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)", borderRadius: "4px", background: "white", color: "black", border: "0px" }}>
            <form className="form-box">
              <div className="d-flex mt-3">
                <Link
                  to="/"
                  type="submit"
                  className="text-decoration-none"
                  style={{ color: "black" }}
                >
                  <i className="fa-solid fa-chevron-left mt-1"></i>
                </Link>
                <h4 style={{ fontFamily: "bold", marginLeft: "10px" }}>Add Purchase</h4>
              </div>
              <TextField
                type="text"
                className="form-control my-2"
                id="bill-number"
                size="small"
                label="Bill Number"
                value={billNumber}
                onChange={(e) => setBillNumber(e.target.value)}
              />
              <div className="container p-0">
                <div className="input-group">
                  <input
                    type="date"
                    className="form-control"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
              </div>
              <TextField
                type="text"
                className="form-control my-2"
                id="customer-name"
                size="small"
                label="Search by Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <FormGroup>
                <div styles={{ zIndex: 999 }}>
                <Select
        required={true}
        placeholder="Select Party"
        options={supplierOptions}
        onChange={(selected) => setSelectedParty(selected)}
      />
                </div>
              </FormGroup>
              <div
                style={{ boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)", borderRadius: "4px", background: "white", color: "black", border: "0px", width: "100%" }}
                onClick={() => setAddItem((state) => !state)}
              >
                <button
                  className="btn btn-outline-primary mt-3"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "30px",
                    backgroundColor: "white",
                    color: "silver",
                    border: "aliceblue"
                  }}
                >
                  <AiOutlinePlusCircle />
                  Add Item (Optional)
                </button>
              </div>
              <div>
                {addItem ? (
                  <>
                    <div className="" style={{ background: "#FFF", borderRadius: "11px", padding: "10px", marginTop: "10px" }}>
                      <div className="mt-3">
                        <div style={{ zIndex: 3 }}>
                        <AsyncSelect
  cacheOptions
  required={true}
  placeholder="Select Item"
  loadOptions={loadOptions}
  onChange={(selectedOption) => setSelectedItem(selectedOption)}
  value={selectedItem}
/>
                        </div>
                      </div>
                      <TextField
                        type="text"
                        className="form-control my-2"
                        id="customer-name"
                        size="small"
                        label="Quantity"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                      <div style={{ marginBottom: 0, paddingBottom: 0 }}>
                        <div>
                          <ul className="d-flex flex-row" style={{ listStyle: "none", marginRight: "40px" }}>
                            <li>Item 1</li>
                            <li>Item 2</li>
                            {/* ... add more list items as needed ... */}
                          </ul>
                          <div className="d-flex justify-content-around">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <FormControl component="fieldset" style={{ flex: 1 }}>
                                <RadioGroup
                                  name="options"
                                  style={{ display: 'flex', flexDirection: 'row' }}
                                >
                                  <FormControlLabel
                                    value="option1"
                                    control={<Radio />}
                                    label="Price"
                                  />
                                  <FormControlLabel
                                    value="option2"
                                    control={<Radio />}
                                    label="Stock"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>
                          <div>
                            {/* ... existing text fields ... */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="mt-5 d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block"
                  onClick={handleSave}
                  style={{
                    backgroundColor: "var(--Acc-1, #457FD4)",
                    outline: "none",
                    border: "none",
                    fontSize: "14px",
                    padding: "10px 140px",
                    borderRadius: "10px",
                    color: "#fff",
                  }}
                >
                  Save
                </button>
              </div>
              <div className="mt-2 d-flex justify-content-center">
                <Link
                  to="/"
                  type="submit"
                  className="text-decoration-none"
                  style={{
                    color: "black",
                  }}
                >
                  Cancel
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Purchase;
