import React, { useState, useEffect } from "react";
import axios from 'axios';
// import { BASEURL, host } from "../../../../URL";
import { BASEURL } from '../services/http-common';
import moment from "moment";
import Export from "react-data-table-component"
import { MdDelete, MdEdit, MdPlaylistAdd } from "react-icons/md";
import DataTable, { createTheme } from 'react-data-table-component';
import { AiOutlineRight } from "react-icons/ai"
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";

function GSTR3Report() {
    


    
    // data table
const columns = [
    {
        name: 'Nature OF Supplies',
        selector: row => row.Suppliers,
    },
    {
        name: 'Total Taxable Value',
        selector: row => row.Taxable,
    },
    {
        name: 'Integrated Tax',
        selector: row => row.IntegratedTax,
    },
    {
        name: 'Central Tax',
        selector: row => row.CentralTax,
    },
    {
        name: 'State/UT Tax',
        selector: row => row.StateUtTax,
    },
    {
        name: 'CESS',
        selector: row => row.CESS,
    },
    {
        name: 'Action',
        cell: row => {
            return (<>
                <AiOutlineRight />
            </>)
        }
    }
  ];
    const Data = [
        {
            id: 1,
            Suppliers: 'OutWard Taxable sup',
            Taxable: '0.0',
            IntegratedTax:"0.0",
            CentralTax:"0.0",
            StateUtTax:"0.0",
            CESS:"0.0"
        },
        {
            id: 2,
            Suppliers: 'Inward Suplies',
            Taxable: '0.0',
            IntegratedTax:"0.0",
            CentralTax:"0.0",
            StateUtTax:"0.0",
            CESS:"0.0"
        },
        {
            id: 3,
            Suppliers: 'Inward Suplies',
            Taxable: '0.0',
            IntegratedTax:"0.0",
            CentralTax:"0.0",
            StateUtTax:"0.0",
            CESS:"0.0"
        },
        {
            id: 4,
            Suppliers: 'Inward Suplies',
            Taxable: '0.0',
            IntegratedTax:"0.0",
            CentralTax:"0.0",
            StateUtTax:"0.0",
            CESS:"0.0"
        },
        {
            id: 5,
            Suppliers: 'Inward Suplies',
            Taxable: '0.0',
            IntegratedTax:"0.0",
            CentralTax:"0.0",
            StateUtTax:"0.0",
            CESS:"0.0"
        },
        {
            id: 6,
            Suppliers: 'Inward Suplies',
            Taxable: '0.0',
            IntegratedTax:"0.0",
            CentralTax:"0.0",
            StateUtTax:"0.0",
            CESS:"0.0"
        },
        {
            id: 7,
            Suppliers: 'Inward Suplies',
            Taxable: '0.0',
            IntegratedTax:"0.0",
            CentralTax:"0.0",
            StateUtTax:"0.0",
            CESS:"0.0"
        },
        {
            id: 8,
            Suppliers: 'Inward Suplies',
            Taxable: '0.0',
            IntegratedTax:"0.0",
            CentralTax:"0.0",
            StateUtTax:"0.0",
            CESS:"0.0"
        }
      ]

      const style={
        boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        borderRadius:"4px",
        background:"white",
        color:"black",
        border:"0px"
      }


      const downloadCSV = (Data) => {
        // Check if data is not null and has at least one row
        if (Data && Data.length > 0) {
          const csvData = Data.map((row) => Object.values(row).join(",")).join("\n");
          const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
          const link = document.createElement("a");
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "GST3.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        } else {
          // Provide feedback or handle the case where there is no data to download
          Swal.fire({
            title:"No data available for download.",
            icon:"info",
            timer:1500
          })
          // You can also show a message to the user if needed.
        }
      };
      const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(Data)} />, []);


  return (
       <div style={style} className="container mt-3">
        <Button className="float-end" onClick={()=>downloadCSV(Data)}>Export</Button>
                            <DataTable
                                columns={columns}
                                data={Data}
                                title="GSTR3 Report"
                                theme="solarized"
                                className='table-bordered'
                            />
                  </div> 
  )
}

export default GSTR3Report