import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { MdDelete,MdEdit, MdPlaylistAdd } from "react-icons/md";
import DataTable from "react-data-table-component";

import Swal from 'sweetalert2';
import CurrancyModal from './CurrancyModal';
// import StoreMasterModal from './StoreMasterModal';




function CurrancyMaster({data,GetCurrencyMaster}) {
 
  const [modalShow, setModalShow] = useState(false);

  const [activeRow, setActiveRow] = useState(false);


    const style={
        boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        borderRadius:"4px",
        background:"white",
        color:"black",
        border:"0px"
      }
      const columns = [
        {
          name: "Effective Date",
          center: true,
          selector: (row) => row.effective_date,
          cell: (row) => {
            return (
              <>
                <div>{row.effective_date}</div>
              </>
            );
          },
        },
        {
          name: "Foregin Currency",
          center: true,
          selector: (row) => row.foregin_currency,
        },
        {
          name: "Exchange Rate",
          center: true,
          selector: (row) => row.exchange_rate,
        },
        {
          name: "Base Currency",
          center: true,
          selector: (row) => row.base_currency,
        },
        {
          name: "Action",
          center: true,
          selector: (row) => {
            return (
              <>
                <div className="d-flex">
                  <div>
                    <MdEdit
                      size={22}
                      color="green"
                      className="mouse-pointer"
                      onClick={() => {
                        setActiveRow(row)
                        setModalShow((state) => !state);
                      }}
                    />
                  </div>
                </div>
              </>
            );
          },
        },
      ];
    
      
  return (
    <>
    <div>  <Container>
     {/* <StoreMasterModal     show={modalShow}
    onHide={() => setModalShow(false)}/> */}

  
    <DataTable
        columns={columns}
        responsive={true}
        fixedHeader={true}
        // fixedHeaderScrollHeight="300px"

        data={data}
        // progressPending={loading}
        pagination
        paginationServer
       
      />
      <CurrancyModal activeRow={activeRow} GetCurrencyMaster={GetCurrencyMaster}  setModalShow={setModalShow} modalShow={modalShow}/>
  </Container></div>

</>
  )
}

export default CurrancyMaster