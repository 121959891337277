import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { BiSearchAlt2 } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IoArrowBack } from "react-icons/io5";
import CustomNavbar from "./CustomNavbar";
import { BASEURL } from "../services/http-common";
import axios from "axios";
import { Input, MenuItem, TextField } from "@mui/material";
// Include other necessary imports
import DataService from "../services/requestApi";
import Swal from "sweetalert2";
function OpenRegister() {
  const userData = JSON.parse(localStorage.getItem("User_data"));
  const { saasId, storeId, userId ,userName} = JSON.parse(
    localStorage.getItem("User_data")
  );

  const [user, setUser] = useState(userData);
  const [RegId, setRegId] = useState("");
  const [getRegId, setGetRegiD] = useState([
    {register_id:"REG1"},
    {register_id:"REG2"},
    {register_id:"REG3"},
    {register_id:"REG4"},
    {register_id:"REG5"},
    {register_id:"REG6"},
    {register_id:"REG7"},
    {register_id:"REG8"},
    {register_id:"REG9"},
    {register_id:"REG10"}
  ]);
  const naviagte = useNavigate();
  const handleEnterClick = async () => {
    try {
      const data = {
        register_id: RegId,
        store_id: storeId,
        saas_id: saasId,
        cashier_id: userName,
      };
      const response = await DataService.UpdateREGlist(userId, RegId);
      console.log("category", response.data.data);
      if (response.data.status) {
        const response = await DataService.TillRegisterOpen(data);
        if(response.data.message=="Register is already open"){
          naviagte("/");
          Swal.fire({
            title: response.data.message,
            icon: "success",
          });
          return
        }
        naviagte("/tillopen");
        Swal.fire({
          title: response.data.message,
          icon: "success",
          timer: 1500,
        });

        const updatedUser = { ...user, registerId: RegId };
        localStorage.setItem("User_data", JSON.stringify(updatedUser));
        setUser(updatedUser);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   const fetchCategoryData = async () => {
  //     try {
  //       const response = await DataService.GetREGlist(saasId, storeId);
  //       console.log("firsetcategory", response.data.data);
  //       setGetRegiD(response.data.data);
  //       // if (response.data.data.length ==0 ) {
  //       //   console.log("new changes");
  //       //   naviagte("/");
  //       // }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchCategoryData();
  // }, []);
  return (
    <>
      <div className="d-flex justify-content-between">
        <Link to={"/"}>
          {" "}
          <IoArrowBack className="mt-2 fs-3" />{" "}
        </Link>
        <CustomNavbar />
        <div></div>
      </div>
      <hr />
      <div className="container mt-5 d-flex justify-content-center">
        <Col md={6}>
          <Form className="mt-5">
            <div>
            <Form.Select autoFocus aria-label="Enter Register id"
            id="register_id"
            name="register_id"
            placeholder="Register id"
            value={RegId}
            onChange={(e) => setRegId(e.target.value)}
            className="form-control my-2" >
      <option>Enter Register id</option>
      {getRegId.map((item, index) => (
      <option key={index} value={item.register_id}>{item.register_id}</option>))}
    </Form.Select>
              {/* <TextField
                focused
                select
                label="Enter Register id"
                id="register_id"
                name="register_id"
                placeholder="Register id"
                value={RegId}
                onChange={(e) => setRegId(e.target.value)}
                className="form-control my-2"
              >
                {getRegId.map((item, index) => (
                  <MenuItem key={index} value={item.register_id}>
                    {item.register_id}
                  </MenuItem>
                ))}
                
              </TextField> */}
            </div>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleEnterClick();
                setRegId("");
              }}
            >
              {" "}
              enter
            </Button>
          </Form>
        </Col>
      </div>
    </>
  );
}

export default OpenRegister;
