import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';
import DataService from '../../services/requestApi'
import Form from 'react-bootstrap/Form';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ViewLogmodal from './ViewLogmodal';


function AllDailyLog({tab}) {
    const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [logsmodalopen, setLogsmodalopen]= useState('')
    const [selectRow, setSelectRow]= useState('')

    
    const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"))
    const [logs, setLogs]= useState('')
    const Getlogs=async()=>{
      try {
        const response =await DataService.GetAllLogs(saasId,storeId,date)
        console.log("Get Average Sales", response.data.data)
        setLogs(response.data.data)
       
      } catch (error) {
        console.log("error", error)
      }
    
    }


    useEffect(() => {
      console.log(tab,date)
        if(date && tab=="DailyLogs"){
            Getlogs()
        }
      }, [tab,date])
    const style={
        boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        borderRadius:"4px",
        background:"white",
        color:"black",
        border:"0px"
      }
      const columns = [
        {
          name: "User Id",
          // center: true,
          selector: (row) => row.user_id,
          cell: (row) => {
            return (
              <>
                <div >{row.user_id}</div>
              </>
            );
          },
        },
       
        {
          name: "Event",
          center: true,
          selector: (row) => row.event,
        },
        {
          name: "Register Id",
          center: true,
          selector: (row) => row.register_id,
        },
     
     
        {
          name: "User Name",
          center: true,
          selector: (row) => row.user_name,
        },
      
        
        {
          name: "Action",
          center: true,
          selector: (row) => {
          
    
            return (
              <>
                <div className="d-flex">
                <div style={{cursor:'pointer'}}>
                <FontAwesomeIcon
                    size={22}
                    color="green"
                    className="mouse-pointer" icon={faEye} 
                    
                    onClick={()=>{setLogsmodalopen(true) 
                        setSelectRow(row)}}/>
                  </div>
    
             
                </div>
    
           
              </>
            );
          },
        },
      ];
  return (
    <div>  <Container>


<Form.Control
          type="date"
          className='w-25'
          id="exampleColorInput"
          defaultValue="#563d7c"
          title="Choose your color"
          value={date}
          onChange={(e)=>{setDate(e.target.value)}}
        />
    <DataTable
  columns={columns}
  responsive={true}
  fixedHeader={true}

  data={logs || []} // Add a conditional check here
  pagination
  paginationServer

/>

  </Container>
  <ViewLogmodal selectRow={selectRow} show={logsmodalopen} onHide={() => setLogsmodalopen(false)}/></div>
  )
}

export default AllDailyLog