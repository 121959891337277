import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { BiSearchAlt2 } from 'react-icons/bi'
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import { AiOutlineArrowLeft } from 'react-icons/ai'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch } from "react-redux";
import { incrementQuantity } from './Redux/Reducer';
import { decrementQuantity } from './Redux/Reducer';
import { Link } from "react-router-dom"
import { CiBarcode } from "react-icons/ci"
import { BsCircle } from "react-icons/bs"
import { PiCrownBold } from "react-icons/pi"
import { useSelector, } from 'react-redux'
import {AiOutlinePlus} from 'react-icons/ai'
import {AiOutlineMinus} from 'react-icons/ai'
import { RxCardStack } from 'react-icons/rx';
import discountIcon from './Images/Ticket Sale.png'
export const Cart = () => {

    const cardVal = useSelector((state) => state.Reducer.values)
    // console.log("cardval**********************",cardVal)
    const displaycartitems = useSelector((state) => state.Reducer.displayCart)
    // console.log('this is cart items',displaycartitems)
    const dispatch = useDispatch();

const totalqty=displaycartitems.reduce((acc,curr)=>acc+parseInt((curr.product_qty)),0)
const totalamount=displaycartitems.reduce((acc,curr)=>acc+parseInt((curr.product_price)),0)




    return (
        <div className='d-flex justify-content-center mt-5'
         style={{ height: "fit-content", width: "100%", marginLeft:"-30px" }}>

            <Container>
                <Row>
                    <Col sm={9} >
                        <Table >
                            <thead>

                            <tr style={{borderBottom:"solid grey 1px"}} >
                                <th>Item</th>
                                <th> Price</th>
                                <th> Quantity</th>
                                <th> Total</th>

                            </tr>
                            </thead>
                          



                            {displaycartitems.map((item) => (
                                <>
                                  
                                    <tr key={item.item_name}>

                                        <td>{item.item_name}
                                            <br />{item.item_id}</td>
                                        <td>{item.price}</td>
                                        <td><Button style={{ marginRight: "10px" ,height:"25px",width:"25px"}} onClick={()=>dispatch(incrementQuantity(item.item_id))}><AiOutlinePlus style={{margin:"-20px 0px 0px -4px "}}/></Button> {item.product_qty}<Button style={{ marginLeft: "15px",height:"25px",width:"25px" }} onClick={()=>dispatch(decrementQuantity(item.item_id))}><AiOutlineMinus style={{margin:"-20px 0px 0px -4px "}}/></Button></td>
                                        <td>{item.product_price? item.product_price : item.price}</td>

                                    </tr>
                                    <tr  style={{borderBottom:"solid grey 1px"}}>
                                        <td> <br />
                                            <Button variant="none" className="text-danger" style={{background:"#f7c8ce"}}> Remove Item</Button>
                                            <Button variant="none"  style={{ marginLeft: "20px",background:"#6dd1d6" }}> Price Override</Button>

                                        </td>

                                    </tr>
                                 
                                </>
                                
                            ))}

                        </Table>

                    </Col>

                    <Col sm={3}>
                        <div>
                            <div style={{ background: "#e07707", height: "200px", width: "350px", margin: "0px 0px 0px 30px", borderRadius: "10px" }}>
                                <Row>
                                    <Col sm={9}>
                                        <h6 style={{ margin: "15px 0px 0px 10px" }}>Your Loyalty Balance</h6>
                                        <h5 style={{ margin: "10px 0px 0px 10px" }}>Balance:{cardVal}</h5>
                                        <h6 style={{ margin: "20px 0px 0px 10px" }}> Value INR 000</h6>
                                        <h6 style={{ margin: "20px 0px 0px 10px" }}> 00 Pending Loyalties</h6>
                                        <h6 style={{ margin: "10px 0px 0px 10px" }}> 1800 0000  5484  5511</h6>
                                    </Col>
                                    <Col sm={3}>
                                        <BsCircle style={{ height: "40px", width: "40px", margin: "25px 0px 0px 0px", color: "light" }} /> <PiCrownBold style={{ height: "30px", width: "30px", margin: "-65px 0px 0px 5px" }} />

                                    </Col>
                                </Row>


                            </div>
                            <Button  variant ="none" style={{ height: "40px", width: "350px", borderRadius: "10px", background: "lightgreen", margin: "20px 0px 0px 33px" }}>
                                 <Link to={'/itemsfordiscount'} style={{ textDecoration: 'none', color: "black" }}>
                                <h5 style={{ margin: "0px 0px 0px 0px"}}
                            ><img
                    style={{ margin: "0px 0px 0px 0px" }}
                    height="20px"
                    width="20px"
                    src={discountIcon} />Discounts</h5></Link>
                    </Button>
                            <div style={{ height: "70px", width: "400px", borderRadius: "10px" }}>
                                <h6 style={{ margin: "60px 0px 0px 40px " }}> subtotal ( {totalqty} Item(s))</h6>
                                <h4 className="text-primary " style={{ marginLeft: "40px" }}> INR {totalamount ? totalamount:'0'}</h4>

                            </div>
                            <div style={{ height: "100px", width: "340px", border: "solid grey 1px", background: "blue", borderRadius: "10px", margin: "0px 0px 0px 40px" }}>
                                <Link to={'/sixth'} style={{ textDecoration: "none" }}> <h4 className="text-white" style={{ margin: "40px 0px 0px 70px" }}> CHECKOUT NOW</h4></Link>
                            </div>
                            <p style={{ marginLeft: "40px" }}>
                                Your order could earn you 100 points
                            </p>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}