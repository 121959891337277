import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import barcode from '../Images/image 34.png';
import { Form } from 'react-bootstrap';
import CustomNavbar from '../UiComponents/CustomNavbar';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { TiThMenu } from "react-icons/ti";
import ReturnWithRecipte from './ReturnWithRecipte';
import ReturnWithoutRecipte from './ReturnWithoutRecipte';
import { IoArrowBack } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RemoveReturn } from '../Redux/Reducer';
import Swal from 'sweetalert2';
import DataService from './../services/requestApi'
const Mainpage = () => {
  const [show, setShow] = useState(false);
   const dispatch = useDispatch()
   const navigate = useNavigate()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [datachange, setDatachange] = useState(true) 
  const {saasId, storeId, userType,registerId} = JSON.parse(localStorage.getItem("User_data"));
  const userData = JSON.parse(localStorage.getItem("User_data"));

  const [user, setUser] = useState(userData);
  const IstillOpend = async () => {
    try {
      const response = await DataService.GetOpendTill(storeId,saasId,registerId);
      console.log(response.data);
      if (response.data.status) {
        const updatedUser = { ...user, tillId: response.data.data.till_id };
        localStorage.setItem("User_data", JSON.stringify(updatedUser));
        setUser(updatedUser);
      } else {
        navigate("/tillopen");
        Swal.fire({
          title: "Please Open Till",
          timer: 1200,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userType == "CASHIER" ) {
      IstillOpend();
    } else {
      Swal.fire({
        title: "Only CASHIER Can Do billing",
        timer: 1500,
      });
      navigate("/");
    }
  }, [userType]);
  return (
    <>
    <div className='d-flex justify-content-between'> 
      

    <Link to={'/'}> <IoArrowBack className='mt-2 fs-3' onClick={()=>{ 
              dispatch(RemoveReturn([]));
              navigate('/')}}/> </Link> 
      <Offcanvas className="w-auto" show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Retrun</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='p-0'>
          <Col className={`my-2 p-2 ${datachange?"bg-body-secondary":""}`} style={{cursor:"pointer"}}>
          <div onClick={()=>{setDatachange(true)}} className='text-decoration-none text-dark'>Return with Receipt
         </div>         
         </Col>
          <Col className={`my-2 p-2 ${datachange?"":"bg-body-secondary"}`} style={{cursor:"pointer"}}>
          <div onClick={()=>{setDatachange(false)}} className='text-decoration-none text-dark'>Return without Receipt
         </div>         
          </Col>
          
        </Offcanvas.Body>
      </Offcanvas>
    <CustomNavbar/>
    <Button className='mt-1 mx-1' variant="primary" style={{float:"right"}} onClick={handleShow}>
      <TiThMenu  className='fs-4 m-1'/>
      </Button>
    </div>
    <hr className='m-0' />
      {datachange?<ReturnWithRecipte/>:<ReturnWithoutRecipte/>}
  </> )
}

export default Mainpage