import React from 'react'
import { Col, FormGroup, Row } from 'react-bootstrap'
import SalesOverview from './SalesOverview'
import InventorySummary from './InventorySummary'
import NumberOfUsers from './NumberOfUsers'
import ProductDetails from './ProductDetails'

const Invatory = () => {
  return (
<>
        <Row className='p-4' style={{background:"#9db2bf"}}>
            <Col md={12} className='mt-3'>
                <FormGroup>
                    <h3>
                        <b>
                            Inventory Dashboard
                        </b>
                    </h3>
                </FormGroup>
            </Col>

            <Col md={12}>
                <div className='sale-purchase-container'>
                    <SalesOverview />
                    {/* <PurchaseOverview /> */}
                    <InventorySummary />
                    <NumberOfUsers />
                </div>
            </Col>

            {/* <Col md={12} className='mb-4'>
                <SalesAndPurchaseStatistics />
            </Col> */}

            <Col md={12} className=''>
                <div className='lkksi3'>
                    <ProductDetails />
                </div>
            </Col>

        </Row>
    </>
  )
}

export default Invatory