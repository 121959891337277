import React, { useState, useEffect } from "react";
import "./App.css";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";
import { BiSearchAlt2 } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import CustomNavbar from "./UiComponents/CustomNavbar";
import ItemLookupModal from "./UiComponents/ItemLookupModal";
import RegisterModal from "./UiComponents/RegisterModal";
import RegisterCloseModal from "./UiComponents/RegisterCloseModal";
import ModalPriceCheck from "./UiComponents/ModalPriceCheck";
import CheckPriceModal from "./UiComponents/CheckPriceModal";
import userround from "./Images/User Round.png";
import downarrow from "./Images/User Rounded.png";
import bag from "./Images/bag.png";
import note from "./Images/Document Add.png";
import crown from "./Images/Crown Line.png";
import SidebarModal from "./UiComponents/SidebarModal";
import DataService from "./services/requestApi";
import Swal from "sweetalert2";
import { RiDashboard2Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { ClearDisplay, sendCommand } from "./Constant/Util";
import { AiOutlineShop } from "react-icons/ai";
import { IoMdPersonAdd } from "react-icons/io";
import AddCustomerModal from "./UiComponents/AddCustomerModal";
const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [addCustomer, setAddCustomer] = useState(false);
  const [modalPrice, setModalPrice] = useState(false);
  const [modalCheckPrice, setCheckModalPrice] = useState(false);
  const [modalRegister, setModalRegister] = useState(false);
  const [registerClose, setRegisterClose] = useState(false);
  const { openPriceCheckModel } = useSelector((state) => state.Reducer);

  const navigate = useNavigate();
  useEffect(() => {
    if (!openPriceCheckModel) {
      window.addEventListener("keydown", handleKeyPress);
    } else {
      window.removeEventListener("keydown", handleKeyPress);
    }

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [openPriceCheckModel]);

  //handel Till Register Open
  const { saasId, storeId, registerId, userType, userName } = JSON.parse(
    localStorage.getItem("User_data")
  );
  const userData = JSON.parse(localStorage.getItem("User_data"));
  const [user, setUser] = useState(userData);
  const HandeRegisterOpen = async (Id) => {
    const reg = Id ? Id : registerId;
    try {
      const data = {
        register_id: reg,
        store_id: storeId,
        saas_id: saasId,
        cashier_id: userName,
      };
      if (userType == "MANAGER" || userType == "ALL") {
        return Swal.fire({
          title: "Only Cashier Can Open Register",
          icon: "error",
     
        });
      }
      const response = await DataService.TillRegisterOpen(data);
      console.log(response);
      if (response.data.status) {
        const updatedUser = { ...user, registerId: Id };
        if (
          response.data.message !== "Register is already open" &&
          response.data.message !== "Store is still closed or not created yet"
        ) {
          localStorage.setItem("User_data", JSON.stringify(updatedUser));
        }
        setModalRegister(false);
        Swal.fire({
          title: response.data.message,
          icon: "success",
          timer: 2000,
        });
      }
    } catch (error) {
      console.log("this catch", error.response.data);
      Swal.fire({
        title: error.response.data.message,
        icon: "error",
      });
    }
  };

  //Till Regester Close
  const handelRegiterClose = async () => {
    try {
      if (userType == "MANAGER" || userType == "ALL") {
        return Swal.fire({
          title: "Only Cashier Can Close Register",
          icon: "error",
        
        });
      }
      const response = await DataService.TillRegisterClose(
        saasId,
        storeId,
        registerId
      );

      console.log("this console response", response);
      if (response.data.status) {
        localStorage.clear();
        navigate("/login");
        Swal.fire({
          title: response.data.message,
          icon: "success",
          timer: 2000,
        });
      } else {
        Swal.fire({
          title: response.data.message,
          icon: "error",
        
        });
      }
    } catch (error) {
      console.log("close", error);
    }
  };

  const toggleModal = () => setShowModal(!showModal);
  const handleKeyPress = (e) => {
    if (e.keyCode === 68) {
      // 116 is the key code for F5
      e.preventDefault();
      navigate("/dashbord");
      // setCheckModalPrice(true)
    }
    if (e.keyCode === 117) {
      // 116 is the key code for F5
      e.preventDefault();
      setModalShow(true);
    }

    if (e.keyCode === 76) {
      e.preventDefault(); // Prevent the default browser behavior
      navigate("/welcomesearch"); // Navigate to the other page
    }
    if (e.keyCode === 86) {
      e.preventDefault(); // Prevent the default browser behavior
      navigate("/scan"); // Navigate to the other page
    }
    if (e.keyCode === 69) {
      e.preventDefault(); // Prevent the default browser behavior
      if(saasId == 1){
        navigate('/AddLoytaltiCutm')
      }else{
        navigate("/enrollform"); // Navigate to the other page

      }
    }
    if (e.keyCode === 82) {
      e.preventDefault(); // Prevent the default browser behavior
      navigate("/return"); // Navigate to the other page
    }
    if (e.keyCode === 119) {
      e.preventDefault(); // Prevent the default browser behavior
      navigate("/startoftheday"); // Navigate to the other page
    }
    if (e.keyCode === 120) {
      // 120 is the key code for F9
      e.preventDefault();
      setModalRegister(true);
    }
    if (e.keyCode === 121) {
      e.preventDefault(); // Prevent the default browser behavior
      navigate("/tilloptions"); // Navigate to the other page
    }
    if (e.keyCode === 122) {
      e.preventDefault();
      // handelRegiterClose();
      return  
    }
    if (e.keyCode === 123) {
      // 123 is the key code for F12
      e.preventDefault();
      navigate("/endofday");
    }
    if (e.key === "s" || e.key === "S") {
      navigate("/shopandbuy");
    }
  };

  // <---------------------------------------Diplay To Port ------------------------->
  const [selectedPort, setSelectedPort] = useState(null);
  const { DisplaPort } = useSelector((state) => state.Reducer);
  const [alluse, setallUse] = useState();
  useEffect(() => {
    if (DisplaPort.length > 0 && !selectedPort) {
      // For simplicity, selecting the first available port
      const firstPort = DisplaPort[0];
      console.log("Using port:", firstPort);
      setSelectedPort(firstPort);

      // Open the selected port
      openSelectedPort(firstPort);
    }
  }, [DisplaPort]);

  const openSelectedPort = async (port) => {
    try {
      await port.open({ baudRate: 9600 });
      console.log("Serial port opened successfully.");
    } catch (error) {
      console.error("Error opening serial port:", error.message);
    }
  };

  useEffect(() => {
    if (selectedPort) {
      // sendCommand("",selectedPort); // Replace 'clear' with the command to clear the screen
      ClearDisplay(selectedPort);
      setTimeout(() => {
        sendCommand("     Welcome To          vast Retail", selectedPort);
      }, 5000);
    }
  }, [selectedPort]);
  
  const cardDetails = [
    {
      index: 1,
      background: "#e07707",
      route: "welcomesearch",
      subtitle: "CHECKOUT",
      title: "Continue as a Loyalty member",
      image: crown,
      description: "View your privileges and start checkout",
      buttonText: "L",
    },
    {
      index: 2,
      background: "rgb(160, 255, 144)",
      route: "scan",
      subtitle: "CHECKOUT",
      title: "Continue as a Visitor",
      image: userround,
      description: "Start here",
      buttonText: "V",
    },
    {
      index: 3,
      background: "#B0DDFD",
      route: "enrollform",
      subtitle: "ENROLLMENT",
      title: "Would you like to Signup",
      image: note,
      description: "Signup to receive privileges, points and more",
      buttonText: "E",
    },
    {
      index: 4,
      background: "#FFEBEA",
      route: "return",
      subtitle: "Return",
      title: "Return Item",
      image: downarrow,
      description: "Return Your Items",
      buttonText: "R",
    },
    {
      index: 6,
      background: "rgb(239 227 141)",
      route: "shopandbuy",
      subtitle: "Shop and Buy",
      title: "Explore and purchase products",
      image: bag,
      description: "Discover a variety of items for purchase",
      buttonText: "S",
    },
    {
      index: 5,
      background: "#5F6F52",
      route: "Dashbord",
      subtitle: "Dashbord",
      title: "Check Your Daily Sales",
      icon: <RiDashboard2Line style={{ fontSize: "90px" }} />,
      description: "Your Store DashBord",
      buttonText: "D",
    },
  ];
  return (
    <div>
      <CustomNavbar onMenuButtonClick={toggleModal} />
      <hr className="m-0" />
      <Container>
        <center>
          <Row>
          {cardDetails.map((card) => (
  <Col key={card.index} md={4} className="my-4">
    <Card
      style={{
        width: "370px",
        height: "15rem",
        background:
          card.index === 3 ? undefined : card.background, // no background for index 3
      }}
    >
      {card.index === 3 ? (
        <div style={{ display: "flex", height: "100%" }}>
          {/* Left side (Purple) */}
          <div
            style={{
              flex: 1,
              backgroundColor: "#bf4fbf",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => setAddCustomer(true)} // navigate to addCustomer
          >
            <Card.Body>
              <Card.Subtitle className="mb-3" style={{ fontSize: "13px" }}>
                ENROLLMENT
              </Card.Subtitle>
              <div className="d-flex align-items-center justify-content-center">
              
                <IoMdPersonAdd style={{ fontSize: "90px" }}  />
             
            </div>
              <Card.Title style={{ fontSize: "20px" }}>
                Add Customer
              </Card.Title>
            </Card.Body>
          </div>

          {/* Right side (Blue) */}
          <div
            style={{
              flex: 1,
              backgroundColor: card.background,
              color: "white",
              cursor: "pointer",
            }}
            onClick={() =>  navigate("/enrollform")} // navigate to enrollform
          >
            <Card.Body>
              <Card.Subtitle className="mb-3" style={{ fontSize: "13px" }}>
                ENROLLMENT
              </Card.Subtitle>
              <div className="d-flex align-items-center justify-content-center">
              {card.image ? (
                <img
                  height="80px"
                  width="80px"
                  src={card.image}
                  alt={`icon-${card.index}`}
                />
              ) : (
                card.icon
              )}
            </div>
              <Card.Title style={{ fontSize: "20px" }}>
                Would you like to Signup
              </Card.Title>
            </Card.Body>
          </div>
        </div>
      ) : (
        <Link
          to={`/${card.route}`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <Card.Body>
            <Card.Subtitle className="mb-3" style={{ fontSize: "13px" }}>
              {card.subtitle}
            </Card.Subtitle>
            <Card.Title style={{ fontSize: "20px" }}>{card.title}</Card.Title>
            <div className="d-flex align-items-center justify-content-center">
              {card.image ? (
                <img
                  height="80px"
                  width="80px"
                  src={card.image}
                  alt={`icon-${card.index}`}
                />
              ) : (
                card.icon
              )}
            </div>
            <Card.Text
              className="fst-italic mb-3 my-4"
              style={{ fontSize: "12px" }}
            >
              {card.description}
            </Card.Text>
            <Button
              variant="none"
              style={{
                border: "solid grey 1px",
                margin:
                  card.index === 2
                    ? "-80px 0px 0px 340px"
                    : card.index === 6
                    ? "-70px 0px 0px 340px"
                    : "-70px 0px 0px 340px",
                position: "relative",
                left: "-40px",
              }}
            >
              {card.buttonText}
            </Button>
          </Card.Body>
        </Link>
      )}
    </Card>
  </Col>
))}
          </Row>
        </center>
      </Container>

      <>
        <SidebarModal
          handelRegiterClose={handelRegiterClose}
          showModal={showModal}
          toggleModal={toggleModal}
          setModalRegister={setModalRegister}
          setRegisterClose={() => setRegisterClose(true)}
        />
        <ItemLookupModal show={modalShow} onHide={() => setModalShow(false)} />
        <RegisterModal
          HandeRegisterOpen={HandeRegisterOpen}
          show={modalRegister}
          onHide={() => setModalRegister(false)}
        />
        <RegisterCloseModal
          show={registerClose}
          onHide={() => setRegisterClose(false)}
        />
        <ModalPriceCheck
          show={modalPrice}
          onHide={() => setModalPrice(false)}
        />
        <AddCustomerModal show={addCustomer} onHide={()=>setAddCustomer(false)}  isHome={true}/>
      </>
    </div>
  );
};
export default Home;
