import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { Button } from 'react-bootstrap';
import DataService from '../services/requestApi'
import moment from 'moment';
import HourlySaleModal from './StoreinsightsModals/HourlySaleModal';

function HourSale() {
  const [Activetab, setActivetab] = useState('')
  const [modalShow, setModalShow] = useState(false);
    const style={
        boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        borderRadius:"4px",
        background:"white",
        color:"black",
        border:"0px"
      }

      const [Hourlysale, setHourlysales] = useState('')
      const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    
      const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"))
      // const businessDate = moment(new Date()).format("YYYY-MM-DD");
      //Hourlysale sales Api Call
      const GetHourlySales=async()=>{
        try {
          if (Activetab === 'HourlySaleReport') {
            const response = await DataService.HourlySales(saasId, storeId, date);
            console.log("API Response for HourlySaleReport", response);
            if (response.data.status) {
              setHourlysales(response.data.data);
              setModalShow(true);
            }
          } else if (Activetab === 'AverageHourly') {
            const response = await DataService.AverageHourlySales(saasId, storeId, date);
            console.log("API Response for AverageHourlySales", response);
            setModalShow(true);
            setHourlysales(response.data.data);
          }
          
          
      } catch (error) {
          console.log('this is catch', error)
      }
      }
      useEffect(() => {
        if(date){
          GetHourlySales()
        }
      }, [date])
      
      const columns = [
        {
          name: "Business Date",
          selector: (row) => row.business_date,
          sortable: true
        },
        {
          name: "Hours in Store", 
          selector:(row)=>row.hours_of_the_day,
          sortable: true
        },
        {
          name: "Total Invoices",
          selector:(row)=>row.total_invoices,
          sortable: true,
        },
        {
          name:  `${Activetab=='HourlySaleReport'?'Total Net Sales':'Total Item Net'}`,
          selector: (row) => Activetab=='HourlySaleReport'?row.total_net_value:row.total_item_net ,
          sortable: true
        },
        {
          name:  `${Activetab=='AverageHourly'?'Total Item Qty':""}`,
          selector: (row) => Activetab=='AverageHourly'?row.total_item_qty:"" ,
          sortable: true
        },
        {
          name:  `${Activetab=='AverageHourly'?'ATV':''}`,
          selector: (row) => Activetab=='AverageHourly'?row.atv?.toFixed(0):"" ,
          sortable: true
        },
        {
          name:  `${Activetab=='AverageHourly'?'UPT':''}`,
          selector: (row) => Activetab=='AverageHourly'?row.upt?.toFixed(0):"" ,
          sortable: true
        },
      ];

      useEffect(() => {
        if (Activetab) {
          console.log("Activetab changed:", Activetab);
          GetHourlySales();
        }
      }, [Activetab]);
  return (
    <div>  <Container>
    <Row>
      <Col>
      <div>
        <img
        className=' rounded-4'
         style={{
            background:"lightgray 50% / cover no-repeat",
            boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
            width: "90%",
            height: "350px",
        }} src={`https://s3-alpha-sig.figma.com/img/7df4/e97c/f61b06ff9043de4c190c700f031889c7?Expires=1702252800&Signature=Tp9V9umkXrQ3WTGADT26FZXnL4LIV56jVHR2syZxwE6Ao2MjHo~4I~Mb5GGS7sZZNmK1yN-eaqA~sZJwqFMnsUCFLTWNQPQni9kliL4mJO0IAWQcMrBPYhFNSX0a-uwshbePinlQb1ieBqCasNj-9IYp39DoFRCbd70n8O7F9v5YVt~kfG49TJU7fAA~pBGZY2bfURZegqVPH32fkSJ0V9HGoEdPdQQlWpR5giV8lSTDZVQjXXcFCdcH5oItYDe59b2K5e44MEWwvoNZiQaecZbHXKOYVGjMJWHatgplzy4nsyexyb4~WJTdBcrDQEx9n8nQw-bBGXHD-2Da5vMaYA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4`} alt="" />
      </div>
      </Col>
      <Col>
    
    

    
      <Button
      style={style}
      className='w-100' variant="success" id="dropdown-basic" 
      onClick={() =>
        { setActivetab('HourlySaleReport')}}>
        Hourly SaleReport
      </Button>

   
      <Button
  style={style}
  className='w-100 mt-3' 
  variant="success" 
  id="dropdown-basic" 
  onClick={() => {
    console.log("Button clicked - Average Hourly");
    setActivetab('AverageHourly');
  }}
>
  Average Hourly 
</Button>
     
    </Col>
    </Row>
    <HourlySaleModal date={date} setDate={setDate} show={modalShow} Activetab={Activetab} setActivetab={setActivetab}
     setModalShow={ setModalShow} columns={columns} data={Hourlysale} />

  </Container></div>
  )
}

export default HourSale