import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { AiOutlineArrowRight } from 'react-icons/ai'
import Button from 'react-bootstrap/Button'
import { Row, Col, Form } from 'react-bootstrap'
import { RxCross2 } from 'react-icons/rx'
import DataService from './services/requestApi'
import Swal from 'sweetalert2';
export const Tillreconcile3 = () => {
    const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"))
    const [data, setData] = useState({
        usd:"",
        sar:"",
        jod:"",
        egp:"",
        gbp:"",
        orm:"",
        qar:"",
        bhd:"",
        kwd:"",
        eur:"",
        store_id:storeId,
        saas_id:saasId
    })

    const SaveForignCurrency=async()=>{
        try {
            const response = await  DataService.SaveForeigncurrency(data)
            console.log("this is SaveForignCurrency",response)
            if (response.data.status) {
                Swal.fire({
                    title:"Data Saved",
                    icon:'success',
                    timer:2000
                })
                setData()
            }
        } catch (error) {
            console.log("this Save Currency",error)
        }
    }

    //onchange function
    const handelonChange=(e)=>{
        e.preventDefault()
        setData({
        ...data,
        [e.target.name]: e.target.value
      })
    }
    return (
        <div>
            <Navbar className="justify-content-Between">
                <Container >
                    <Navbar.Collapse className="justify-content-center"  >
                        <Navbar.Brand>
                            Photon Softwares
                        </Navbar.Brand>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <hr />
            <Container>

                <div className='py-3 d-flex'>
                    <Link to={'/tilloptions'} className='text-dark'>
                        < AiOutlineArrowLeft className='fs-4' />
                    </Link>
                    <h5 className='mx-5'>Till Reconcile</h5>
                </div>
                <p>Enter foreign currency to count </p>
                <Row>
                   
                    <Col md={4}>
                        <Col md='12 py-1'>
                            <div style={{ width: '430px', background: "#f2f0f0", height: "75px", borderRadius: '7px' }} className=''>
                                <h6 className='px-2'>USD <span className='float-end fs-5'><AiOutlineArrowRight /></span></h6>
                                <div className='px-3'>
                                    <Form.Control name='usd' value={data?.usd} onChange={handelonChange} placeholder='Enter Amount' ></Form.Control>
                                </div>
                            </div>
                        </Col>


                        <Col md='12 py-1'>
                            <div style={{ width: '430px', background: "#f2f0f0", height: "75px", borderRadius: '7px' }} className=''>
                                <h6 className='px-2'>SAR <span className='float-end fs-5'><AiOutlineArrowRight /></span></h6>
                                <div className='px-3'>
                                    <Form.Control name='sar' value={data?.sar} onChange={handelonChange} placeholder='Enter Amount' ></Form.Control>
                                </div>
                            </div>
                        </Col>


                        <Col md='12 py-1'>
                            <div style={{ width: '430px', background: "#f2f0f0", height: "75px", borderRadius: '7px' }} className=''>
                                <h6 className='px-2'>JOD <span className='float-end fs-5'><AiOutlineArrowRight /></span></h6>
                                <div className='px-3'>
                                    <Form.Control name='jod' value={data?.jod} onChange={handelonChange} placeholder='Enter Amount' ></Form.Control>
                                </div>
                            </div>
                        </Col>

                        <Col md='12 py-1'>
                            <div style={{ width: '430px', background: "#f2f0f0", height: "75px", borderRadius: '7px' }} className=''>
                                <h6 className='px-2'>EGP <span className='float-end fs-5'><AiOutlineArrowRight /></span></h6>
                                <div className='px-3'>
                                    <Form.Control name='egp' value={data?.egp} onChange={handelonChange} placeholder='Enter Amount' ></Form.Control>
                                </div>
                            </div>
                        </Col>
                    </Col>


                    <Col md={4}>
                        <div className=''>
                            <Col md='12 py-1'>
                                <div style={{ width: '430px', background: "#f2f0f0", height: "75px", borderRadius: '7px' }} className=''>
                                    <h6 className='px-2'>GBP <span className='float-end fs-5'><AiOutlineArrowRight /></span></h6>
                                    <div className='px-3'>
                                        <Form.Control name='gbp' value={data?.gbp} onChange={handelonChange} placeholder='Enter Amount' ></Form.Control>
                                    </div>
                                </div>
                            </Col>

                            <Col md='12 py-1'>
                                <div style={{ width: '430px', background: "#f2f0f0", height: "75px", borderRadius: '7px' }} className=''>
                                    <h6 className='px-2'>OMR <span className='float-end fs-5'><AiOutlineArrowRight /></span></h6>
                                    <div className='px-3'>
                                        <Form.Control name='orm' value={data?.orm} onChange={handelonChange} placeholder='Enter Amount' ></Form.Control>
                                    </div>
                                </div>
                            </Col>

                            <Col md='12 py-1'>
                                <div style={{ width: '430px', background: "#f2f0f0", height: "75px", borderRadius: '7px' }} className=''>
                                    <h6 className='px-2'>QAR <span className='float-end fs-5'><AiOutlineArrowRight /></span></h6>
                                    <div className='px-3'>
                                        <Form.Control name='qar' value={data?.qar} onChange={handelonChange} placeholder='Enter Amount' ></Form.Control>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </Col>


                    <Col md={4}>
                        <div className=''>
                            <div className=''>
                                <Col md='12 py-1'>
                                    <div style={{ width: '430px', background: "#f2f0f0", height: "75px", borderRadius: '7px' }} className=''>
                                        <h6 className='px-2'>BHD <span className='float-end fs-5'><AiOutlineArrowRight /></span></h6>
                                        <div className='px-3'>
                                            <Form.Control name='bhd' value={data?.bhd} onChange={handelonChange} placeholder='Enter Amount' ></Form.Control>
                                        </div>
                                    </div>
                                </Col>

                                <Col md='12 py-1'>

                                    <div style={{ width: '430px', background: "#f2f0f0", height: "75px", borderRadius: '7px' }} className=''>
                                        <h6 className='px-2'>KWD <span className='float-end fs-5'><AiOutlineArrowRight /></span></h6>
                                        <div className='px-3'>
                                            <Form.Control name='kwd' value={data?.kwd} onChange={handelonChange} placeholder='Enter Amount' ></Form.Control>
                                        </div>
                                    </div>
                                </Col>

                                <Col md='12 py-1'>

                                    <div style={{ width: '430px', background: "#f2f0f0", height: "75px", borderRadius: '7px' }} className=''>
                                        <h6 className='px-2'>EUR <span className='float-end fs-5'><AiOutlineArrowRight /></span></h6>
                                        <div className='px-3'>
                                            <Form.Control name='eur' value={data?.eur} onChange={handelonChange} placeholder='Enter Amount' ></Form.Control>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </Col>
                </Row>


                <Row>
                    <Col md={6}>
                        {/* <center> */}
                            <div className='py-3'>
                                <Button className="text-White  border  mx-2" variant="none" style={{ width: '200px' }}><span className='float-start my-2'>Skip</span> <Button className=" border float-end text-dark" variant='none' >Esc</Button></Button>
                                <Link to={'/tillreconcile4'} style={{ textDecoration: "none", color: 'white' }}> <Button className="text-White  border  mx-2" onClick={SaveForignCurrency} variant="primary" style={{ width: '200px' }} ><span className='float-start my-2'>Next</span> <Button className=" border float-end text-white" >Enter</Button></Button></Link>
                            </div>
                        {/* </center> */}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}