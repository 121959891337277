import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { MdDelete,MdEdit, MdPlaylistAdd } from "react-icons/md";
import DataTable from "react-data-table-component";
import SalesManUpdateModal from './StoreinsightsModals/SalesManUpdateModal';
import Swal from 'sweetalert2';
import DataService from '../services/requestApi'
import AddSalesmanMadal from './StoreinsightsModals/AddSalesmanMadal';

function SalesMan({data,SalesManData,count, handlePageChange}) {
    //Update Concept Functionlity
    const [addSaleman, setAddSalesMan] = useState(false)
    const [OpenUpdate, setOpenUpdate] = useState(false)
    const [SelectedRow, setSelectedRow] = useState("")
   
  const deleteSalesMan = async (sales_man_id) => {
    try {
      const response = await DataService.deleteSalesMan(sales_man_id); // Assuming you have this function in your DataService
      console.log("Delete Concept", response.data);
      if (response.data.status) {
        SalesManData()
        Swal.fire({
          title:"SalesMan Delete Successfully",
          icon:"success",
          timer:2000
      })
      SalesManData()
      }
      // Optionally, you can update the state or perform any other actions after successful deletion.
    } catch (error) {
      console.error("Error deleting concept", error);
    }
  };



    const style={
        boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        borderRadius:"4px",
        background:"white",
        color:"black",
        border:"0px"
      }
      const columns = [
        {
          name: "Sales Name",
          // center: true,
          selector: (row) => row.sales_man_name,
          cell: (row) => {
            return (
              <>
                <div>{row.sales_man_name}</div>
              </>
            );
          },
        },
   
        {
          name: "Concept Id",
          center: true,
          selector: (row) => row.conc_id,
        },
        {
          name: "Commission",
          center: true,
          selector: (row) => row.commission,
        },
    
        {
          name: "Sales Man id",
          center: true,
          selector: (row) => row.sales_man_id,
        },
        {
          name: "Store Id",
          center: true,
          selector: (row) => row.store_id,
        },
        {
          name: "Status",
          center: true,
          selector: (row) => row.status ,
        },
       
        {
          name: "Action",
          center: true,
          selector: (row) => {
         
    
    
            return (
              <>
                <div className="d-flex">
                <div style={{cursor:'pointer'}}>
                    <MdPlaylistAdd
                      size={22}
                      color="green"
                      className="mouse-pointer"
                      onClick={() => setAddSalesMan(true)}
                    />
                  </div>
    
                  <div style={{cursor:'pointer'}}>
                    <MdDelete
                      size={22}
                      color="red"
                      className="mouse-pointer"
                      onClick={() => deleteSalesMan(row.sales_man_id)}
                    />
                  </div>
    
                  <div style={{cursor:'pointer'}}>
                    <MdEdit
                      size={22}
                      color="var(--primary1)"
                      className="mouse-pointer"
                      onClick={() => {
                        setOpenUpdate(true)
                        setSelectedRow(row)
                      }}
                    />
                  </div>
                </div>
    
                {/* <AddItem
                  addUpdateItemModalIsOpen={addUpdateItemModalIsOpen}
                  setAddUpdateItemModalIsOpen={setAddUpdateItemModalIsOpen}
                  row={row}
                  setFlag={setFlag}
                  flag={flag}
                /> */}
              </>
            );
          },
        },
      ];
  return (
    <div>  <Container>
      <SalesManUpdateModal open={OpenUpdate} SalesManData={SalesManData} setOpenUpdate={setOpenUpdate} row ={SelectedRow}/>
      <AddSalesmanMadal open={addSaleman} SalesManData={SalesManData} setAddSalesMan={setAddSalesMan} />

  
    <DataTable
        columns={columns}
        responsive={true}
        fixedHeader={true}
        // fixedHeaderScrollHeight="300px"

        data={data}
        // progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={count}
        // onChangeRowsPerPage={10}
        onChangePage={(page) => {handlePageChange(page)}}
      />
  </Container></div>
  )
}

export default SalesMan