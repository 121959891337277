import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { loginDetails } from "./Redux/Reducer";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import axios from "axios";
import crown from "./Images/Group 19.png";
import { Form, Button, Col, Row } from "react-bootstrap";
import { BASEURL } from "./services/http-common";
import DataService from "./services/requestApi";
import moment from "moment";
import Swal from "sweetalert2";

export const Login = () => {
  const date = moment(new Date()).format("YYYY-MM-DD")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const focusRef = useRef();
  const [readOnly, setReadOnly] = useState(true);
  const [userDetails, setUserDetails] = useState({
    user_name: "",
    password: "",
  });
  const { user_name, password } = userDetails;

  const changeHandler = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    focusRef.current.focus();
  }, []);
  useEffect(() => {
    // Clear input fields on page load
    document.getElementById("username").value = "";
    document.getElementById("password").value = "";

    // Ensure autocomplete is off for the form
    document.getElementById("loginForm").setAttribute("autocomplete", "off");
  }, []);
  const subHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASEURL.ENDPOINT_URL}/auth/user-login`,
        userDetails
      ); 
      if(response.data.status){
        if(response.data.data.user_data.userType=="CASHIER"){
          const response1 = await DataService.GetAllOpenRegisterAndtill(response.data.data.user_data.saasId,response.data.data.user_data.storeId,response.data.data.business_date)
          console.log(response1.data.data.store_status.length)
          if(response1.data.data.store_status.length == 0 || response1.data.data.store_status[0].store_open_close_status == "CLOSE"){
           console.log("cashirepart",response1.data.data.store_status)
            return Swal.fire({title:"Store is Not Open",  text:"Please Ask Manager", icon:"error" })
      
          }
        }

      
      console.log("API response:", response.data);
      localStorage.setItem("token", response.data.data.jwt_response);
      localStorage.setItem(
        "User_data",
        JSON.stringify(response.data.data.user_data)
      );
      localStorage.setItem(
        "Store_data",
        JSON.stringify(response.data.data.store_data)
      );
      localStorage.setItem(
        "Customer_data",
        JSON.stringify(response.data.data.customer_data)
      );
      localStorage.setItem(
        "business_date",
        JSON.stringify(response.data.data.business_date)
      );
      if(response.data.data.user_data.userType=="CASHIER"){
        navigate("/openregister");

      }else{

        navigate("/");
      }
      dispatch(loginDetails(response.data.data));
    }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <Navbar>
        <Container>
          <Navbar.Collapse className="justify-content-center">
            <h5>Photon Softwares</h5>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <hr />
      <div className="image-container text-center">
        <img
          className="img-fluid"
          src={crown}
          alt="Crown Logo"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </div>

      <Container>
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6}>
            <h4 style={{ textAlign: "center", margin: "10px 0px 30px" }}>
              LOGIN
            </h4>
            <Form
              id="loginForm"
              onSubmit={(e) => subHandler(e)}
              autoComplete="off"
            >
              <Form.Group controlId="formUsername">
                <Form.Label style={{ color: "grey", marginTop: "10px" }}>
                  Username
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  name="user_name"
                  value={user_name}
                  autoFocus
                  onChange={(e) => changeHandler(e)}
                  autoComplete="off"
                  id="username"
                  readOnly={readOnly}
                  onFocus={() => setReadOnly(false)}
                  onBlur={() => setReadOnly(true)}
                  ref={focusRef}
                />
              </Form.Group>
              <Form.Group controlId="formPassword">
                <Form.Label style={{ color: "grey" }}>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="password"
                  value={password}
                  onChange={(e) => changeHandler(e)}
                  autoComplete="off"
                  id="password"
                  readOnly={readOnly}
                  onFocus={() => setReadOnly(false)}
                  onBlur={() => setReadOnly(true)}
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                style={{ width: "100%", marginTop: "20px" }}
              >
                Login
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
