import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { BiSearchAlt2 } from 'react-icons/bi'
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { AiOutlineArrowRight } from 'react-icons/ai'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CiBarcode } from "react-icons/ci"
import { BsCircle } from "react-icons/bs"
import { PiCrownBold } from "react-icons/pi"
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios'
export const Enrollment = () => {
    return (

        <div >
            <Container >
                <Navbar>
                    <Navbar.Collapse className="justify-content-center"  >
                        <Navbar.Brand>
                            Photon Softwares
                        </Navbar.Brand>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
            <hr />
            <div className='p-3'>
                <Link to={'/'} className='float-start' style={{ color: 'black' }}> < AiOutlineArrowLeft style={{ width: "30px", height: "20px" }} /></Link>
            </div>
            <Container>
                <Row className='  p-5'>
                    <Col >
                        <div >
                            <div className='d-flex align-items-center justify-content-center py-3'> <PiCrownBold style={{ height: "40px", width: "40px" }} /></div>
                            <h5 style={{ textAlign: 'center' }}>Welcome to Loyalty</h5>
                            <p style={{ textAlign: 'center' }} className='my-4'> Enter your mobile number to continue</p>
                            <Form className='d-flex align-items-center justify-content-center p-4'>
                                <InputGroup className="" style={{ width: "600px", height: "20px" }}>
                                    <DropdownButton
                                        variant="outline-secondary"
                                        title="+91"
                                        id="input-group-dropdown-1"
                                    >
                                        <Dropdown.Item >+91</Dropdown.Item>
                                        <Dropdown.Item >+1</Dropdown.Item>
                                        <Dropdown.Item >+44</Dropdown.Item>
                                        <Dropdown.Divider />

                                    </DropdownButton>
                                    <Form.Control aria-label="Text input with dropdown button" placeholder="Enter Mobile Number" />
                                    <Link to={'/accountverify'}>  <Button type="Submit" variant="primary" style={{backgroundColor:"#2E45AF"}}><AiOutlineArrowRight /></Button></Link>

                                </InputGroup>

                            </Form>
                            <div className='d-flex align-items-center justify-content-center p-3' >
                               <Link style={{ textDecoration: 'none' }}> Or use your email instead</Link>
                            </div>

                        </div>


                    </Col>
                </Row>
            </Container>


        </div>
    )
}