import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { BiSearchAlt2 } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataService from "../../services/requestApi";
import moment from "moment";
function StaffPerformanceReportModal(props) {
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [startDate, setstartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const {show}= props;
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const [staffPerformance, setStaffPerformance] = useState("");
  const GetAllStaffPerformance = async () => {
    try {
      const response = await DataService.GetStaffPerformanceDiscount(
        storeId,
        saasId,
        startDate,
        date
      );
      console.log("Get loylity issue", response.data);
      setStaffPerformance(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (date && startDate && show) {
      GetAllStaffPerformance();
    }
  }, [show,date, startDate]);
  const columns = [
    {
      name: "SHOPNAME",
      selector: (row) => row.store_name,
      sortable: true,
    },
    {
      name: "CONCEPT",
      selector: (row) => row.conc_id,
      sortable: true,
    },
    {
      name: "STAFF ID",
      selector: (row) => row.sales_man_id,
      sortable: true,
    },
    {
      name: "STAFFNAME",
      selector: (row) => row.sales_man_name,
      sortable: true,
    },
    {
      name: "BILL COUNT",
      selector: (row) => row.invoice_count,
      sortable: true,
    },
    {
      name: "BILL QTY",
      selector: (row) => row.total_item_quantity,
      sortable: true,
    },
    {
      name: "ATV",
      selector: (row) => row.total_item_net,
      sortable: true,
    },
    {
      name: "UPT",
      selector: (row) => row.item_quantity_to_net_ratio,
      sortable: true,
    },
  ];
  return (
    <Modal
      {...props}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ paddingLeft: "15px" }}
      centered
    >
      <Modal.Header className="container" closeButton>
        <Modal.Title>Staff Performance Report</Modal.Title>
      </Modal.Header>
      <Modal.Body className="container">
        <div className="d-flex" style={{
          maxWidth:"500px"
        }}>
          <Form.Control
            type="date"
            className="w-50"
            id="exampleColorInput"
            defaultValue="#563d7c"
            title="Choose your color"
            value={startDate}
            onChange={(e) => {
              setstartDate(e.target.value);
            }}
          />
          <Form.Control
            type="date"
            className="w-50"
            id="exampleColorInput"
            defaultValue="#563d7c"
            title="Choose your color"
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
        </div>

        <DataTable
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          columns={columns}
          responsive={true}
          fixedHeader={true}
          data={staffPerformance}
          pagination
          paginationServer
        />
      </Modal.Body>
    </Modal>
  );
}

export default StaffPerformanceReportModal;
