import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { RiCheckboxLine } from "react-icons/ri";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Col from "react-bootstrap/Col";
import { AiOutlineCheckCircl } from "react-icons/ai";
import { Row, Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { PiNotePencilLight } from "react-icons/pi";
import InputGroup from "react-bootstrap/InputGroup";
import { Form } from "react-bootstrap";
import { PiCards } from "react-icons/pi";
import DataService from "./services/requestApi";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";

export const Tilloptions = () => {
  const navigate = useNavigate();
  const {saasId, storeId, userType ,registerId} = JSON.parse(localStorage.getItem("User_data"));
  const userData = JSON.parse(localStorage.getItem("User_data"));

  const [user, setUser] = useState(userData);
  const IstillOpend = async () => {
    try {
      const response = await DataService.GetOpendTill(storeId,saasId,registerId);
      console.log(response.data);
      if (response.data.status) {
        const updatedUser = { ...user, tillId: response.data.data.till_id };
        localStorage.setItem("User_data", JSON.stringify(updatedUser));
        setUser(updatedUser);
      }
    } catch (error) {
      console.log(error);
    }
  };




  useEffect(() => {
    IstillOpend()
    const handleKeyPress = (event) => {
      if (event.key === "F9") {
        openTill();
        
      }
      if(event.key =="F10"){
        navigate("/tillreconcile2")
      }
      if(event.key =="F11"){
        navigate("/tillclose")
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        navigate("/");
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);

  const openTill = () => {
    navigate("/tillopen");
  };

  useEffect(() => {
    if (userType === "MANAGER" || userType === "ALL") {
      navigate("/");
      Swal.fire({
        title: "Only Cashier Can Open Till",
        icon: "info",
        timer: 1000,
      });
    }
  }, [userType]);

  return (
    <div>
      <Navbar className="justify-content-Between">
        <Container>
          <Navbar.Collapse className="justify-content-center">
            <Navbar.Brand>Photon Softwares</Navbar.Brand>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <hr />

      <Container>
        <div className="my-5">
          <Link to={"/"} style={{ color: "black" }}>
            <AiOutlineArrowLeft
              style={{ width: "50px", height: "20px" }}
              className="mb-2"
            />
          </Link>
          <span>
            <b className="fs-4 mx-4">Till Options</b>
          </span>
        </div>
        <center>
          <Row>
            <Col md={4} sm={12} xs={12} className="p-3">
              <Card style={{ width: "250px", height: "180px" }}>
                <Link
                  to={"/tillopen"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Card.Body>
                    <p>Till Open</p>
                    <div className="d-flex align-items-center justify-content-center">
                      <PiNotePencilLight
                        style={{ width: "50px", height: "50px", color: "grey" }}
                      />
                    </div>
                    <Button
                      variant="none"
                      style={{
                        border: "solid grey 1px",
                        margin: "30px 0px 0px 170px",
                        height: "29px",
                        width: "45px",
                      }}
                    >
                      F9
                    </Button>
                  </Card.Body>
                </Link>
              </Card>
            </Col>

            <Col md={4} sm={12} xs={12} className="p-3">
              <Card style={{ width: "250px", height: "180px" }}>
                <Link
                  to={"/tillreconcile2"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Card.Body>
                    <p>Till Reconcile</p>
                    <div className="d-flex align-items-center justify-content-center">
                      <PiCards
                        style={{ width: "50px", height: "50px", color: "grey" }}
                      />
                    </div>
                    <Button
                      variant="none"
                      style={{
                        border: "solid grey 1px",
                        margin: "30px 0px 0px 170px",
                        height: "29px",
                        width: "55px",
                      }}
                    >
                      F10
                    </Button>
                  </Card.Body>
                </Link>
              </Card>
            </Col>

            {/* <Col md={4} sm={12} xs={12} className="p-3">
              <Card style={{ width: "250px", height: "180px" }}>
                <Link
                  to={"/tillclose"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Card.Body>
                    <p> Till close</p>
                    <div className="d-flex align-items-center justify-content-center">
                      <RiCheckboxLine
                        style={{ width: "50px", height: "50px", color: "grey" }}
                      />
                    </div>
                    <div
                      className="float-end my-4"
                      style={{
                        border: "solid grey 1px",
                        height: "30px",
                        width: "55px",
                        borderRadius: "6px",
                      }}
                    >
                      <Button variant="none">F11</Button>
                    </div>
                  </Card.Body>
                </Link>
              </Card>
            </Col> */}
          </Row>
        </center>
      </Container>
    </div>
  );
};
