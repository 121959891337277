import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import {
  AiFillInfoCircle,
  AiOutlineEdit,
  AiOutlinePercentage,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { PiBookOpenLight } from "react-icons/pi";
import { BsBank2 } from "react-icons/bs";
import { Link } from "react-router-dom";
import { GiBlackBook } from "react-icons/gi";
import { Button, Form } from "react-bootstrap";
import { TextField } from "@mui/material";
import DataService from '../services/requestApi'
import Swal from "sweetalert2";

const DebitNote = ({taxes}) => {
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"))
  const [addProduct, setAddProduct] = useState(false);
  const [party, setParty] = useState();
  const [Data, setData]=useState()
  const [charges, setCharges] = useState();
  const [amount, setAmount] = useState();
  const [Prducte, setProduct] = useState([]);
  const [addChalan, setAddChalan] = useState({
    type: "Type 1",
    name: "",
    selling_price: "",
    selling_price_tax: "",
    purchase_price:"",
    purchase_price_tax: "",
    gst: "",
    quantity: "",
  });
  const data = {
    customer_party: party,
    charges: charges,
    amount: amount,
    add_chalan: Prducte,
  };
  // console.log(taxes)
  const onChange = (e) => {
    setAddChalan({ ...addChalan, [e.target.name]: e.target.value });
  };

  const style = {
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    background: "white",
    color: "black",
    border: "0px",
  };

  const addProducte=()=>{
    
    setProduct(Prducte.concat(addChalan))
    setAddChalan()
    setAddProduct(false)
  }
  useEffect(() => {
    console.log(Prducte)
  }, [Prducte])
  


  const searchPartyName=async (name)=>{
    try {
       const response = await DataService.GetParties(storeId, saasId,name)
       console.log(response.data.data)
       setData(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }


  const CreditDabitNotes=async(e)=>{
    e.preventDefault();
    try {
      const response = await DataService.CreitDebitNote(data)
      if (response.data.status) {
        Swal.fire({
          title:"Credite Added Successfully",
          icon:"success",
          timer:2000
        })
        setParty("")
        setCharges("")
        setAmount("")
        setProduct([])
      }
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <>
      <div className="">
        <div
          className="row d-flex justify-content-center"
          styel={{ height: "fit-content" }}
        >
          <form className="col-lg-5 col-md-10 col-sm-12 px-5" style={style}>
            <div className="d-flex mt-3">
              <Link
                to="/"
                type="submit"
                className="text-decoration-none"
                style={{
                  color: "black",
                }}
              >
                {" "}
                <i className="fa-solid fa-chevron-left mt-1"></i>
              </Link>
              <h4 style={{ fontFamily: "bold", marginLeft: "10px" }}>
                Debit note
              </h4>
            </div>

            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
              //  style={{ flex: 1 }}
              >
                <TextField
                  type="text"
                  className="form-control my-2"
                  id="customer-name"
                  size="small"
                  // value={billNumber}
                  // onChange={(e) => setBillnumber(e.target.value)}
                  // required
                  // disabled
                  label="Debit Node#"
                />
              </div>

              <div className="container p-0">
                <div className="input-group">
                  <input type="date" className="form-control" />
                </div>
              </div>
            </div>
            <div>
              <p className="mb-1 fw-bold mt-2">
                Party
                <AiFillInfoCircle className="mx-2" />
              </p>

              <div>
                <div className="my-1">
                  <div>
                  <input
                  style={{
                    padding: "0.5rem",
                    fontSize: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    width: "100%",
                    boxSizing: "border-box",
                    marginTop: "0.5rem",
                  }}
                  type="text"
                  id="partyInput"
                  list="partyList"
                  placeholder="Select Item"
                  onChange={(e) => {searchPartyName(e.target.value);
                    setParty(e.target.value)}}
                />

                <datalist id="partyList" onSelect={(e)=>{console.log(e.target.value)}}>
                  {/* <option value="kuchi bhi">kuchi bhi</option> */}
                  {Data?.map((data) => (
                    <option  value={data.name}>{data.mobile_number}</option>
                  ))}
                </datalist>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className="mb-1 fw-bold mt-2">
                Products
                <AiFillInfoCircle className="mx-2" />
              </p>

              {addProduct ? (
                <div
                  className="rounded-3"
                  style={{
                    background: " white",
                    padding: " 15px",
                    marginTop: "10px",
                  }}
                >
                  <div className="">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-TextField mx-2"
                        type="radio"
                        name="inlineRadioOptions"
                        value={"Type 1"}
                        required
                        id="inlineRadio4"
                      />
                      <label className="form-check-label" for="inlineRadio4">
                        Product
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-TextField mx-2"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio5"
                        value={"Type 2"}
                        required
                      />
                      <label className="form-check-label" for="inlineRadio5">
                        Service
                      </label>
                    </div>
                  </div>
                  <TextField
                    size="small"
                    type="text"
                    name="name"
                    value={addChalan?.name}
                    onChange={onChange}
                    className="form-control mt-2"
                    id="customer-name"
                    label="Product Name"
                    required
                  />
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="" style={{ flex: 1 }}>
                      <p>Selling Price</p>
                      <TextField
                        size="small"
                        type="text"
                        className="form-control mt-2"
                        id="customer-name"
                        name="selling_price"
                        value={addChalan?.selling_price}
                        onChange={onChange}
                        label="₹ 0"
                        required
                      />
                    </div>
                    <div
                      className="d-flex flex-col mt-1"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <div className="mb-3" styles={{ fontSize: "15px" }}>
                        Sell Price Tax{" "}
                      </div>
                      <div>
                        <Form.Select aria-label="Default select example" name="selling_price_tax" value={addChalan?.selling_price_tax} onChange={onChange} placeholder="select Taxes">
                          <option></option>
                          {taxes && taxes.map((el)=>{return(<option value={el.taxRate}>{el.taxCode}@{el.taxRate}%</option>)})}
                          
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="" style={{ flex: 1 }}>
                      <p>Purchase Price</p>
                      <TextField
                        size="small"
                        type="text"
                        className="form-control mt-2"
                        id="customer-name"
                        name="purchase_price"
                        value={addChalan?.purchase_price}
                        onChange={onChange}
                        label="₹ 0"
                        required
                      />
                    </div>
                    <div
                      className="d-flex flex-col"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <p style={{ textWrap: "nowrap" }}>Purchase PriceTax </p>
                      <div className="mt-1">
                      <Form.Select aria-label="Default select example" name="purchase_price_tax" value={addChalan?.purchase_price_tax} onChange={onChange} placeholder="select Taxes">
                          <option></option>
                          {taxes && taxes.map((el)=>{return(<option value={el.taxRate}>{el.taxCode}@{el.taxRate}%</option>)})}
                          
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="my-3">
                    <p className="my-1">GST %</p>
                    <div>
                    <Form.Select aria-label="Default select example" name="gst" value={addChalan?.gst} onChange={onChange} placeholder="select Taxes">
                          <option>Enter GST</option>
                          {taxes && taxes.map((el)=>{return(<option value={el.taxRate}>{el.taxCode}@{el.taxRate}%</option>)})}
                          
                        </Form.Select>
                    </div>
                  </div>
                  <div className="my-3">
                    <p className="my-1">Units</p>
                    {/* <Select placeholder="Select Unit" /> */}
                    <TextField
                        size="small"
                        type="number"
                        className="form-control mt-2"
                        id="customer-name"
                        name="quantity"
                        value={addChalan?.quantity}
                        onChange={onChange}
                        label="₹ 0"
                        required
                      />
                  </div>
                  <Button
                    // type="submit"
                    className="w-100"
                    onClick={()=>{
                      addProducte()
                    }}
                    // className="btn btn-primary btn-lg btn-block"
                    style={{
                      backgroundColor: "var(--Acc-1, #457FD4)",
                      outline: "none",
                      border: "none",
                      fontSize: "14px",
                      padding: "10px 120px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                  >
                    Add
                  </Button>
                </div>
              ) : (
                ""
              )}

              <p
                className="btn btn-outline-primary mt-3"
                style={{
                  ...style,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "30px",
                  backgroundColor: "white",
                  color: "silver",
                  border: "aliceblue",
                }}
                onClick={() => setAddProduct((state) => !state)}
              >
                <AiOutlinePlusCircle />
                Add Products
              </p>
            </div>
            {/* <div className="d-flex justify-content-between bg-white">
              <h5 className="text-primary fw-bold">
              <AiOutlinePlusCircle className="mx-2" />
              Additional Charges
              </h5>
            </div> */}

            <div className="rounded">
              {/*  */}
              <p className="fw-bold mt-3">Optional</p>
              <div className="d-flex align-items-center justify-content-between">
                <TextField
                  size="small"
                  type="number"
                  className="form-control mt-2"
                  id="customer-name"
                  value={amount}
                  onChange={(e)=>{setAmount(e.target.value)}}
                  label="Amount"
                  required
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <TextField
                  size="small"
                  type="number"
                  className="form-control mt-2"
                  id="customer-name"
                  value={charges}
                  onChange={(e)=>{setCharges(e.target.value)}}
                  label="Charges"
                  required
                />
              </div>
              {/*  */}

              <div className="mt-4 d-flex justify-content-center">
                <button
                onClick={CreditDabitNotes}
                  type="submit"
                  className="btn btn-primary btn-lg btn-block"
                  style={{
                    backgroundColor: "var(--Acc-1, #457FD4)",
                    outline: "none",
                    border: "none",
                    fontSize: "14px",
                    padding: "10px 140px",
                    borderRadius: "10px",
                    color: "#fff",
                  }}
                >
                  Save
                </button>
              </div>
              <div className="mt-2 d-flex justify-content-center">
                <Link
                  to="/"
                  type="submit"
                  // onClick={()=>}
                  className="text-decoration-none"
                  style={{
                    // backgroundColor: "gray",
                    // outline: "none",
                    // border: "none",
                    // marginLeft: "20px",
                    // fontSize: "20px",
                    // padding: "10px 20px",
                    // borderRadius: "10px",
                    color: "black",
                  }}
                >
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DebitNote;
