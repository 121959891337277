import React, { useState } from "react";
import { Container, FloatingLabel, FormControl, Card } from "react-bootstrap";
import DataService from "../services/requestApi";
import Swal from "sweetalert2";

export const AddSupplier = () => {
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const [formData, setFormData] = useState({
    saas_id: saasId,
    store_id: storeId,
    party_name: "",
    gstin: "",
    phone_number: "",
    gst_type: "",
    state: "",
    email: "",
    billing_address: "",
    opening_balance: "",
    credit_limit_flag: false,
    creditLimitAmount: "",
  });

  const handleAddFunction = async () => {
    try {
      const response = await DataService.AddSupplier(formData);
      console.log("formData", formData);
      // Clear The Input Fields
      if (response.status === 200) {
        Swal.fire({
          title: " Supplier Added Successfully",
          icon: "success",
          confirmButtonText: "Done",
          timer: 3000,
        });
        setFormData({
          saas_id: saasId,
          store_id: storeId,
          party_name: "",
          gstin: "",
          phone_number: "",
          gst_type: "",
          state: "",
          email: "",
          billing_address: "",
          opening_balance: "",
          credit_limit_flag: false,
          creditLimitAmount: "",
        });
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Something Not Working Right",
        icon: "error",
        confirmButtonText: "Try again?",
      });
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Container className="d-flex justify-content-center">
      <Card
        style={{ width: "500px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
      >
        <Card.Body>
          <h1 class="text-center mb-4">Add Supplier</h1>{" "}
          <FloatingLabel
            controllId="floatingInput"
            label="Name"
            className="mb-3 "
          >
            <FormControl
              type="text"
              name="party_name"
              value={formData.party_name}
              onChange={handleInputChange}
              required
            />
          </FloatingLabel>
          <FloatingLabel
            controllId="floatingInput"
            label="GSTIN"
            className="mb-3 "
          >
            <FormControl
              type="text"
              name="gstin"
              value={formData.gstin}
              onChange={handleInputChange}
              required
            />
          </FloatingLabel>
          <FloatingLabel
            controllId="floatingInput"
            label="Phone Numbers"
            className="mb-3 "
          >
            <FormControl
              type="number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleInputChange}
              required
            />{" "}
          </FloatingLabel>
          <FloatingLabel
            controllId="floatingInput"
            label="GST Type"
            className="mb-3 "
          >
            <FormControl
              type="text"
              name="gst_type"
              value={formData.gst_type}
              onChange={handleInputChange}
              required
            />
          </FloatingLabel>
          <FloatingLabel
            controllId="floatingInput"
            label="state"
            className="mb-3 "
          >
            <FormControl
              type="text"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              required
            />
          </FloatingLabel>
          <FloatingLabel
            controllId="floatingInput"
            label="Email Address"
            className="mb-3 "
          >
            <FormControl
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </FloatingLabel>
          <FloatingLabel
            controllId="floatingInput"
            label="Billing Address"
            className="mb-3 "
          >
            <FormControl
              type="text"
              name="billing_address"
              value={formData.billing_address}
              onChange={handleInputChange}
              required
            />
          </FloatingLabel>
          <FloatingLabel
            controllId="floatingInput"
            label="Opening Balance"
            className="mb-3 "
          >
            <FormControl
              type="number"
              name="opening_balance"
              value={formData.opening_balance}
              onChange={handleInputChange}
              required
            />
          </FloatingLabel>
          <FloatingLabel
            controllId="floatingInput"
            label="Credit Limit Amount"
            className="mb-3 "
          >
            <FormControl
              type="number"
              name="creditLimitAmount"
              value={formData.creditLimitAmount}
              onChange={handleInputChange}
              required
            />
          </FloatingLabel>{" "}
          <div className=" d-flex justify-content-center">
            <button
              onClick={handleAddFunction}
              type="submit"
              className="btn btn-primary btn-lg btn-block"
              style={{
                backgroundColor: "var(--Acc-1, #457FD4)",
                outline: "none",
                border: "none",
                fontSize: "14px",
                padding: "10px 140px",
                borderRadius: "10px",
                color: "#fff",
              }}
            >
              Add
            </button>
          </div>
        </Card.Body>
      </Card>{" "}
    </Container>
  );
};
