import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { AiOutlineArrowRight } from 'react-icons/ai'
import Button from 'react-bootstrap/Button'
import { Row, Col, Form } from 'react-bootstrap'
import { RxCross2 } from 'react-icons/rx'
export const Tillreconcile4 = () => {
    const navigate =useNavigate()

    return (

        <div >
            <Navbar className="justify-content-Between">
                <Container >
                    <Navbar.Collapse className="justify-content-center"  >
                        <Navbar.Brand>
                            Photon Softwares
                        </Navbar.Brand>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <hr />
            <Container>
                <div className='d-flex my-5'>
                    <Link to={'/tillreconcile3'} className='text-dark'  >
                        <AiOutlineArrowLeft />
                    </Link>
                    <h5 className='mx-4'>Till Reconcile</h5>
                </div>
                <p>Enter the quantity for each type of AED currencies</p>
                <Row>
                    <Col md='6'>
                        <Form>
                            <div className='py-1'>
                                <div style={{ height: "45px", width: "400px", background: "#f2f0f0", }}>
                                    <Row >
                                        <Col> <p className='py-2 mx-2'>PENNIES</p></Col>
                                        <Col><span className='float-end py-2'><RxCross2 /></span></Col>
                                        <Col>
                                            <div className='py-1 float-end'>
                                                <Form.Control></Form.Control>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>


                            <div className='py-1'>
                                <div style={{ height: "45px", width: "400px", background: "#f2f0f0", }}>
                                    <Row>
                                        <Col> <p className='py-2 mx-2'>NICKELS</p></Col>
                                        <Col><span className='float-end py-2'><RxCross2 /></span></Col>
                                        <Col>
                                            <div className='py-1 float-end'>
                                                <Form.Control></Form.Control>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>




                            <div className='py-1'>
                                <div style={{ height: "45px", width: "400px", background: "#f2f0f0", }}>
                                    <Row>
                                        <Col> <p className='py-2 mx-2'>DIMES</p></Col>
                                        <Col><span className='float-end py-2'><RxCross2 /></span></Col>
                                        <Col>
                                            <div className='py-1 float-end'>
                                                <Form.Control></Form.Control>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>



                            <div className='py-1'>
                                <div style={{ height: "45px", width: "400px", background: "#f2f0f0", }}>
                                    <Row>
                                        <Col> <p className='py-2 mx-2'>QUARTERS</p></Col>
                                        <Col><span className='float-end py-2'><RxCross2 /></span></Col>
                                        <Col>
                                            <div className='py-1 float-end'>
                                                <Form.Control></Form.Control>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>


                            <div className='py-1'>
                                <div style={{ height: "45px", width: "400px", background: "#f2f0f0", }}>
                                    <Row>
                                        <Col xs='7'> <p className='py-2 mx-2'>HALF DOLLARS</p></Col>
                                        <Col xs='1'><span className='float-end py-2'><RxCross2 /></span></Col>
                                        <Col xs='4'>
                                            <div className='py-1 float-end'>
                                                <Form.Control></Form.Control>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>


                            <div className='py-1'>
                                <div style={{ height: "45px", width: "400px", background: "#f2f0f0", }}>
                                    <Row>
                                        <Col xs='7'> <p className='py-2 mx-2'>1 DOLLAR COINS</p></Col>
                                        <Col xs='1'><span className='float-end py-2'><RxCross2 /></span></Col>
                                        <Col xs='4'>
                                            <div className='py-1 float-end'>
                                                <Form.Control></Form.Control>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>


                            <div className='py-1'>
                                <div style={{ height: "45px", width: "400px", background: "#f2f0f0", }}>
                                    <Row>
                                        <Col xs='7'> <p className='py-2 mx-2'>1 DOLLAR BILLS</p></Col>
                                        <Col xs='1'><span className='float-end py-2'><RxCross2 /></span></Col>
                                        <Col xs='4'>
                                            <div className='py-1 float-end'>
                                                <Form.Control></Form.Control>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>


                            <div className='py-1'>
                                <div style={{ height: "45px", width: "400px", background: "#f2f0f0", }}>
                                    <Row>
                                        <Col xs='7 '> <p className='py-2 mx-2'>2 DOLLAR BILLS</p></Col>
                                        <Col xs='1'><span className='float-end py-2'><RxCross2 /></span></Col>
                                        <Col xs='4'>
                                            <div className='py-1 float-end'>
                                                <Form.Control></Form.Control>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className='py-1'>
                                <div style={{ height: "45px", width: "400px", background: "#f2f0f0", }}>
                                    <Row>
                                        <Col xs='7'> <p className='py-2 mx-2'>5 DOLLAR BILLS</p></Col>
                                        <Col xs='1'><span className='float-end py-2'><RxCross2 /></span></Col>
                                        <Col xs='4'>
                                            <div className='py-1 float-end'>
                                                <Form.Control></Form.Control>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className='py-1'>
                                <div style={{ height: "45px", width: "400px", background: "#f2f0f0", }}>
                                    <Row>
                                        <Col xs='7'> <p className='py-2 mx-2'>20 DOLLAR BILLS</p></Col>
                                        <Col xs='1'><span className='float-end py-2'><RxCross2 /></span></Col>
                                        <Col xs='4'>
                                            <div className='py-1 float-end'>
                                                <Form.Control></Form.Control>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className='py-1'>
                                <div style={{ height: "45px", width: "400px", background: "#f2f0f0", }}>
                                    <Row>
                                        <Col xs='7'> <p className='py-2 mx-2'>50 DOLLAR BILLS</p></Col>
                                        <Col xs='1'><span className='float-end py-2'><RxCross2 /></span></Col>
                                        <Col xs='4'>
                                            <div className='py-1 float-end'>
                                                <Form.Control></Form.Control>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className='py-1'>
                                <div style={{ height: "45px", width: "400px", background: "#f2f0f0", }}>
                                    <Row>
                                        <Col xs='7'> <p className='py-2 mx-2'>100 DOLLAR BILLS</p></Col>
                                        <Col xs='1'><span className='float-end py-2'><RxCross2 /></span></Col>
                                        <Col xs='4'>
                                            <div className='py-1 float-end'>
                                                <Form.Control></Form.Control>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Form>
                    </Col>

                    {/* <Col md='1'>
                        <div className='vr' style={{ height: '650px' }}>
                        </div>
                    </Col> */}


                    <Col md='5'>
                        <center>
                            <div className='my-5'>
                                <h5>Total</h5>
                                <h4 style={{ color: "blue" }}>AED 200.0</h4>
                                <Button onClick={()=>{navigate("/tillreconcile5")}} className="text-White w-50 " variant="primary"><span className='float-start'>Next</span> <Button className=" border float-end" >Enter</Button></Button>
                            </div>
                        </center>
                    </Col>
                </Row>
            </Container>





        </div>
    )
}