import React, { useEffect, useRef, useState } from "react";
import { Form, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DataService from "../services/requestApi";
import { useDispatch, useSelector } from "react-redux";
import { handelDisplaycart } from "../Redux/Reducer";
import moment from "moment";
const GetHoldTranModal = ({ show, setShow,getFocus }) => {
  const [Invoice, setInvoice] = useState("");
  const displayData = useSelector((state) => state.Reducer.displayCart);
  const handleClose = () => {
    setShow(false)
    getFocus()
    setInvoice("")};
  const dispatch = useDispatch();
  const focus = useRef();
  useEffect(() => {
    if (show) {
      focus.current.focus();
    }
  }, [show]);
  const { saasId, storeId, registerId, userId, userType ,userName} = JSON.parse(
    localStorage.getItem("User_data")
  );
  const today = moment(new Date()).format("YYYY-MM-DD");
  const sendTransection = async (event,Items) => {
    try {
      
      const data = {
        userId:userId,
        userName:userName,
        businessDate: today,
        saasId: saasId,
        storeId: storeId,
        registerId: registerId,
        invoiceNo: null,
        event: event,
        cartItems:Items
        // cartItems: [
        //   {
        //     "item_id": 1,
        //     "itemName": "Item 1",
        //     "category": "Category A",
        //     "message": "Example Message 1",
        //     "itemCode": "ABC123",
        //     "sku": "SKU123",
        //     "invoice_no": "INV001",
        //     "description": "Description of Item 1",
        //     "price": 10.99,
        //     "discount": 1.5,
        //     "tax": 0.5,
        //     "status": "Active",
        //     "department": "Department A",
        //     "saasId": "itemSaasId1",
        //     "storeId": "itemStoreId1",
        //     "promoId": "promo123",
        //     "productQty": 3,
        //     "hsnCode": "HSN001",
        //     "taxRate": 0.1,
        //     "taxCode": "TAX001",
        //     "taxPercent": 5.0
        //   },
        //   {
        //     "item_id": 2,
        //     "itemName": "Item 2",
        //     "category": "Category B",
        //     "message": "Example Message 2",
        //     "itemCode": "XYZ456",
        //     "sku": "SKU456",
        //     "invoice_no": "INV002",
        //     "description": "Description of Item 2",
        //     "price": 15.99,
        //     "discount": 2.0,
        //     "tax": 0.75,
        //     "status": "Inactive",
        //     "department": "Department B",
        //     "saasId": "itemSaasId2",
        //     "storeId": "itemStoreId2",
        //     "promoId": "promo456",
        //     "productQty": 5,
        //     "hsnCode": "HSN002",
        //     "taxRate": 0.15,
        //     "taxCode": "TAX002",
        //     "taxPercent": 7.5
        //   }
        // ]
      };

      const response = await DataService.TransactionLog(data);
      console.log("transectionData", response);
    } catch (error) {
      console.error(error.message);
    }
  };

  const holdTransaction = async (e) => {
    e.preventDefault();
    try {
      if (Invoice) {
        const response = await DataService.GetHoldTransction(Invoice);
        console.log(response.data.data);
        dispatch(handelDisplaycart(response.data.data));
        handleClose();
        
            sendTransection(`Retrieve Invoice ${Invoice} : `,response.data.data);
        

      } 
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Get Hold Transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          style={{ width: "50%" }}
          onSubmit={holdTransaction}
          className="d-flex "
        >
          <FormControl
            type="number"
            placeholder="Enter Invoice Number"
            className="mr-2"
            aria-label="Search"
            ref={focus}
            value={Invoice}
            onChange={(e) => {
              setInvoice(e.target.value);
            }}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default GetHoldTranModal;
