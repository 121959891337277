import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { BiSearchAlt2 } from 'react-icons/bi'
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import { AiOutlineArrowLeft } from 'react-icons/ai'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CiBarcode } from "react-icons/ci"
import { BsCircle } from "react-icons/bs"
import { PiCrownBold } from "react-icons/pi"
import { Link, Outlet } from 'react-router-dom'
import barcode from './Images/image 34.png'
import exitcart from './Images/Logout 3.png'
import { useSelector } from 'react-redux'
import { useDispatch } from "react-redux";
import { getDataToCart } from './Redux/Reducer';
import { useNavigate } from 'react-router';
import { displayDataToCart } from './Redux/Reducer';

import axios from 'axios'
export const Scanner = () => {
    const cardVal = useSelector((state) => state.Reducer.values)
    return (
        <>
      {/* {displayDataToCart && displayDataToCart.length > 0  ? "table":  <Container>
            <div>
                <Row>
                    <Col style={{ height: "500px", width: "1050px", borderRight: "solid grey 1px " }} className='my-5 py-5' >
                        <div className='d-flex align-items-center justify-content-center'>
                            <h6 style={{ color: "grey" }} >Your Shopping Cart is Empty. Scan item to start checkout</h6>
                        </div>
                        <div className='d-flex align-items-center justify-content-center my-4'><img height="120px" width="230px" src={barcode} /></div>
                    </Col>

                    <Col>
                    <div style={{ background: "#e07707", height: "200px", width: "350px", borderRadius: "10px" }} className='d-flex align-items-center justify-content-center float-end my-5 ' >
                                <div >
                                    <h6 className='my-2 '>Your Loyalty Balance</h6>
                                    <h5 className='my-3 '>Balance:{cardVal}</h5>
                                    <h6 className='my-3 '> Value INR 000</h6>
                                    <h6 className='my-3 '> 00 Pending Loyalties</h6>
                                    <h6 className='my-3 '> 1800 0000  5484  5511</h6>
                                </div>
                                <div> <BsCircle style={{ height: "40px", width: "40px",  color: "light",marginBottom:'100px',marginRight:'60px' }}/> <PiCrownBold style={{ height: "30px", width: "30px",}} /></div>
                    </div>
                    </Col>
                </Row>
            </div>
        </Container>} */}
         </>

    )
}