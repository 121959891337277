import React, { useState,useEffect } from 'react'
import './App.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';

import {AiOutlineArrowLeft} from 'react-icons/ai'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import image1 from './Images/image71.png'
import image2 from './Images/image 72.png'
import image3 from './Images/image 71.png'
import image4 from './Images/image 70.png'
import { Link } from 'react-router-dom';
import CustomNavbar from './UiComponents/CustomNavbar';

export const Discount_offer=()=>{
return(
    <div>
<CustomNavbar/>
<hr ></hr>
<Link to={'/itemsfordiscount'} style={{ color: "black", margin: "20px 0px 0px 70px" }}> < AiOutlineArrowLeft style={{ width: "30px", height: "20px" }} /></Link>
<h5 style={{margin:"-24px 0px 0px 120px"}}>Select Discount or Offer</h5>

<div style={{width:"100%"}}>

    <div style={{ width: '300px', height: '200px', margin: "30px 00px 0px  50px",border:"solid lightgrey 1px" }}>
        
        <img
            style={{margin:"20px 0px 0px 70px"}}
            height="150px"
            width="150px"
            src={image1}
          />
          <h6 style={{margin:"0px 0px 0px 100px"}}>Discount</h6>
       
    </div>

 
    <div style={{ width: '300px', height: '200px',margin: "-200px 20px 0px  360px",border:"solid lightgrey 1px" }}>
        
        <img
            style={{margin:"40px 0px 0px 100px"}}
            height="130px"
            width="130px"
            src={image2}
          />
        <h6 style={{margin:"0px 0px 0px 100px"}}>Employee Discount</h6>
    </div>
   
    <div style={{  width: '300px', height: '200px', margin: "-200px 20px 0px  670px",border:"solid lightgrey 1px" }}>
        
        <img
            style={{margin:"29px -2px 0px 80px"}}
            height="150px"
            width="150px"
            src={image3}
          />
         <h6 style={{margin:"-10px 0px 0px 100px"}}>Damage Discount</h6>
    </div>
    
    <div style={{  width: '300px', height: '200px', margin: "-200px 20px 0px  980px",border:"solid lightgrey 1px" }}>
        
        <img
            style={{margin:"20px 0px 0px 92px"}}
            height="150px"
            width="150px"
            src={image4}
          />
          <h6 style={{margin:"0px 0px 0px 130px"}}>Place Holder</h6>
       
    </div>

<Button className="text-dark"  variant="none" style={{ height:"45px",width:"220px",border:"solid grey 1px",margin:"200px 0px 0px  80px"}}>Cancel <Button className="text-dark" style={{border:"solid grey 1px",margin:" 0px 0px 0px 90px ",height:"30px",width:"50px"}}  variant="none" ><Link to={'/itemsfordiscount'} style={{textDecoration:"none",color:"white"}}>Esc</Link></Button></Button>
                  <Button  className="text-White"   style={{backgroundColor:"#2E45AF",height:"45px",width:"220px",border:"solid grey 1px",margin:"200px 0px 0px  17px"}}>Next <Button className="text-white" style={{backgroundColor:"#2E45AF", border:"solid grey 1px",margin:" 0px 0px 0px 90px ",height:"30px",width:"60px"}} ><Link to={'/Discountoption'} style={{textDecoration:"none",color:"white"}}> Enter </Link></Button></Button>
                
</div>
</div>
)
}