import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import SalesModal from "./StoreinsightsModals/SalesModal";
import DataService from "../services/requestApi";
import moment from "moment";
const SalesSeaction = ({ src }) => {
  const [modalShow, setModalShow] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [Avergesale, setAgvergsales] = useState("");
  const [Activetab, setActivetab] = useState("");
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const today = moment(new Date()).format("YYYY-MM-DD");
  const [date, setDate] = useState(today);

  const GetAverageSales = async () => {
    try {
      console.log("Active Tab:", Activetab);
      let response;
      if (Activetab === "Average") {
        response = await DataService.AverageSales(saasId, storeId, date);
      } else if (Activetab === "ConceptDeptReport") {
        response = await DataService.ConceptDeptReport(saasId, storeId, date);
      } else if (Activetab === "SalesSummary") {
        response = await DataService.getSalesSummary(
          saasId, storeId, startDate, endDate
        );
      } else if (Activetab === "DailySales") {
        response = await DataService.getDailySales(
          startDate, storeId, saasId
        );
      }

      if (response) {
        console.log("API Response:", response.data.data || response.data.list_sales_report);
        setAgvergsales(response.data.data || response.data.list_sales_report);
        setModalShow(true);
      }
    } catch (error) {
      console.log("Error in GetAverageSales function", error);
    }
  };

  useEffect(() => {
    console.log("date:", date);
    if (Activetab || date || startDate || endDate) {
      GetAverageSales();
    }
  }, [Activetab, date, startDate, endDate]); // Consolidated dependency array

  const style = {
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    background: "white",
    color: "black",
    border: "0px",
  };

  // console.log("selecion component", Avergesale);

  const columns = [
    {
      name: `${
       Activetab == "DailySales"?"Type":""
      }`,
      selector: (row) =>
        Activetab == "DailySales"?row.type:"",
      sortable: true,
      width:"90px"
    },
    {
      name: `${
        Activetab == "Average"
          ? "No of Invoice"
          : Activetab == "ConceptDeptReport"
          ? "Concept Number"
          : Activetab == "SalesSummary"
          ? "Business Date"
          : "Business Date"
      }`,
      selector: (row) =>
        Activetab == "Average"
          ? row.total_invoices
          : Activetab == "ConceptDeptReport"
          ? row.conc_id
          : Activetab == "SalesSummary"
          ? row.business_date
          : row.business_date,
      sortable: true,
      width:"150px"
    },
    {
      name: `${
        Activetab == "Average"
          ? "Sales Total"
          : Activetab == "ConceptDeptReport"
          ? " Conecpt Name "
          : Activetab == "SalesSummary"
          ? "Gross Sales"
          : "Invoice Number"
      }`,
      selector: (row) =>
        Activetab == "Average"
          ? row.total_item_net
          : Activetab == "ConceptDeptReport"
          ? row.conc_name
          : Activetab == "SalesSummary"
          ? row.invoice_total
          : row.invoice_no,
      sortable: true,
      width:"200px"
    },
    {
      name: `${
        Activetab == "Average"
          ? "Total Quantity"
          : Activetab == "ConceptDeptReport"
          ? " Dept Number   "
          : Activetab == "SalesSummary"
          ? "Discount Total"
          : "Gross Total"
      }`,
      selector: (row) =>
        Activetab == "Average"
          ? row.total_item_qty?.toFixed(0)
          : Activetab == "ConceptDeptReport"
          ? row.dept
          : Activetab == "SalesSummary"
          ? row.discount_total
          : row.invoice_total,
      sortable: true,
      width:"200px"
    },
    {
      name: `${
       Activetab == "SalesSummary"?"Return":""
      }`,
      selector: (row) =>
        Activetab == "SalesSummary"?row.return_net_value:"",
      sortable: true,
      width:"200px"
    },
    {
      name: `${
        Activetab == "Average"
          ? "ATV "
          : Activetab == "ConceptDeptReport"
          ? "Dept Name "
          : Activetab == "DailySales"
          ? "Discount"
         :Activetab == "SalesSummary" ? "Net Sales"  :""
      }`,
      selector: (row) =>
        Activetab == "Average"
          ? row.atv?.toFixed(0)
          : Activetab == "ConceptDeptReport"
          ? row.dept_name
          : Activetab == "DailySales"
          ? row.discount
          : Activetab == "SalesSummary" ? row.net_value - row.return_net_value :"",
      sortable: true,
      width:"200px"
    },
    {
      name: `${
       Activetab == "DailySales"?"Quantity":""
      }`,
      selector: (row) =>
        Activetab == "DailySales"?row.qty:"",
      sortable: true,
      width:"200px"
    },
    {
      name: `${
        Activetab == "Average"
          ? "UPT "
          :     Activetab == "DailySales"
          ? "Net Total"
         :Activetab == "ConceptDeptReport"?"Store Id  ":""
          
      }`,
      selector: (row) =>
      Activetab == "Average"
      ? row.upt?.toFixed(0)
      :    Activetab == "DailySales"
      ? row.netValue
      : Activetab == "ConceptDeptReport"?row.store_id:"",
      sortable: true,
      width:"200px"
    },

    {
      name: `${
       Activetab == "ConceptDeptReport"?" Total Gross Sales ":""
      }`,
      selector: (row) =>
        Activetab == "ConceptDeptReport"?row.total_item_value:"",
      sortable: true,
      width:"200px"
    },
    {
      name: `${
       Activetab == "ConceptDeptReport"?"Discount":""
      }`,
      selector: (row) =>
        Activetab == "ConceptDeptReport"?row.total_item_discount:"",
      sortable: true,
      width:"200px"
    },
    
    
    {
      name: `${
       Activetab == "ConceptDeptReport"?"Net Sales":""
      }`,
      selector: (row) =>
        Activetab == "ConceptDeptReport"?row.total_item_net:"",
      sortable: true,
      width:"200px"
    },
  ];
  // useEffect(() => {
  //   if (Activetab) {
  //     GetAverageSales();
  //   }
  //   if (startDate) {
  //     GetAverageSales();
  //   }
  //   if (startDate || endDate) {
  //     GetAverageSales();
  //   }
  // }, [Activetab, startDate, endDate]);

  return (
    <Container>
      <Row>
        <Col>
          <div>
            <img
              className="w-100 rounded-4"
              style={{
                background: "lightgray 50% / cover no-repeat",
                boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
              }}
              src={src}
              alt=""
            />
          </div>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setActivetab("Average");
            }}
            style={style}
            className="w-100"
            variant="success"
            id="dropdown-basic"
          >
            Average Sales
          </Button>

          <Button
            onClick={() => {
              setActivetab("DailySales");
            }}
            style={style}
            className="w-100 mt-3"
            variant="success"
            id="dropdown-basic"
          >
            Daily Sales
          </Button>

          <Button
            onClick={() => {
              setActivetab("SalesSummary");
            }}
            style={style}
            className="w-100 mt-3"
            variant="success"
            id="dropdown-basic"
          >
            Sales Summary
          </Button>

          <Button
            onClick={() => {
              setActivetab("ConceptDeptReport");
            }}
            style={style}
            className="w-100 mt-3"
            variant="success"
            id="dropdown-basic"
          >
            Concept/Dept. Sales
          </Button>
        </Col>
      </Row>
      <SalesModal
        show={modalShow}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setActivetab={setActivetab}
        Activetab={Activetab}
        setModalShow={setModalShow}
        columns={columns}
        data={Avergesale}
        setDate={setDate}
        date={date}
      />
    </Container>
  );
};
export default SalesSeaction;
