import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import axios from "axios";
import { BASEURL } from "./services/http-common";
import Button from "react-bootstrap/Button";
import { Form, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import DataService from "./services/requestApi";
export const Tillclose = () => {
  const navigate = useNavigate()
  const { saasId, storeId, registerId } = JSON.parse(
    localStorage.getItem("User_data")
  );
  const [tillData, setTillData] = useState("");
  const naviagte = useNavigate();
  const Ref = useRef()
  const handleCloseTill = async () => {
    const apiUrl = `${BASEURL.ENDPOINT_URL}/till/till-close/CLOSE/${saasId}/${storeId}/${tillData}/${registerId}`;
    try {
      const response = await axios.put(apiUrl);
      if (response.data.status) {
        const Register = response.data.data ? response.data.data:registerId
        Swal.fire({
          title: "Want to Close Register",
          icon: "question",
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await DataService.TillRegisterClose(saasId, storeId, Register);
            localStorage.clear();
            naviagte("/login");
          } else if (result.isDenied) {
            localStorage.clear();
            naviagte("/login");
          }
        });
      } else {
        Swal.fire({
          title: response.data.message,
          icon: "error",
        });
      }
      // Perform any additional actions after a successful request
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle errors accordingly
    }
  };

  const changeHandler = (e) => {
    setTillData(e.target.value);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        navigate("/tilloptions");
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);

  useEffect(() => {
    Ref.current.focus()
  }, [])
  
  return (
    <div>
      <Navbar className="justify-content-Between">
        <Container>
          <Navbar.Collapse className="justify-content-center">
            <Navbar.Brand>Photon Softwares</Navbar.Brand>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <hr />
      <Container>
        <div className="d-flex my-5">
          <Link to={"/tilloptions"} className="text-dark">
            <AiOutlineArrowLeft />
          </Link>
          <h5 className="mx-4">Till Close</h5>
        </div>

        <div className="mx-4 my-5">
          <Row className="justify-content-center">
            <Col lg={6}>
              {/* <Form> */}
                <h6 className="my-4">Enter Till Id</h6>
                <Form.Control
                ref={Ref}
                  placeholder="Enter Till Id"
                  value={tillData}
                  name="tillData"
                  onChange={changeHandler}
                />
              {/* </Form> */}
              <center>
                <div className="my-5">
                  <Button
                    className="text-White border mx-2"
                    variant="none"
                    style={{ width: "250px" }}
                  >
                    <span className="float-start my-2">Cancel</span>{" "}
                    <Button
                      className="border float-end text-dark"
                      variant="none"
                    >
                      Esc
                    </Button>
                  </Button>
                  <Button
                    onClick={handleCloseTill}
                    className="text-White border mx-2"
                    variant="primary"
                    style={{ width: "250px" }}
                  >
                    <span className="float-start my-2">Next</span>{" "}
                    <Button className="border float-end text-white">
                      <Link
                        to={"/"}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Enter
                      </Link>
                    </Button>
                  </Button>
                </div>
              </center>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
