import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { BiSearchAlt2 } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataService from "../../services/requestApi";
import moment from "moment";
function StaffDiscountModal(props) {

  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"))
  const [staffDiscount, setStaffDiscount]= useState('')
  const {show}= props
  const GetAllStaffDiscount=async()=>{
    try {
      const response =await DataService.GetStaffDiscount(saasId,storeId,date)
      console.log("Get loylity issue", response.data)
      setStaffDiscount(response.data.data)
  
  
    } catch (error) {
      console.log("error", error)
    }
  
  }


  useEffect(() => {
    if(date && show){
      GetAllStaffDiscount()
    }
  }, [show, date])



    const columns = [
        {
          name: "SHOPNAME",
          selector: (row) => row.store_name,
          sortable: true
        },
        {
          name: "TRN DATE", 
          selector:(row)=>row.business_date,
          sortable: true
        },
        {
          name: "STAFF ID",
          selector:(row)=>row.customer_id,
          sortable: true,
        },
        {
          name: 'STAFF NAME',
          selector: (row) => row.customer_name ,
          sortable: true
        },
        {
          name:  'BILL NO',
          selector: (row) =>row.invoice_number,
          sortable: true
        },
        {
          name:  'CASHIER ID',
          selector: (row) =>row.cashier_id,
          sortable: true
        },
        {
          name:  'Invoice Total',
          selector: (row) => row.invoice_total,
          sortable: true
        },
        {
            name:  'Discount Total',
            selector: (row) => row.discount_total,
            sortable: true
          },
      ];
  return (
    <Modal
      {...props}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ paddingLeft: "15px" }}
      centered
    >
          <Modal.Header className="container" closeButton>
        <Modal.Title>Staff Discount Report</Modal.Title>
      </Modal.Header>
                    <Modal.Body className='container'>
                    <Form.Control
          type="date"
          className='w-50'
          id="exampleColorInput"
          defaultValue="#563d7c"
          title="Choose your color"
          value={date}
          onChange={(e)=>{setDate(e.target.value)}}
        />


                    <DataTable
        
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          columns={columns}
          responsive={true}
          fixedHeader={true}
       
          data={staffDiscount}
          // progressPending={loading}
          pagination
          paginationServer
    
        />

                 </Modal.Body>
    </Modal>
  );
}

export default StaffDiscountModal;
