import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import SalesModal from "./StoreinsightsModals/SalesModal";
import DataService from "../services/requestApi";
import moment from "moment";
import LoylityCardReportModal from "./ReportsModal/LoylityCardReportModal";
import StaffDiscountModal from "./ReportsModal/StaffDiscountModal";
import StaffPerformanceReportModal from "./ReportsModal/StaffPerformanceReportModal";
import LoylityCardIssueReport from "./ReportsModal/LoylityCardIssueReport";
const Reports = ({src}) => {
  const [modalLoylitycard, setModalLoylitycard] = useState(false);
  const [modalStaffDiscount, setModalStaffDiscount] = useState(false);
  const [modalStaffPerformance, setModalStaffPerformance] = useState(false);
  const [modalLoylityIssue, setModalLoylityIssue] = useState(false);



  const [Activetab, setActivetab] = useState("");
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));





 





  const style = {
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    background: "white",
    color: "black",
    border: "0px",
  };

  const sampleData = [
    {
      business_date: "2024-03-01",
      hours_of_the_day: 8,
      total_invoices: 50,
      total_net_value: 5000,
      total_item_qty: 100,
      atv: 100,
      upt: 2
    },
    {
      business_date: "2024-03-02",
      hours_of_the_day: 10,
      total_invoices: 60,
      total_net_value: 6000,
      total_item_qty: 120,
      atv: 120,
      upt: 2.5
    },
    // Add more sample data as needed
  ];
 

 

  return (
    <Container>
      <Row>
        <Col>
          <div>
            <img
              className="w-100 rounded-4"
              style={{
                background: "lightgray 50% / cover no-repeat",
                boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
              }}
              src={src}
              alt=""
            />
          </div>
        </Col>
        <Col>
          <Button
            onClick={() => {
                setModalLoylitycard(true);
            }}
            style={style}
            className="w-100"
            variant="success"
            id="dropdown-basic"
          >
           Loylity Card Redemption Report
          </Button>

          <Button
            onClick={() => {
            
                setModalStaffDiscount(true);
            }}
            style={style}
            className="w-100 mt-3"
            variant="success"
            id="dropdown-basic"
          >
         Staff Discount Report
          </Button>

          <Button
            onClick={() => {

                setModalStaffPerformance(true);
            }}
            style={style}
            className="w-100 mt-3"
            variant="success"
            id="dropdown-basic"
          >
            Staff Performance Report
          </Button>

          <Button
            onClick={() => {
          
                setModalLoylityIssue(true);
            }}
            style={style}
            className="w-100 mt-3"
            variant="success"
            id="dropdown-basic"
          >
            Loylity Card Issued Report
          </Button>
        </Col>
      </Row>
      <LoylityCardReportModal sampleData={sampleData}  show={modalLoylitycard} onHide={() => setModalLoylitycard(false)} />
      <StaffDiscountModal   show={modalStaffDiscount} onHide={() => setModalStaffDiscount(false)} />
      <StaffPerformanceReportModal sampleData={sampleData}  show={modalStaffPerformance} onHide={() => setModalStaffPerformance(false)} />
      <LoylityCardIssueReport   show={modalLoylityIssue} onHide={() => setModalLoylityIssue(false)} />

    </Container>
  );
};
export default Reports;
