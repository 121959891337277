import React, { useEffect } from 'react'
import './App.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';

import {AiOutlineArrowLeft} from 'react-icons/ai'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";

import { displayDataToCart } from './Redux/Reducer';
export const Itemsfordiscount=()=>{
    const displayData = useSelector((state) => state.Reducer.displayCart);

    useEffect(() => {
     console.log("item discount", displayData)
    }, [])
    

return(
    <div>
<Navbar className="justify-content-Between">
<Container >



    <Navbar.Collapse className="justify-content-center"  >
        <Navbar.Brand>
            Photon Softwares
        </Navbar.Brand>
    </Navbar.Collapse>



</Container>

</Navbar>
<hr ></hr>
<Link to={'/'} style={{ color: "black", margin: "20px 0px 0px 50px" }}> < AiOutlineArrowLeft style={{ width: "30px", height: "20px" }} /></Link>
<h5 style={{margin:"-24px 0px 0px 100px"}}>Select Items for Discount</h5>
<hr></hr>
<div style={{width:"100%"}}>

<Row >
    <Col sm={8} style={{margin:"0px 0px 0px 60px"}}>
        <label style={{margin:"0px 0px 0px 30px" ,fontSize:"19px"}} >Items</label>
    </Col>
    
    <Col sm={1}>
         <p style={{fontSize:"19px"}}>Price</p>
    </Col>
    <Col sm={1}>
    <p style={{fontSize:"19px"}}>Quantity</p>
</Col>
{/* <Col sm={1}>
<p style={{fontSize:"19px"}}>Total</p>
</Col> */}
</Row>
<hr style={{margin:"-10px 0px 20px 0px"}}></hr>
{displayData.map((item, index) => ( 
<Row key={item.item_id}>
    <Col sm={8} style={{margin:"0px 0px 0px 60px"}}>
       <input type="checkbox" style={{height:"20px ",width:"20px"}}/><label style={{margin:"0px 0px 0px 30px" ,fontSize:"17px"}} >{item.item_name}</label>
    </Col>
    
    <Col sm={1}>
         <p style={{fontSize:"17px"}}>{item.price}</p>
    </Col>
    <Col sm={1}>
    <p style={{fontSize:"17px"}}>{item.product_qty}</p>
</Col>
{/* <Col sm={1}>
<p style={{fontSize:"17px"}}>200.00</p>
</Col> */}
<hr  style={{margin:"20px 0px 20px 0px"}}></hr>
</Row>
    ))}


<Button className="text-dark"  variant="none" style={{height:"44px",width:"220px",border:"solid grey 1px",margin:"20px 0px 0px  60px"}}>Cancel <Button className="text-dark" style={{border:"solid grey 1px",margin:" 0px 0px 0px 90px ",height:"30px",width:"50px"}}  variant="none"> Esc</Button></Button>
                  <Button className="text-White"   style={{backgroundColor:"#2E45AF", height:"45px",width:"220px",border:"solid grey 1px",margin:"20px 0px 0px  17px"}}>Next <Button className="text-white" style={{backgroundColor:"#2E45AF", border:"solid grey 1px",margin:" 0px 0px 0px 90px ",height:"30px",width:"60px"}} ><Link to={'/Discountoption'} style={{textDecoration:"none",color:"white"}}> Enter </Link></Button></Button>
                
</div>
</div>
)
}