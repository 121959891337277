import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, TextField } from "@mui/material";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { createMakeAndWithStyles } from "tss-react";
// import { PhotoCameraRoundedIcon } from "@material-ui/icons";
import { AiFillCamera } from "react-icons/ai";

// import { useHistory } from "react-router-dom";
import Webcam from "react-webcam";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {  Col } from "react-bootstrap";
import { Label, FormGroup } from "reactstrap";
import { BASEURL } from '../../../services/http-common';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Swal from "sweetalert2";
const videoConstraints = {
  width: 200,
  facinMode: "enviorment",
};

const AddItemModal = ({
  addUpdateItemModalIsOpen,
  setAddUpdateItemModalIsOpen,
  row,
  setFlag,
  flag,
  GetItemReport
}) => {
  const navigate = useNavigate();
  const { makeStyles } = createMakeAndWithStyles({});
  const useStyles = makeStyles((theme) => ({
    root: {
      height: "100%",
      textAlign: "center",
    },
    imgBox: {
      maxWidth: "80%",
      maxHeight: "80%",
      margin: "10px",
    },
    img: {
      height: "inherit",
      maxWidth: "inherit",
    },
    input: {
      display: "none",
    },
  }));
  function handleTakePhoto(dataUri) {
    // Do stuff with the photo...
    console.log("takePhoto");
  }
//   const { save_product_id } = useSelector((e) => e.ComponentPropsManagement);

  // console.log(save_product_id);
  const dispatch = useDispatch();
  //
  // const classes = useStyles();

  const [source, setSource] = useState("");
  //
  const [selectedOptionDiscount, setSelectedOptionDiscount] = useState(null);
  const [selectedOptionTax, setSelectedOptionTax] = useState(null);
  const [selectedHSNTax, setSelectedHSNTax] = useState(null);
  const [itemName, setItemName] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("0");
  const [sellingPrice, setSellingPrice] = useState("");
  const [mrp, setMrp] = useState("");
  const [stockQuantity, setstockQuantity] = useState("");
  const [openingQuantity, setOpeningQuantity] = useState("0");
  const [receivedQuantity, setReceivedQuantity] = useState("0");
  const [clossingQuantity, setClossingQuantity] = useState("");
  const [image, setImage] = useState("");
  const [uploadItem, setUploadItem] = useState("");
  const [department, setDepartment] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [itemDesc, setItemDesc] = useState("");
  const [hsnCode, setHsnCode] = useState("0");
  const [itemPrice, setItemPrice] = useState("0");
  const [pcs_price, setPcs_Price] = useState("0");
  const [openCam, setOpenCam] = useState(false);
  const [productId, setProductId] = useState("");
  const [taxPercentage, setTaxPercentage] = useState("0");
  const [itemCategory, setItemCategory] = useState("");
  const [images, setImages] = React.useState([]);
  const [ImageName, setImageName] = useState("");
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  // console.log("UPLOAD ITEM", uploadItem);
  // console.log("rr", row)
  // useEffect(() => {
  //   if (item_id) {
  //     setProductId(item_id);
  //   }
  // }, [item_id]);

  useEffect(() => {
    if (row) {
      setItemName(row.item_name);
      setItemPrice(row.price);
      setTaxPercentage(row.tax_percent);
      setItemCode(row.hsn_code);
      // setHsnCode(row.hsn_code);
    }
  }, [row]);

  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));

  console.log("ROW", row);

  const onUserMedia = (e) => {
    console.log(e);
  };

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files?.length !== 0) {
        // const file = target.files[0];
        // const newUrl = URL.createObjectURL(file);
        // setSource(newUrl);
        setSource(target.files[0]);
      }
    }
  };
  
  

  const handleUploadImage = (e) => {
    e.preventDefault()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      item_name: itemName,
      item_code: row.item_id,
      description: itemName,
      price: itemPrice,
      price_pcs:pcs_price,
      discount: row.discount,
      tax: taxPercentage,
      status: row.status,
      saas_id: saasId,
      store_id: storeId,
      promo_id: row.promo_id,
      sku: 0,
      category: row.category,
      barcode: row.barcode,
      mrp: row.mrp,
      stock_quantity: 0,
      update_price: itemPrice,
      selling_price: itemPrice,
      opening_quantity: openingQuantity,
      closing_quantity: 0,
      received_quantity: receivedQuantity,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASEURL.ENDPOINT_URL}/item/update-item/${row.item_id}`, requestOptions)
      .then((response) => response.text())
      .then((result) =>{
        GetItemReport()
      })
      .catch((error) => console.log("error", error));
  
    if(image){
      const formData = new FormData();
      formData.append("file", image);
      fetch(`${BASEURL.ENDPOINT_URL}/item/save-image/${row.item_id}`, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: formData,
      })
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
      
      setImage("");
      setImageName("");
      
      setAddUpdateItemModalIsOpen(false);
    }
    //setProductId(undefined)
    setFlag(!flag)
    setAddUpdateItemModalIsOpen(false);
    Swal.fire("Good job", `Item Updated Successfully. `, "success");
    
  

  };
  // const VisuallyHiddenInput = styled('input')({
  //   clip: 'rect(0 0 0 0)',
  //   clipPath: 'inset(50%)',
  //   height: 1,
  //   overflow: 'hidden',
  //   position: 'absolute',
  //   bottom: 0,
  //   left: 0,
  //   whiteSpace: 'nowrap',
  //   width: 1,
  // });
  return (
    <>
      <Modal
        isOpen={addUpdateItemModalIsOpen}
        toggle={() => setAddUpdateItemModalIsOpen(!addUpdateItemModalIsOpen)}
      >
        <ModalHeader>
          <BsArrowLeft
            onClick={() =>
              setAddUpdateItemModalIsOpen(!addUpdateItemModalIsOpen)
            }
            className="mouse-pointer"
          />{" "}
          Update Item *
        </ModalHeader>
        <ModalBody>
          <div className="row d-flex justify-content-center">
            <div className="">
              <form
                className="form-box"
                // onSubmit={handleAddItem}
                // encType="multipart/form-data"
                encType="Content-Type"
              >
                <div
                  className="d-flex flex-col"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Col md={12}>
                  
                  </Col>
                  <TextField
                    size="small"
                    type="text"
                    className="form-control my-2"
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                    label="Item Name"
                    multiline
                    required
                    rows={1}
                  />
                   
                  <TextField
                    size="small"
                    id="outlined-number"
                    type="number"
                    className="form-control my-2"
                    value={itemPrice}
                    required
                    onChange={(e) => setItemPrice(e.target.value)}
                    label="Item Price"
                  />
                  <TextField
                   size="small"
                   id="outlined-number"
                   type="number"
                   className="form-control my-2"
                    value={hsnCode && "0"}
                    required
                    onChange={(e) => setHsnCode(e.target.value)}
                    label="HSN Code"
                  />

                  <TextField
                    size="small"
                    id="outlined-number"
                    type="number"
                    className="form-control my-2"
                    value={taxPercentage}
                    required
                    onChange={(e) => setTaxPercentage(e.target.value)}
                    label="Tax Percentage"
                  />
                  <TextField
                   size="small"
                   id="outlined-number"
                   type="number"
                   className="form-control my-2"
                    value={purchasePrice}
                    required
                    onChange={(e) => setPurchasePrice(e.target.value)}
                    label="Purchase Price"
                  />
                  <TextField
                    size="small"
                    id="outlined-number"
                    type="number"
                    className="form-control my-2"
                    value={openingQuantity}
                    required
                    onChange={(e) => setOpeningQuantity(e.target.value)}
                    label="Opening Quantity"
                  />
                  <TextField
                    size="small"
                    id="outlined-number"
                    type="number"
                    className="form-control my-2"
                    value={receivedQuantity}
                    required
                    onChange={(e) => setReceivedQuantity(e.target.value)}
                    label="Received Quantity"
                  />
                 
                  <TextField
                    size="small"
                    id="outlined-number"
                    type="number"
                    className="form-control my-2"
                    value={pcs_price}
                    onChange={(e) => setPcs_Price(e.target.value)}
                    label="Enter Price Per Pice"
                  />
                   {/* <Button className="mb-2" component="label" variant="contained" >
      Upload Image
      <VisuallyHiddenInput type="file" onChange={(e) => {
                      console.log("evfdcvfvfvc", e.target.files[0].name);
                      setImage(e.target.files[0]);
                      setImageName(e.target.files[0].name);
                    }}/>
    </Button> */}
               
                </div>

                <div className="mt-2">
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "rgb(46, 69, 175)",
                      outline: "none",
                      border: "none",
                      fontSize: "20px",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                    onClick={handleUploadImage}
                  >
                    Update
                  </button>

              
                  <span
                    // to="/retailer-dashboard"
                    onClick={() => {
                      setAddUpdateItemModalIsOpen(!addUpdateItemModalIsOpen);
                    }}
                    className="btn btn-primary mb-2"
                    style={{
                      backgroundColor: "grey",
                      outline: "none",
                      border: "none",
                      marginLeft: "20px",
                      fontSize: "20px",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                  >
                    Close
                  </span>
                </div>
              </form>
              {/* )} */}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>{/* <h1>FOOTER</h1> */}</ModalFooter>
      </Modal>
    </>
  );
};

export default AddItemModal;
