import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { BiSearchAlt2 } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Grid } from '@mui/material';

function ItemNameCodeSearchModal(props) {
  // Sample data for demonstration
  const { ItemCodeName } = props;
  

  return (
    <Modal
    {...props}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
                  <Modal.Body>
                
                            
                            
    <div>
    <div className='d-flex'>
                                < AiOutlineArrowLeft   onClick={() => props.onHide()} className='fs-4' /><h4 className='mx-3 mb-2'>InvoiceNumber</h4>
                             </div>
                           
    <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #ddd' }}>
        <thead>
          <tr style={{ border: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>#</th>
           
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>InvoiceNumber</th>

          </tr>
        </thead>
        <tbody>
        {ItemCodeName.map((item, index) => (
          <tr key={index+1} style={{ border: '1px solid #ddd' }}>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.Business_date}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.invoice_no}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
    
    </Modal.Body>
    </Modal>
  );
}

export default ItemNameCodeSearchModal;
