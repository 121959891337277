import React from 'react'
import {    
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
  } from "@mui/material";
const CreateCoupon = ({GetLoyaltyCusomer, Data, mobail, setMobail, handleonchange}) => {
  return (
    <div className="row d-flex justify-content-center">
        <form className="form-box w-50 " encType="Content-Type">
          <TextField
            size="small"
            type="text"
            className="form-control my-2"
            label="Enter Loyality Mobile"
            value={mobail}
            onChange={(e) => {
              setMobail(e.target.value);
            }}
            multiline
            required
            rows={1}
          />
          <TextField
            size="small"
            type="text"
            onChange={handleonchange}
            value={Data.coupon_name}
            className="form-control my-2"
            name="coupon_name"
            label="Coupun Name"
            multiline
            required
            rows={1}
          />
          <TextField
            size="small"
            type="text"
            onChange={handleonchange}
            value={Data.store_id}
            className="form-control my-2"
            name="store_id"
            label="Store Id"
            multiline
            required
            rows={1}
          />
          <FormControl fullWidth>
            <InputLabel id="isdcode-label" style={{ width: "fit-content" }}>
              Discount Type
            </InputLabel>
            <Select
              className="mb-3"
              label="Select Store"
              labelId="isdcode-label"
              id="discount_type"
              name="discount_type"
              value={Data.discount_type}
              onChange={handleonchange}
              // value={sdiscount_typeelectedStoreId}
              // onChange={handleonchange}
              required
            >
              <MenuItem value="per">Percentage</MenuItem>
              <MenuItem value="amt">Amount</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            type="text"
            onChange={handleonchange}
            value={Data.description}
            className="form-control my-2"
            name="description"
            label="Description"
            multiline
            required
            rows={1}
          />
          <TextField
            type="date"
            size="small"
            className="form-control my-2"
            onChange={handleonchange}
            value={Data.effective_from}
            name="effective_from"
            label="Effective Date"
            // multiline
            // required
            // rows={1}
          />
          <TextField
            type="date"
            size="small"
            className="form-control my-2"
            onChange={handleonchange}
            value={Data.expiration_date}
            name="expiration_date"
            label="expiration Date"
            // multiline
            // required
            // rows={1}
          />
          <TextField
            size="small"
            type="text"
            className="form-control my-2"
            onChange={handleonchange}
            value={`${
              Data.discount_type == "per"
                ? Data.discount_percent
                : Data.discount_amount
            }`}
            name={`${
              Data.discount_type == "per"
                ? "discount_percent"
                : "discount_amount"
            }`}
            label={`${
              Data.discount_type == "per"
                ? "Discount Percent"
                : "Discount Amount"
            }`}
            multiline
            required
            rows={1}
          />
          <TextField
            size="small"
            type="text"
            className="form-control my-2"
            onChange={handleonchange}
            value={Data.min_purchased_amount}
            name="min_purchased_amount"
            label="Minimum Purchased Amount"
            multiline
            required
            rows={1}
          />
          <div className="d-flex justify-content-center">
            <button
              className=""
              type="button"
              style={{
                backgroundColor: "rgb(46, 69, 175)",
                outline: "none",
                border: "none",
                fontSize: "20px",
                padding: "10px 20px",
                borderRadius: "10px",
                color: "#fff",
              }}
              onClick={GetLoyaltyCusomer}
            >
              Create Coupun
            </button>
          </div>
        </form>
      </div>
  )
}

export default CreateCoupon