import React, { useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  Row,
} from "reactstrap";
import Select from "react-select";
import moment from "moment";
import { Link } from "react-router-dom";
import { HiOutlineArrowSmallLeft } from "react-icons/hi2";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css"; 
const Bahikhata = (props) => {
  const [addBahikhataModalIsOpen, setAddBahikhataModalIsOpen] = useState(false);
  const [updateBahikhataModalIsOpen, setUpdateBahikhataModalIsOpen] = useState(false);
  const [updateItem, setUpdateItem] = useState("");
  const [updateIndexNumber, setUpdateIndexNumber] = useState("");
  const [addBahikhataArr, setAddBahikhataArr] = useState([]);
  const [bahikhataArr, setBahikhataArr] = useState({
    party: "",
    payment_type: "",
    payment_date: "",
    payment_mode: "",
    amount: "",
    payment_notes: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(bahikhataArr);
    // Handle your local state or any other logic here, API interaction is removed
    setTimeout(() => {
      setBahikhataArr({
        party: "",
        payment_type: "",
        payment_date: "",
        payment_mode: "",
        amount: "",
        payment_notes: "",
      });
      setAddBahikhataArr([]);
    }, 500);
  };
  const style={
    boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    borderRadius:"4px",
    background:"white",
    color:"black",
    border:"0px"
  }
  return (
    <>
      <div className="" >
        <div className="row d-flex justify-content-center" style={{ height: "fit-content" }}>
          <div className="col-lg-5 col-md-10 col-sm-12 px-5" style={style}>
            <Form  onSubmit={handleSubmit}>
              <div className="d-flex mt-3">
                <Link
                  to="/"
                  type="submit"
                  className="text-decoration-none"
                  style={{
                    color: "black",
                  }}
                >
                  <HiOutlineArrowSmallLeft className="mouse-pointer" />
                </Link>
                <h4 style={{ fontFamily: "bold", marginLeft: "10px" }}>Create Bahikhata</h4>
              </div>

              <ModalBody>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label>Select Party</Label>
                      <Select
                        options={[]}
                        onChange={(e) => {
                          const val = e.value;
                          setBahikhataArr({ ...bahikhataArr, party_name: val });
                        }}
                        value={null}
                        required={true}
                        placeholder="Select Party"
                      />
                    </FormGroup>
                  </Col>
         

<Col md={12}>
  <FormGroup>
    <Label>Payment Type</Label>
    <div className="d-flex">
      <div className="me-4">
        <Input
          type="radio"
          className="me-2 mouse-pointer"
          id="received-radio"
          onChange={(e) => {
            setBahikhataArr({
              ...bahikhataArr,
              payment_type: "received",
            });
          }}
          checked={bahikhataArr.payment_type === "received"}
          name="payment-type"
        />
        <Label htmlFor="received-radio" className="mouse-pointer">
          Received
        </Label>
      </div>
      <div className="me-4">
        <Input
          type="radio"
          className="me-2 mouse-pointer"
          id="given-radio"
          onChange={(e) => {
            setBahikhataArr({
              ...bahikhataArr,
              payment_type: "given",
            });
          }}
          checked={bahikhataArr.payment_type === "given"}
          name="payment-type"
        />
        <Label htmlFor="given-radio" className="mouse-pointer">
          Given
        </Label>
      </div>
    </div>
  </FormGroup>
</Col>

<Col md={12}>
  <FormGroup>
    <Label>Payment Date</Label>
    <Flatpickr
      style={{
        fontStyle: "italic",
        fontFamily: "Arial, sans-serif",
      }}
      className="form-control"
      onChange={(e) => {
        const d = e[0];
        setBahikhataArr({ ...bahikhataArr, payment_date: d });
      }}
      options={{ allowInput: true, dateFormat: "d-M-Y" }}
      value={bahikhataArr.payment_date}
      required={true}
      placeholder="Payment Date"
    />
  </FormGroup>
</Col>

<Col md={12}>
  <FormGroup>
    <Label>Payment Mode</Label>
    <div className="d-flex">
      <div className="me-4">
        <Input
          type="radio"
          className="me-2 mouse-pointer"
          id="online-radio"
          onChange={(e) => {
            setBahikhataArr({
              ...bahikhataArr,
              payment_mode: "online",
            });
          }}
          checked={bahikhataArr.payment_mode === "online"}
          name="payment-mode"
        />
        <Label htmlFor="online-radio" className="mouse-pointer">
          Online
        </Label>
      </div>
      <div className="me-4">
        <Input
          type="radio"
          className="me-2 mouse-pointer"
          id="cheque-radio"
          onChange={(e) => {
            setBahikhataArr({
              ...bahikhataArr,
              payment_mode: "cheque",
            });
          }}
          checked={bahikhataArr.payment_mode === "cheque"}
          name="payment-mode"
        />
        <Label htmlFor="cheque-radio" className="mouse-pointer">
          Cheque
        </Label>
      </div>
      <div>
        <Input
          type="radio"
          className="me-2 mouse-pointer"
          id="cash-radio"
          onChange={(e) => {
            setBahikhataArr({
              ...bahikhataArr,
              payment_mode: "cash",
            });
          }}
          checked={bahikhataArr.payment_mode === "cash"}
          name="payment-mode"
        />
        <Label htmlFor="cash-radio" className="mouse-pointer">
          Cash
        </Label>
      </div>
    </div>
  </FormGroup>
</Col>

<Col md={12}>
  <FormGroup>
    <Label>Amount</Label>
    <Input
      style={{
        fontStyle: "italic",
        fontFamily: "Arial, sans-serif",
      }}
      type="number"
      onChange={(e) => {
        const val = e.target.value;
        setBahikhataArr({ ...bahikhataArr, amount: val });
      }}
      value={bahikhataArr.amount}
      required={true}
      rows={4}
      placeholder="Enter Amount"
    />
  </FormGroup>
</Col>

<Col md={12}>
  <FormGroup>
    <Label>Payment Notes</Label>
    <Input
      style={{
        fontStyle: "italic",
        fontFamily: "Arial, sans-serif",
      }}
      type="textarea"
      onChange={(e) => {
        const val = e.target.value;
        setBahikhataArr({ ...bahikhataArr, payment_notes: val });
      }}
      value={bahikhataArr.payment_notes}
      required={true}
      rows={4}
      placeholder="Enter Payment Notes"
    />
  </FormGroup>
</Col>

                </Row>
              </ModalBody>

              <div className="mt-4 d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg btn-block"
                  style={{
                    backgroundColor: "var(--Acc-1, #457FD4)",
                    outline: "none",
                    border: "none",
                    fontSize: "14px",
                    padding: "10px 140px",
                    borderRadius: "10px",
                    color: "#fff",
                  }}
                >
                  Save
                </button>
              </div>

              <div className="mt-2 d-flex justify-content-center">
                <Link
                  to="/"
                  type="submit"
                  className="text-decoration-none"
                  style={{
                    color: "black",
                  }}
                >
                  Cancel
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bahikhata;
