// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-right {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh; /* Take the full height of the screen */
  width: 320px; /* Set the width of the modal */
  margin-left: 300px;
  transform: translate3d(25%, 0, 0);
}

.modal-right .modal-dialog {
  height: 100%;
  width: 100%;
  margin-left: 300px;
  transform: none; /* Reset the transform applied on the dialog */
}

.modal-right .modal-content {
  height: 100%;
  border: 0;
  border-radius: 10px;
}

@media (min-width: 576px) {
  .modal {
      --bs-modal-margin: 0.05rem;
      --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}
@media only screen and (min-width: 990px) {
  .sale-purchase-container {
      display: flex;
      justify-content: space-between;
  }

}
.pevent {
  margin: 0 0 .875rem;
  padding: .35rem;
}
.eventcontainer {
  box-shadow: 0 2px 12px rgba(36, 36, 39, .12);
  border-radius: .75rem;
  overflow: hidden;
}

.text{
  font-size: "12px";
}
input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,QAAQ;EACR,aAAa,EAAE,uCAAuC;EACtD,YAAY,EAAE,+BAA+B;EAC7C,kBAAkB;EAGlB,iCAAiC;AACnC;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,eAAe,EAAE,8CAA8C;AACjE;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE;MACI,0BAA0B;MAC1B,wDAAwD;EAC5D;AACF;AACA;EACE;MACI,aAAa;MACb,8BAA8B;EAClC;;AAEF;AACA;EACE,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,4CAA4C;EAC5C,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;AACA;;MAEM,wBAAwB;MACxB,SAAS;IACX;IACA;MACE,0BAA0B;IAC5B","sourcesContent":[".modal-right {\r\n  position: fixed;\r\n  top: 0;\r\n  right: 0;\r\n  height: 100vh; /* Take the full height of the screen */\r\n  width: 320px; /* Set the width of the modal */\r\n  margin-left: 300px;\r\n\r\n  -webkit-transform: translate3d(25%, 0, 0);\r\n  transform: translate3d(25%, 0, 0);\r\n}\r\n\r\n.modal-right .modal-dialog {\r\n  height: 100%;\r\n  width: 100%;\r\n  margin-left: 300px;\r\n  transform: none; /* Reset the transform applied on the dialog */\r\n}\r\n\r\n.modal-right .modal-content {\r\n  height: 100%;\r\n  border: 0;\r\n  border-radius: 10px;\r\n}\r\n\r\n@media (min-width: 576px) {\r\n  .modal {\r\n      --bs-modal-margin: 0.05rem;\r\n      --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);\r\n  }\r\n}\r\n@media only screen and (min-width: 990px) {\r\n  .sale-purchase-container {\r\n      display: flex;\r\n      justify-content: space-between;\r\n  }\r\n\r\n}\r\n.pevent {\r\n  margin: 0 0 .875rem;\r\n  padding: .35rem;\r\n}\r\n.eventcontainer {\r\n  box-shadow: 0 2px 12px rgba(36, 36, 39, .12);\r\n  border-radius: .75rem;\r\n  overflow: hidden;\r\n}\r\n\r\n.text{\r\n  font-size: \"12px\";\r\n}\r\ninput[type=\"number\"]::-webkit-inner-spin-button,\r\n    input[type=\"number\"]::-webkit-outer-spin-button {\r\n      -webkit-appearance: none;\r\n      margin: 0;\r\n    }\r\n    input[type=\"number\"] {\r\n      -moz-appearance: textfield;\r\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
