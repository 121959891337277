import React from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const SidebarModal = ({
  showModal,
  toggleModal,
  setModalRegister,
  setRegisterClose,
  handelRegiterClose,
}) => {
  const modalMargin = "-0.5rem";
  const { userType } = JSON.parse(localStorage.getItem("User_data"));
  return (
    <Modal
      show={showModal}
      onHide={toggleModal}
      dialogClassName="modal-right"
      centered={false} // This prevents the modal from being vertically centered
      style={{ margin: `var(--bs-modal-margin, ${modalMargin})` }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Daily Operation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {userType == "MANAGER" && (
          <Row>
            <Col sm={10}>
              {" "}
              Start of Day{" "}
              <Button
                variant="none"
                style={{
                  border: "solid grey 1px",
                  position: "relative",
                  left: "60px",
                }}
              >
                {" "}
                F8
              </Button>
            </Col>
            <Col>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to={"/startoftheday"}
              >
                <AiOutlineArrowRight />
              </Link>
            </Col>
          </Row>
        )}

        <hr />

       {userType == "CASHIER" && <><Row>
          <Col lg={9}>
            {" "}
            Register Open
            <Button
              variant="none"
              style={{
                border: "solid grey 1px",
                position: "relative",
                left: "48px",
              }}
            >
              {" "}
              F9
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => setModalRegister(true)}
              style={{ position: "relative", left: "10px" }}
              variant="none"
            >
              <AiOutlineArrowRight />
            </Button>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col xs={10}>
            Till Options
            <Button
              variant="none"
              style={{
                border: "solid grey 1px",
                position: "relative",
                left: "70px",
              }}
            >
              {" "}
              F10
            </Button>
          </Col>
          <Col>
            <Link to={"/tilloptions"}>
              <AiOutlineArrowRight />
            </Link>
          </Col>
        </Row>

        <hr />
        {/* <Row>
          <Col xs={9}>
            Register Close
            <Button
              variant="none"
              style={{
                border: "solid grey 1px",
                position: "relative",
                left: "50px",
              }}
            >
              {" "}
              F11
            </Button>
          </Col>

          <Col>
            <Button
              onClick={handelRegiterClose}
              style={{ position: "relative", left: "10px" }}
              variant="none"
            >
              <AiOutlineArrowRight />
            </Button>
          </Col>
        </Row>

        <hr /> */}
         </>}
        { userType == "MANAGER" &&<><Row>
          <Col sm={10}>
            {" "}
            End of Day
            <Button
              variant="none"
              style={{
                border: "solid grey 1px",
                position: "relative",
                left: "77px",
              }}
            >
              {" "}
              F12
            </Button>
          </Col>
          <Col>
            <Link to={"/endofday"}>
              <AiOutlineArrowRight />
            </Link>
          </Col>
        </Row>
        <hr />
        </>
        }

      </Modal.Body>
    </Modal>
  );
};

export default SidebarModal;
