import React, { useState } from "react";
import { BASEURL } from "../../services/http-common";
// import { Button } from 'react-bootstrap';
import moment from "moment";
import { Button, Card, CardBody } from "reactstrap";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";

const BossReport = () => {
  const [businessDate, setBusinessDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [Storeid, setStoreid] = useState("");
  const downloadCSV = async (URL, name) => {
    try {
        if (!Storeid) {
            Swal.fire({
              title:"Please Selecte Store Id",
              icon: "warning",
            })  
          return
        }
      // Set downloading state to true to show loading indicator
      //   this.setState({ downloading: true });

      // Replace the API URL with the actual URL
      const response = await fetch(URL);
      console.log(response);
      // Check if response is successful
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const csvData = await response.text();

      // Create a blob object from CSV data
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a URL for the blob object
      const url = window.URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${Storeid}_${businessDate}${name}.csv`);

      // Append the link to the body and click it
      document.body.appendChild(link);
      link.click();

      // Clean up resources
      window.URL.revokeObjectURL(url);

      // Reset downloading state
      //   this.setState({ downloading: false });
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      // Reset downloading state
      //   this.setState({ downloading: false });
    }
  };
  return (
    <Card>
    <CardBody>

      <div className="d-flex" style={{ marginBottom: "20px" }}>
        <label
          className="mt-2"
          htmlFor="fileInput"
          style={{ fontSize: "16px", marginRight: "10px" }}
        >
          {/* {fileFlag} */}
           Download Csv:
        </label>
        <input
          type="date"
          id="csvfile"
          value={businessDate}
          onChange={(e) => {
            setBusinessDate(e.target.value);
          }}
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
          }}
        />
        <Form.Select className="w-50" onChange={(e) => {
            setStoreid(e.target.value);
          }}
           aria-label="Default select example">
      <option>Select Store</option>
      <option value="10002">Splash,Dream City,Baghdad</option>
      <option value="10003">BABYSHOP, TIME Square, Basra</option>
    </Form.Select>
      

        {/* <div className='my-auto'>
            <Button
            disabled={csvFile?false:true}
            className=' ml-4'
                type='button'
                onClick={(e) => handleUploadFile()}
                style={{marginLeft:"30px", backgroundColor: "rgb(46, 69, 175)", border: "none" }}> Submit </Button>
        </div> */}
      </div>

      <div className="d-flex">
      <Button
        onClick={() => {
          downloadCSV(
            `${BASEURL.ENDPOINT_URL}/csv/itemreport/${businessDate}/${Storeid}`,
            "ItemReport"
          );
        }}
        className=" mr-4"
        type="button"
      >
        Download Item Report Csv
      </Button>
      <Button
        className="mx-3"
        onClick={() => {
          downloadCSV(
            `${BASEURL.ENDPOINT_URL}/csv/sfile/${businessDate}/${Storeid}`,
            "Header"
          );
        }}
        type="button"
      >
        Download Header Report Csv
      </Button>
      <Button
        onClick={() => {
          downloadCSV(
            `${BASEURL.ENDPOINT_URL}/csv/tenderreport/${businessDate}/${Storeid}`,
            "Tender"
          );
        }}
        className=" mr-4"
        type="button"
      >
        Download Tender Report Csv
      </Button>
      </div>    
    </CardBody>
    </Card>
  );
};

export default BossReport;
