import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Label } from "reactstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FormGroup, TextField } from "@mui/material";
import DataService from "../services/requestApi";
const DeliveryChalan = () => {
  const [addProduct, setAddProduct] = useState(false);
  const [incomingData, setIncomingData] = useState(["danish"]);
  const [partyName, setPartyName] = useState("");
  const style = {
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    background: "white",
    color: "black",
    border: "0px",
  };
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));

  async function getParties(store_id, saas_id, name) {
    try {
      const response = await DataService.GetParties(store_id, saas_id, name);

      const partiesData = response.data;

      console.log(partiesData);
    } catch (error) {
      console.error(error);
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if (partyName === "") {
      return alert("Please enter a valid name!");
    } else {
      getParties(storeId, saasId, partyName);
    }
  };
  return (
    <>
      <div>
        <div
          className="row d-flex justify-content-center"
          styel={{ height: "fit-content" }}
        >
          <div className="col-lg-5 col-md-10 col-sm-12 px-5" style={style}>
            <form className="form-box">
              <div className="d-flex mt-3">
                <Link
                  to="/"
                  type="submit"
                  className="text-decoration-none"
                  style={{
                    color: "black",
                  }}
                >
                  {" "}
                  <i className="fa-solid fa-chevron-left mt-1"></i>
                </Link>
                <h4 style={{ fontFamily: "bold", marginLeft: "10px" }}>
                  Delivery challan
                </h4>
              </div>

              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <TextField
                    type="text"
                    className="form-control my-2"
                    id="customer-name"
                    size="small"
                    label="Debit Node#"
                  />
                </div>

                <div className="container p-0">
                  <div className="input-group">
                    <input type="date" className="form-control" />
                  </div>
                </div>
              </div>
              <TextField
                type="text"
                className="form-control my-2"
                id="customer-name"
                size="small"
                label="Search by Phone Number"
              />
              <div>
                <label htmlFor="partyInput">
                  Party{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984ZM10.0003 5.20817C10.3455 5.20817 10.6253 5.48799 10.6253 5.83317V10.8332C10.6253 11.1783 10.3455 11.4582 10.0003 11.4582C9.65515 11.4582 9.37533 11.1783 9.37533 10.8332V5.83317C9.37533 5.48799 9.65515 5.20817 10.0003 5.20817ZM10.0003 14.1665C10.4606 14.1665 10.8337 13.7934 10.8337 13.3332C10.8337 12.8729 10.4606 12.4998 10.0003 12.4998C9.54009 12.4998 9.16699 12.8729 9.16699 13.3332C9.16699 13.7934 9.54009 14.1665 10.0003 14.1665Z"
                      fill="#393939"
                    />
                  </svg>
                </label>
                <br />
                <input
                  style={{
                    padding: "0.5rem",
                    fontSize: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    width: "100%",
                    boxSizing: "border-box",
                    marginTop: "0.5rem",
                  }}
                  type="text"
                  id="partyInput"
                  list="partyList"
                  placeholder="Select Item"
                  onChange={(e) => setPartyName(e.target.value)}
                />

                <datalist id="partyList">
                  {incomingData?.map((data) => (
                    <option value={data} />
                  ))}
                </datalist>
              </div>

              <div style={{ width: "100%" }}>
                <Label className="mt-3">
                  Product{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984ZM10.0003 5.20817C10.3455 5.20817 10.6253 5.48799 10.6253 5.83317V10.8332C10.6253 11.1783 10.3455 11.4582 10.0003 11.4582C9.65515 11.4582 9.37533 11.1783 9.37533 10.8332V5.83317C9.37533 5.48799 9.65515 5.20817 10.0003 5.20817ZM10.0003 14.1665C10.4606 14.1665 10.8337 13.7934 10.8337 13.3332C10.8337 12.8729 10.4606 12.4998 10.0003 12.4998C9.54009 12.4998 9.16699 12.8729 9.16699 13.3332C9.16699 13.7934 9.54009 14.1665 10.0003 14.1665Z"
                      fill="#393939"
                    />
                  </svg>
                </Label>

                <>
                  {addProduct ? (
                    <div
                      className="rounded-3"
                      style={{
                        background: " white",
                        padding: " 15px",
                        marginTop: "10px",
                      }}
                    >
                      <div className="d-flex justify-content-around">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FormControl component="fieldset" style={{ flex: 1 }}>
                            <RadioGroup
                              name="options"
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <FormControlLabel
                                value="option1"
                                control={<Radio />}
                                label="Price"
                              />
                              <FormControlLabel
                                value="option2"
                                control={<Radio />}
                                label="Stock"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                      <TextField
                        size="small"
                        type="text"
                        className="form-control mt-2"
                        id="customer-name"
                        label="Product Name"
                        required
                      />
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="" style={{ flex: 1 }}>
                          <p>Selling Price</p>
                          <TextField
                            size="small"
                            type="text"
                            className="form-control mt-2"
                            id="customer-name"
                            label="₹ 0"
                            required
                          />
                        </div>
                        <div
                          className="d-flex flex-col mt-1"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                          }}
                        >
                          <div className="mb-3" styles={{ fontSize: "15px" }}>
                            Sell Price Tax{" "}
                          </div>
                          <div>
                            <Select
                              required={true}
                              className=""
                              placeholder="Gst Type"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="" style={{ flex: 1 }}>
                          <p>Purchase Price</p>
                          <TextField
                            size="small"
                            type="text"
                            className="form-control mt-2"
                            id="customer-name"
                            label="₹ 0"
                            required
                          />
                        </div>
                        <div
                          className="d-flex flex-col"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                          }}
                        >
                          <p style={{ textWrap: "nowrap" }}>
                            Purchase PriceTax{" "}
                          </p>
                          <div className="mt-1">
                            <Select
                              required={true}
                              className=""
                              placeholder="Gst Type"
                            />
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="my-3">
                        <p className="my-1">GST %</p>
                        <div>
                          <Select required={true} placeholder="Gst Type" />
                        </div>
                      </div>
                      <div className="my-3">
                        <p className="my-1">Units</p>
                        <Select placeholder="Select Unit" />
                      </div>
                      <Button
                        className="btn btn-primary btn-lg btn-block"
                        style={{
                          backgroundColor: "var(--Acc-1, #457FD4)",
                          outline: "none",
                          border: "none",
                          fontSize: "14px",
                          padding: "10px 120px",
                          borderRadius: "10px",
                          color: "#fff",
                        }}
                      >
                        Add
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </>

                <button
                  className="btn btn-outline-primary mt-3"
                  style={{
                    ...style,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "30px",
                    backgroundColor: "white",
                    color: "silver",
                    border: "aliceblue",
                  }}
                  onClick={() => setAddProduct((state) => !state)}
                >
                  <AiOutlinePlusCircle />
                  Add Item (Optional)
                </button>
              </div>

              <div className="d-flex justify-content-between  mt-2">
                <p className="fw-bold">Optional</p>
                {/* <h5 className="text-primary fw-bold">
                <AiOutlinePlusCircle className="mx-2" />
                Additional Charges
              </h5> */}
              </div>

              <div className="rounded">
                <div className="d-flex align-items-center justify-content-between">
                  <TextField
                    size="small"
                    type="number"
                    className="form-control"
                    id="customer-name"
                    label="Amount"
                    required
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <TextField
                    size="small"
                    type="number"
                    className="form-control mt-2"
                    id="customer-name"
                    label="Charges"
                    required
                  />
                </div>
              </div>

              <div className="mt-5 d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg btn-block"
                  style={{
                    backgroundColor: "var(--Acc-1, #457FD4)",
                    outline: "none",
                    border: "none",
                    fontSize: "14px",
                    padding: "10px 140px",
                    borderRadius: "10px",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
              <div className="mt-2 d-flex justify-content-center">
                <Link
                  to="/"
                  type="submit"
                  className="text-decoration-none"
                  style={{
                    color: "black",
                  }}
                >
                  Cancel
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryChalan;
