import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { BiSearchAlt2 } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { AiOutlineCheckCircle } from 'react-icons/ai'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Include other necessary imports

function RegisterModal(props) {
    const {HandeRegisterOpen} = props
    const [RegId, setRegId] = useState('')
    const ref = useRef()
    const {show}= props
    useEffect(() => {
      if(show){
       ref.current.focus()
      }
    }, [show])
    
  return (
    <Modal
    {...props}

    aria-labelledby="contained-modal-title-vcenter"
    centered
>
    {/* <AiOutlineCheckCircle style={{ width: "70px", height: "70px", color: "green", margin: "50px 0px 0px 200px" }} /> */}

    <Modal.Body>
    <Col>
    <Form>
                   <Form.Select  ref={ref} onChange={(e)=>setRegId(e.target.value)} aria-label="Default select example">
      <option>Open this select menu</option>
      <option value="REG1">REG1</option>
      <option value="REG2">REG2</option>
      <option value="REG3">REG3</option>
      <option value="REG4">REG4</option>
      <option value="REG5">REG5</option>
      <option value="REG6">REG6</option>
      <option value="REG7">REG7</option>
      <option value="REG8">REG8</option>
      <option value="REG9">REG9</option>
      <option value="REG10">REG10</option>
    </Form.Select>
    <Button onClick={(e)=>{ e.preventDefault();
        HandeRegisterOpen(RegId)
        setRegId('')}}> enter</Button>
    </Form>
                  </Col>
    </Modal.Body>
    

</Modal>
  );
}

export default RegisterModal;
