import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { BiSearchAlt2 } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DataService from '../../services/requestApi'
function ViewLogmodal(props) {

    const [viewlogs, setViewLogs]= useState('')
    const {selectRow, show}=props
    const GetViewlogs=async()=>{
      try {
        const response =await DataService.GetViewLogs(selectRow.user_id, selectRow.register_id,  selectRow.log_id)
        console.log("Get view logs", response.data)
        setViewLogs(response.data)
       
      } catch (error) {
        console.log("error", error)
      }
    
    }

    useEffect(() => {
        if(show){

            GetViewlogs()
        }
    }, [show])
    
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
                    <Modal.Body>
              

                    <Row>
                         <Col>
                             <div className='d-flex'>
                              < AiOutlineArrowLeft onClick={()=>props.onHide()} className='fs-4' /><h4 className='mx-3'>View Logs</h4>
                             </div>
                           
                             <Row>
                              <p> 
                                {viewlogs}
                              </p>
                             </Row>

                         </Col>
                     </Row>



                 </Modal.Body>
    </Modal>
  );
}

export default ViewLogmodal;
