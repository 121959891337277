import React, { useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { BiSearchAlt2 } from "react-icons/bi";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataService from "../services/requestApi";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

function CheckPriceModal(props) {
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const [itemId, setItemId] = useState("");
  const [data, setdata] = useState("");
  const [styleCode, setStyleCode] = useState("");
  const [styleData, setStyleData] = useState("");
  const focus = useRef(null); // Initialize the ref with null

  const CheckPrice = async (e) => {
    e.preventDefault();
    try {
      if(itemId){
        const response = await DataService.GetItemById(storeId, saasId, itemId);
        console.log("this price check", response.data.data);
        if (response.data.status) {
          setdata(response.data.data[0]);
          setItemId("");
        }
      }
    } catch (error) {
      console.log("this catch error", error);
    }
  };

  const LookupByStyleCode = async (code) => {
    try {
      const response = await DataService.GetItemBYStyleCode(code);
      console.log("Lookup by style code", response.data);
      if (response.data.status) {
        setStyleData(response.data.data);
        setStyleCode("");
        console.log("setyled data", styleData);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (focus.current) {
      focus.current.focus();
    }
  }, []);

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
      <div className='d-flex'>
                               < AiOutlineArrowLeft   onClick={() => props.onHide()} className='fs-4' /><h4 className='mx-3'>Item Lookup</h4>
                             </div>
        <Tabs
          defaultActiveKey="priceCheck"
          id="justify-tab-example"
          className="mt-3"
        >
          <Tab eventKey="priceCheck" title="Price Check">
            <Row>
              <Col>
                <Row className="my-4">
                  <Col className="d-flex justify-content-around">
                    <span>Item Name: {data?.item_name} </span>
                    <span>Price: {data && `IQD: ${data.price}`} </span>
                  </Col>
                </Row>
                <Row>
                  <Form onSubmit={(e) => CheckPrice(e)}>
                    <Col className="d-flex">
                      <FormControl
                        type="text"
                        autoFocus
                        value={itemId}
                        onChange={(e) => setItemId(e.target.value)}
                        placeholder="Enter item barcode"
                        className="mr-2"
                        aria-label="Search"
                      />

                      <Button
                        type="button"
                        onClick={(e) => {
                          CheckPrice(e);
                        }}
                        variant="primary"
                      >
                        <BiSearchAlt2
                          style={{ height: "2rem", width: "2rem" }}
                        />
                      </Button>
                    </Col>
                  </Form>
                </Row>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="lookup" title="Lookup">
            <Row>
              <Col>
                <Row className="my-4">
                  <Col className="d-flex justify-content-around">
                    <span>Style Name: {styleData?.style_name} </span>

                    <span>
                      {styleData && `Item Code: ${styleData.item_code}`}{" "}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      LookupByStyleCode(styleCode);
                    }}
                  >
                    <Col className="d-flex">
                      <FormControl
                        type="text"
                        autoFocus
                        value={styleCode}
                        onChange={(e) => setStyleCode(e.target.value)}
                        placeholder="Enter style code"
                        className="mr-2"
                        aria-label="Search"
                      />

                      <Button type="submit" variant="primary">
                        <BiSearchAlt2
                          style={{ height: "2rem", width: "2rem" }}
                        />
                      </Button>
                    </Col>
                  </Form>
                </Row>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

export default CheckPriceModal;
