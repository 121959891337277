import React from 'react';
import Modal from 'react-bootstrap/Modal';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Export from "react-data-table-component";
import { Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';


function TillSummaryModal({data,show,setModalShow,columns,setActivetab,Activetab,setDate,date}) {
    

  const downloadCSV = (data) => {
    // Check if data is not null and has at least one row
    if (data && data.length > 0) {
      const csvData = data.map((row) => Object.values(row).join(",")).join("\n");
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "StoreSummry.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      // Provide feedback or handle the case where there is no data to download
      Swal.fire({
        title:"No data available for download.",
        icon:"info",
        timer:1500
      })
      // You can also show a message to the user if needed.
    }
  };
  const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []);
    //   console.log("avergsale", )
  return (
    <Modal
    show={show}
    fullscreen={true}
   onHide={()=>{
    setActivetab('')
    setModalShow(false)}}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
       <Modal.Header closeButton>
       <Modal.Title>{Activetab === 'TillSummary' ? 'TillSummary' : 'Store Summary'}</Modal.Title>
        </Modal.Header>
                    <Modal.Body>
                 
       
     
    
               
                    <div className="d-flex justify-content-between m-2">
                    {Activetab == 'StoreSummary'&&<Form.Control
          type="date"
          className='w-50'
          id="exampleColorInput"
          defaultValue="#563d7c"
          title="Choose your color"
          value={date}
          onChange={(e)=>{setDate(e.target.value)}}
        />}
                    {Activetab == 'TillSummary'&&<Form.Control
          type="date"
          className='w-50'
          id="exampleColorInput"
          defaultValue="#563d7c"
          title="Choose your color"
          value={date}
          onChange={(e)=>{setDate(e.target.value)}}
        />}
             <Button onClick={()=>downloadCSV(data)}>Export</Button>
        </div>
      {/* <Export onExport={() => downloadCSV(data)} /> */}
          <DataTable
          actions={actionsMemo}
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
        columns={columns}
        responsive={true}
        fixedHeader={true}
        // fixedHeaderScrollHeight="300px"
        // paginationTotalRows={count}
        data={data}
        // progressPending={loading}
        pagination
        paginationServer
        // paginationTotalRows={item_master_list ? item_master_list.totalCount : 1}
        // onChangeRowsPerPage={10}
        // onChangePage={(page) => handlePageChange(page)}
      />


                 </Modal.Body>
    </Modal>
  );
}

export default TillSummaryModal;
