import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Col from "react-bootstrap/Col";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import DataService from "./services/requestApi";
import Swal from "sweetalert2";
import moment from "moment";
export const Startoftheday = () => {
  const navigate = useNavigate();
  const focusRef = useRef(null);
  const nextRef = useRef(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [date, setDate] = useState(moment(Date.now()).format("YYYY-MM-DD"));
  const [amount, setAmount] = useState("");
  const [isSubmeeting, setIsSubmeeting]=useState(false)
  const { saasId, storeId, registerId, userType } = JSON.parse(
    localStorage.getItem("User_data")
  );
  const data = {
    store_id: storeId,
    saas_id: saasId,
    business_Date: date,
    amount: amount,
    register_id: registerId,
  };
  const HandelStartoftheday = async () => {
    setIsSubmeeting(true)
    try {
      const response = await DataService.Startofthedat(data);
      console.log("this is response for statof the day", response.data);
      if (response.data.status) {
        Swal.fire({
          title: response.data.message,
          icon: "success",
          timer: 2000,
        });
        navigate("/");
        setIsSubmeeting(false)
      }
    } catch (error) {
      console.log(error);
      setIsSubmeeting(false)
      Swal.fire({
        text: error.response.data.message,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    if (userType !== "MANAGER") {
      navigate("/");
      Swal.fire({
        title: "Only Manger Can Open Store",
        icon: "info",
        timer: 1000,
      });
    }
  }, [userType]);

  useEffect(() => {
    focusRef.current.focus();
  }, []);

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Row>
          <Col className="p-2">
            <Col>
              <center>
                <AiOutlineCheckCircle
                  style={{ color: "green", width: "70px", height: "70px" }}
                />
              </center>
            </Col>
            <Modal.Body>
              <Col className="d-flex justify-content-center ">
                <h4> Store opened Successfully</h4>
              </Col>
              <Col>
                <center>
                  <p className="my-3"> Press enter to continue </p>
                </center>
              </Col>
            </Modal.Body>
          </Col>
        </Row>
      </Modal>
    );
  }

  const handelOnchange = (e) => {
    console.log("this is oncahnge", e.target.value);
    setDate(e.target.value);
  };
  return (
    <div>
      <Navbar className="justify-content-Between">
        <Container>
          <Navbar.Collapse className="justify-content-center">
            <Navbar.Brand>Photon Softwares</Navbar.Brand>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <hr />

      <Container>
        <Row>
          <div className="d-flex my-5">
            <Link to={"/"} className="text-dark fs-4">
              <AiOutlineArrowLeft />
            </Link>
            <h5 className="mx-4 my-2">Start of Day </h5>
          </div>
          <Col>
            <div>
              <Form onSubmit={(e)=>{
                e.preventDefault()
                nextRef.current.focus()
              }}>
                <h6 className="my-4">Business Date</h6>
                <Form.Control
                  onChange={handelOnchange}
                  value={date}
                  type="date"
                ></Form.Control>
                <h6 className="my-4">Amount in the store safe</h6>
                <Form.Control
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter amount"
                  ref={focusRef}
                ></Form.Control>
              </Form>
            </div>

            <div className="my-5 ml-5">
              <center>
                <div className="my-5">
                  <Button
                    className="text-White  border  mx-2"
                    variant="none"
                    style={{ width: "250px" }}
                  >
                    <span className="float-start my-2">Cancel</span>{" "}
                    <Button
                      className=" border float-end text-dark"
                      variant="none"
                    >
                      Esc
                    </Button>
                  </Button>
                  <Button
                    className="text-White  border  mx-2"
                    variant="none"
                    style={{ width: "250px" }}
                  >
                    <span className="float-start my-2">Undo</span>{" "}
                    <Button
                      className=" border float-end text-dark"
                      variant="none"
                    >
                      Ctlr+z
                    </Button>
                  </Button>
                  <Button
                  ref={nextRef}
                  disabled={isSubmeeting}
                    className="text-White  border  mx-2"
                    style={{ width: "250px", background: "#2E45AF" }}
                    onClick={HandelStartoftheday}
                  >
                    <span
                      className="float-start my-2"
                      style={{ background: "#2E45AF" }}
                    >
                      Next
                    </span>{" "}
                    <Button
                      className=" border float-end text-white"
                      style={{ background: "#2E45AF" }}
                    >
                      Enter
                    </Button>
                  </Button>
                </div>
              </center>
            </div>
          </Col>
          <>
            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </>
        </Row>
      </Container>
    </div>
  );
};
