import React, { useState, useEffect, useCallback } from "react";
import Modal from 'react-bootstrap/Modal';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import DataService from '../../../services/requestApi'
import Select from "react-select";
import { ButtonGroup } from "react-bootstrap";
const CurrancyModal = ({modalShow,setModalShow,GetCurrencyMaster,activeRow}) => {
  const [CurrancyData, setCurrncydata]=useState({
    exchange_rate: activeRow.exchange_rate,
  effective_date: activeRow.exchange_rate,
  end_date: activeRow.end_date
  })

  const {exchange_rate,effective_date,end_date} = CurrancyData

   const handChange = (e)=>{
    setCurrncydata({
        ...CurrancyData,
        [e.target.name]:e.target.value
    })
   }



   const handelSave =async ()=>{
    try {
        const response = await DataService.AddCurrancyMaster(activeRow.id,CurrancyData)
        if(response.data.status){
          GetCurrencyMaster()
          setModalShow(false)
        }
        console.log(response)
    } catch (error) {
        console.log(error)
    }
   }




  return (
    <Modal  
     show={modalShow}
    onHide={()=>{setModalShow(false)}}
    aria-labelledby="contained-modal-title-vcenter"
    centered>
  <Modal.Header closeButton>
    <Modal.Title>User Master</Modal.Title>
  </Modal.Header>
   
    <Form className="container mx-5 mb-3" style={{width:"fit-content"}} >
              <Row className="mt-4">

              <Col md={8}>
                  <FormGroup>
                    {/* <Label
                          style={{color: "#000",

                          fontFamily: "Segoe UI",
                       fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal"}}>
                    Register Id<span className="text-red"> * </span>
                    </Label> */}
                    <Input
                      type="text"
                      name="exchange_rate"
                      value={exchange_rate}
                      required={true}
                      onChange={handChange}
                      placeholder="Enter Exchange Rate"
                      style={{boxShadow: "0 2px 12px rgba(36, 36, 39, .12)",
                      borderRadius: ".75rem",
                      overflow: "hidden"}}
                    />
                  </FormGroup>
                </Col>

                <Col md={8}>
                  <FormGroup>
                    <Input
                      type="date"
                      value={effective_date}
                      name="effective_date"
                      onChange={handChange}
                      required={true}
                      placeholder="Enter effective Date"
                      style={{boxShadow: "0 2px 12px rgba(36, 36, 39, .12)",
                        borderRadius: ".75rem",
                        overflow: "hidden"}}
                    />
                  </FormGroup>
                </Col>

                <Col md={8}>
                  <FormGroup>
                    
                    <Input
                      type="date"
                      name="end_date"
                      value={end_date}
                      onChange={handChange}
                    //   required={true}
                      placeholder="End Date"
                      style={{boxShadow: "0 2px 12px rgba(36, 36, 39, .12)",
                      borderRadius: ".75rem",
                      overflow: "hidden"}}
                    />
                  </FormGroup>
                </Col>
                <Button className="w-75" onClick={()=>{handelSave()}} variant="primary">Save</Button>
                {/* <Col md={8}>
                  <FormGroup>
                    <Label
                          style={{color: "#000",

                          fontFamily: "Segoe UI",
                       fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal"}}>
                    Store Name <span className="text-red"> * </span>
                    </Label>
                    <Input
                      type="text"
                    //   onChange={(e) => {
                    //     setStoreName(e.target.value);
                    //   }}
                    //   value={storeName}
                      required={true}
                      placeholder="Enter Store Name"
                      style={{boxShadow: "0 2px 12px rgba(36, 36, 39, .12)",
                      borderRadius: ".75rem",
                      overflow: "hidden"}}
                    />
                  </FormGroup>
                </Col>

            

              

                <Col md={8}>
                  <FormGroup>
                    <Label 
                    style={{color: "#000",

                      fontFamily: "Segoe UI",
                   fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal"}}>
                    Country<span className="text-red"> * </span>
                    </Label>
                   
                    <Input
                    // options={state_dropdown}
                   
                    required={true}
                    placeholder="Select Country"
                    style={{boxShadow: "0 2px 12px rgba(36, 36, 39, .12)",
                     borderRadius: ".75rem",
                     overflow: "hidden"}}
                    />
                  
                  </FormGroup>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Label 
                    style={{color: "#000",

                      fontFamily: "Segoe UI",
                   fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal"}}>
                    User Role<span className="text-red"> * </span>
                    </Label>
                   
                    <Input
                    // options={state_dropdown}
                   
                    required={true}
                    placeholder="Select User Role"
                    style={{boxShadow: "0 2px 12px rgba(36, 36, 39, .12)",
                     borderRadius: ".75rem",
                     overflow: "hidden"}}
                    />
                  
                  </FormGroup>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Label 
                    style={{color: "#000",

                      fontFamily: "Segoe UI",
                   fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal"}}>
                    Currency<span className="text-red"> * </span>
                    </Label>
                   
                    <Input
                    // options={state_dropdown}
                   
                    required={true}
                    placeholder="Select Currency"
                    style={{boxShadow: "0 2px 12px rgba(36, 36, 39, .12)",
                     borderRadius: ".75rem",
                     overflow: "hidden"}}
                    />
                  
                  </FormGroup>
                </Col>

                <Col md={8}>
                  <div className="d-flex justify-content-end">
                    <FormGroup>
                      <Label>&nbsp;</Label>
                      <div>
                        <Button
                          style={{
                            borderRadius:" 8px;",
                            background:" #0067CE"
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </FormGroup>
                  </div>
                </Col> */}
              </Row>
            </Form>
 
  </Modal>
  )
}

export default CurrancyModal