import React, { useState, useEffect } from 'react'
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

function DisCountConformModal(props) {
  
    
   
  return (
    <Modal
    {...props}
    aria-labelledby="contained-modal-title-vcenter"
    className='rounded-5'
    centered
   
  >
                    <Modal.Body >
    <div className='p-2 m-1 font-bold' style={{ border:"solid grey 1px "}}>
    <Link to={'/itemsfordiscount'} style={{ color: "black", margin: "20px 0px 0px 00px" }}> < AiOutlineArrowLeft style={{ width: "30px", height: "20px" }} /></Link>
            <h5 style={{ margin: "-24px 0px 0px 40px" }}>Select an Option</h5>
            <div style={{ margin: "20px 0px 0px 00px", height: "500px", width: "500px" }}>
                <Form>
                  
                    <Row style={{ margin: "40px 0px 0px -10px" }}>
                        <Col sm={2}>
                            <input type="radio" name="percentage" value="percentage" style={{ height: "25px", width: "25px" }} /><div style={{ height: "50px", width: "50px", margin: "-30px 0px 0px 40px" }} >%</div>
                        </Col>
                        <Col>
                            <input type="radio" name="percentage" value="percentage" style={{ height: "25px", width: "25px" }} /><div style={{ height: "50px", width: "50px", margin: "-30px 0px 0px 40px" }} >AED</div></Col>
                    </Row>

                    <Form.Label>Discount(%)</Form.Label>
                    <Form.Control style={{width:"88%"}} placeholder="0"></Form.Control>

                </Form>
                <Button className="text-dark"  variant="none" style={{height:"45px",width:"218px",border:"solid grey 1px",margin:"245px 0px 0px  0px"}}>Cancel <Button className="text-dark" style={{border:"solid grey 1px",margin:" 0px 0px 0px 90px ",height:"30px",width:"50px"}}  variant="none" > <Link onClick={props.onHide}  style={{textDecoration:"none",color:"black"}}> Esc</Link></Button></Button>
                  <Button className="text-White"   style={{backgroundColor:"#2E45AF", height:"45px",width:"212px",border:"solid grey 1px",margin:"245px 0px 0px  17px"}}>Next <Button className="text-white" style={{backgroundColor:"#2E45AF", border:"solid grey 1px",margin:" 0px 0px 0px 90px ",height:"30px",width:"60px"}} ><Link to={'/discountapproval'} style={{textDecoration:"none",color:"white"}}> Enter </Link></Button></Button>
                
            </div>
    </div>
    </Modal.Body>
    </Modal>
  )
}

export default DisCountConformModal