import React, { useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Barcode from 'react-barcode';
import logo from '../Images/logoHlmg.jpg'
import { useReactToPrint } from 'react-to-print'
function GetItemBarcodeModal({ item, ...props }) {
    const {show}= props
    const styles = {
        fontFamily: 'Verdana',
        fontSize: 'small',
        fontWeight: 'bold',
      };
      const barcodeStyles = {
        fontSize: 'smaller',
        /* Add more styles as needed */
    };
    const componentRef = useRef();
    const handlePrint = useReactToPrint(
      {
      content: () => componentRef.current,
    });

    useEffect(() => {
        const handleKeyPress = (e) => {
          
            if (e.keyCode === 80) { // 80 is the key code for P print
                if(show){
                    console.log("this is P Button",e.keyCode)
                    e.preventDefault();
                    handlePrint()

                }
                // print()
              }        
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
          };
    }, [show]);
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div ref = {componentRef}>
                {item.map((row, index) => (
                    <Row   key={index} className='border border-dark m-2'>
                            <Col >
                            <p className='m-0 d-grid col-6' style={{ ...styles, display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', WebkitLineClamp: 2, textOverflow: 'ellipsis' }}>Description: {row.description}</p>

                               
                                <p className='m-0 ' style={styles}>Mrp: {row.actual_price}</p>
                                <p className='m-0' style={styles}>OFFER PRICE: {row.price}</p>
                                 <div  style={{width:"fit-content"}} className='d-flex  border border-dark m-2'  >
                                    <Barcode  value={row.item_id} width={1.4} height={20} />
                                    <img className='mx-2 my-2'
                    style={{ margin: "0px 0px 0px 0px" }}
                    height="50px"
                    width="100px"
                    src={logo} />
                            </div>
                            </Col>
                                {/* <Col>
                           </Col> */}
                    </Row>
                ))}
                </div>
           <div  className='d-flex justify-content-end'> <button style={{backgroundColor: "rgb(46, 69, 175)"}} className="btn mt-10 p-2 text-white" onClick={handlePrint}>
                 PRINT ( P )
                </button></div> 
            </Modal.Body>
        </Modal>
    );
}

export default GetItemBarcodeModal;
