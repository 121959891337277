import React, { useEffect, useRef, useState } from "react";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { PiNote } from "react-icons/pi";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { AiOutlineArrowLeft, AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { LuCreditCard } from "react-icons/lu";
import { PiCrownBold } from "react-icons/pi";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";
import { MdOutlineCardGiftcard } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import DataService from "../services/requestApi";
import Row from "react-bootstrap/Row";
import Swal from "sweetalert2";
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Modal } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
// import {BASEURL} from '../services/http-common'
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import moment, { now } from "moment/moment";
import CustomNavbar from "../UiComponents/CustomNavbar";
import { IoArrowBack } from "react-icons/io5";
import { BASEURL } from "../services/http-common";
import { RemoveReturn, RetrunWithReceipt } from "../Redux/Reducer";
import ReturnRiceaptModal from "./ReturnRiceaptModal";
import { useReactToPrint } from "react-to-print";
import CreditRiceaptModal from "./CreditRiceaptModal";
// import {ApplyDiscountOnTotal, HandelLinkCustomer, RemoveCart, RemoveLoyltiyuser, handleactualPrice} from '../Redux/Reducer'
function ReturnTransection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [TotalAmount, setTotalAomunt] = useState("");
  const customer = useSelector((state) => state.Reducer.LinkCustomer);
  const ReturnData = useSelector((state) => state.Reducer.RetrunData);
  const ReturnHeader = useSelector((state) => state.Reducer.Returnheader);
  const SelectedReturn = useSelector((state) => state.Reducer.SelectedReturn);
  const RetundAmount = useSelector((state) => state.Reducer.RetundAmount);
  // const totalamount = ReturnData.reduce((sum, item) => sum + item.new_price, 0)
  const cardVal = useSelector((state) => state.Reducer.values);
  // const customer = useSelector((state) => state.Reducer.LinkCustomer);
  // const { saasId, storeId ,registerId} = JSON.parse(localStorage.getItem("User_data"))
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const { print } =
    defaultLayoutPluginInstance.toolbarPluginInstance.printPluginInstance;
  const [amount, setAmount] = useState(RetundAmount);
  const [optionTick, setOptionTick] = useState([]);
  const [pdfFile, SetPdfFile] = useState("");
  const [showPdf, SetShowPdf] = useState(false);
  const [barcodeData, setBarcodeData] = useState("1234567898765");
  const [Tender, setTender] = useState();
  const [CrediNotesresp, setCreditnotesresp] = useState();
  const [TotalReturn, setTotalRetrun] = useState();
  const [CreditValid, setCreditvalid] = useState();
  const [CreditId, setCreditId]= useState("")
  const componentRef = useRef();
  const inputRef = useRef();
  useEffect(() => {
    if (SelectedReturn?.length == 0) {
      navigate("/return");
    }
  }, [SelectedReturn]);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      // Add any logic you need before printing
      console.log("printing Start");
    },
    onAfterPrint: () => {
      // Add any logic you need after printing
      console.log("printing End");
      inputRef.current.focus();
    },
  });
  const TenderArray = [
    {
      name: "IQD",
      value: "cash",
      icon: (
        <AiOutlineDollarCircle
          style={{
            height: "40px",
            width: "40px",
            color: "green",
            margin: "15px 0px 0px 56px",
          }}
        />
      ),
      isActive: ReturnHeader[0].loyalty_id == null ? true : false,
    },

    {
      name: "Credit Notes",
      value: "credit_sale",
      icon: (
        <PiNote
          style={{
            height: "40px",
            width: "40px",
            color: "green",
            margin: "15px 0px 0px 56px",
          }}
        />
      ),
      isActive: true,
    },
  ];

  const { saasId, storeId, registerId, userId ,userName ,tillId} = JSON.parse(
    localStorage.getItem("User_data")
  );
  const business_date = JSON.parse(
    localStorage.getItem("business_date")
  );

  const [Issubmeting, setIssubmeting] = useState(false)

  const handleReturnTransection = async () => {
    setIssubmeting(true)
    try {
      const date = moment(new Date()).format("YYYY-MM-DD");
      const formattedTime = moment(new Date()).format("HH:mm:ss");
      const data = {
        saas_id: saasId,
        transaction_date: business_date,
        transaction_time: formattedTime,
        original_invoice: SelectedReturn[0]?.invoice_no,
        expiration_date: date,
        credit_note_amount: RetundAmount,
        store_id: storeId,
      };
      const response = await DataService.GetReturnTransection(data);
      console.log("return data", response.data.data.creditNote_id);
      // setCreditId(response.data.data.creditNote_id)
      handleSaveTransection(response.data.data.creditNote_id);
      
    } catch (error) {
      setIssubmeting(false)
      console.log(error)
    }
  };

  //Tender Amount Handel
  const handleTenderAmount = () => {
    console.log("this is option", optionTick);
    if (optionTick?.length > 0) {
      const obj = {};
      optionTick.map((item) => {
        obj[item.name] = item.amount;
      });
      console.log("this is obj", obj);
      return obj;
      // setSendValues(obj)
    }
    return {};
  };

  const handleSaveTransection = async (CreditId) => {
    try {
      setIssubmeting(true)
      const data = {
        registerId: registerId,
        userId: userId,
        storeId: storeId,
        tillId: tillId,
        saasId: saasId,
        invoice_no: SelectedReturn[0]?.invoice_no,
        tenderId: "TENDER1",
        type: "Return",
        tender: handleTenderAmount(),
        cartItems: SelectedReturn,
        customerName: customer?.name,
        customerNumber: customer?.mobile_number,
        loyalty_id: cardVal[0]?.loyalty_id,
        creditNote_id:CreditId,
      };
      // SetShowPdf(true)
      const response = await DataService.saveTransction(data);
      if (response.data.status) {
        if (optionTick[0].name == "Credit Notes" ) {
          const ReturnResponse = await DataService.ReturnTransectionPdf(data);
          console.log(ReturnResponse);
          if(ReturnResponse.data.status){
            setIssubmeting(false)
          setCreditnotesresp(
            ReturnResponse.data.data.returnresponse.creditNote
          );
          setTotalRetrun(ReturnResponse.data.data.returnresponse.IQD);
          setCreditvalid(ReturnResponse.data.data.returnresponse.Validupto);
          SetPdfFile(ReturnResponse.data.data.pdf_file_name);
          SetShowPdf(true);
          }
          // dispatch(RemoveReturn([]))
        } else {
          setIssubmeting(false)
          sendTransection(`Cash Refund `,);
          setTotalAomunt(response.data.data.total_amount);
          setTender(response.data.data.tender);
          SetPdfFile(response.data.data.pdf_file_name);
          SetShowPdf(true);
          // dispatch(RemoveReturn([]))
        }
      }
      console.log("this response", response);
    } catch (error) {
      console.log("this catch error", error);
      setIssubmeting(false)
    }
  };
  //UseEffect for Selecte tender
  useEffect(() => {
    const handleKeyPress = (e) => {
      const blockedKeys = [116,114,115]; // Example: Block Enter and Tab keys

      // Check if the pressed key is in the blockedKeys array
      if (blockedKeys.includes(e.keyCode)) {
        // Prevent the default behavior of the blocked key
      e.preventDefault();
        console.log(`Blocked key: ${e.keyCode}`);
      }
      if(Issubmeting){
        return
      }
      if (e.keyCode === 80) {
        // 116 is the key code for F5
        console.log("this is P Button", e.keyCode);
        e.preventDefault();
        handlePrint();
      }
      if (e.keyCode === 112) {
        // 112 is the key code for F1
        // console.log("this is f1 Button",e.keyCode)
        e.preventDefault();
        console.log("this calling", amount);
        SelectTender(TenderArray[0], amount);
      }
      if (e.keyCode === 113) {
        // 113 is the key code for F2
        console.log("this is P Button", e.keyCode);
        e.preventDefault();
        SelectTender(TenderArray[1], amount);
      }
      if (e.key === "n" || e.key === "N") {
        e.preventDefault();
        if(optionTick.length>0 && !showPdf){
          
          if (optionTick[0]?.name == "Credit Notes") {
            handleReturnTransection();
          } else {
            handleSaveTransection();
            
          }
        }
      }
      if (e.key === "c" || e.key === "C") {
       if(showPdf){
         dispatch(RemoveReturn([]));
         SetShowPdf(false);
         navigate("/");

       }
        // dispatch(RemoveCart())
      }
      if (e.key === "B" || e.key === "b") {
        // B for pay
        e.preventDefault();
        console.log("this B");
        navigate(-1);
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [amount, optionTick,showPdf,Issubmeting]);

  const style = {
    overflowY: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: 635,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
  };

  // Tender Select For Key
  const SelectTender = async (item) => {
    if (optionTick?.length === 0) {
      const obj = { ...item, amount };
      setOptionTick([...optionTick, obj]);
    } else if (optionTick?.length > 0) {
      if (optionTick.filter((io) => io.value === item.value)?.length > 0) {
        setOptionTick(optionTick.filter((io) => io.value !== item.value));
      } else {
        Swal.fire({
          text: "You Want Change Payment Methode Click On Selected Method",
          timer: 1000,
        });
        // if (Number(optionTickSum) <= sumValue) {
        //   const obj = { ...item, amount };
        //   console.log("this other method",obj)
        //   setOptionTick([...optionTick, obj]);
        // }
      }
    }
  };

  useEffect(() => {
    if (showPdf === true) {
      console.log("useEffact Run ");
      // e.preventDefault();
      setTimeout(() => {
        handlePrint();
      }, 1000);
    }
  }, [showPdf]);



  const sendTransection = async (event) => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    try {
      
      const data = {
        userId:userId,
        userName:userName,
        businessDate: today,
        saasId: saasId,
        storeId: storeId,
        registerId: registerId,
        invoiceNo: null,
        event: event,
        cartItems:SelectedReturn
      };

      const response = await DataService.TransactionLog(data);
      console.log("transectionData", response);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>{Issubmeting ?
      <>
      <Box sx={{ display: 'flex' ,justifyContent:"center" , flexDirection:"column", alignItems:"center"}}>
        <CircularProgress style={{width:"120px", height:"120px"}}/>
      <span>Please Wait Your Transaction in Progress Do Not Go Back</span>
      </Box>
      </>
       :<>
    <div className="">
      <div className="d-flex justify-content-between">
        <Link to={"/return"}>
          {" "}
          <IoArrowBack className="mt-2 fs-3" />{" "}
        </Link>
        <CustomNavbar />
        <div>
          {/* //dont Remove This Div */}
          {""}
        </div>
      </div>
      <hr />
     
      <Container>
        <Row className="mt-3" style={{ height: "200px" }}>
          <h4
            className="mt-5"
            style={{
              color: "#2C2C2C",

              fontFamily: "Inter",
              fontSize: "32px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            Return Transaction
          </h4>

          {TenderArray.filter((item) => item.isActive === true).map(
            (item, index) => {
              return (
                <div
                  onClick={() => {
                    if (optionTick?.length === 0) {
                      const obj = { ...item, amount };
                      setOptionTick([...optionTick, obj]);
                    } else if (optionTick?.length > 0) {
                      if (
                        optionTick.filter((io) => io.value === item.value)
                          ?.length > 0
                      ) {
                        setOptionTick(
                          optionTick.filter((io) => io.value !== item.value)
                        );
                      } else {
                        Swal.fire({
                          text: "You Want Change Payment Methode Click On Selected Method",
                          timer: 1000,
                        });
                        // if (Number(optionTickSum) <= sumValue) {
                        //   const obj = { ...item, amount };
                        //   console.log("this other method",obj)
                        //   setOptionTick([...optionTick, obj]);
                        // }
                      }
                    }
                  }}
                  style={{
                    height: "fit-content",
                    width: "180px",
                    border: "solid grey 1px",
                    borderRadius: "5px",
                    marginLeft: "30px",
                    marginTop: "30px",
                    backgroundColor:
                      optionTick[0]?.name == item.name && "#8181c6",
                  }}
                >
                  {item.icon}
                  <h6 className="text-center">{item.name}</h6>
                  <span
                    style={{ border: "2px solid gray", marginLeft: "60px" }}
                    className="rounded px-1"
                  >
                    F{index + 1}
                  </span>
                </div>
              );
            }
          )}
        </Row>
        <Row>
          {/* <hr /> */}

          <div
            style={{
              height: "180px",
              width: "440px",
              margin: "30px 0px 0px 700px",
              borderLeft: "1px solid gray",
            }}
          >
            <h5>Refund Amount</h5>
            {/* <h6 style={{ marginTop: "10px" }}>AED</h6> */}
            <Form style={{ width: "400px", marginLeft: "0px" }}>
              <FormControl
                type="search"
                placeholder="1500"
                value={RetundAmount}
                className="mr-2"
                aria-label="Search"
              />
            </Form>
            <Button
              className="fw-bold"
              disabled={optionTick.length > 0 ? false : true}
              onClick={() => {
                if(Issubmeting){
                  return
                }else{
                  if (optionTick[0].name == "Credit Notes") {
                    handleReturnTransection();
                  } else {
                    handleSaveTransection();
                  }
                }
              }}
              style={{
                marginTop: "20px",
                width: "160px",
                fontSize: "13px",

                marginLeft: "34px",
                height: "50px",
                background: "#ffb42a",
                border: "none",
              }}
              variant="primary"
            >
              {" "}
              PAY NOW ( N )
            </Button>
          </div>
        </Row>
      </Container>

      {/* Recipte show modal */}
      <Modal
        open={showPdf}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginBottom: "20px",
              cursor: "pointer",
            }}
            onClick={() => {
              SetShowPdf((state) => !state);
              // dispatch(RemoveCart())
              navigate("/");
            }}
          > */}
            <Box sx={style}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatch(RemoveReturn([]))
                  SetShowPdf(false)
                  // dispatch(RemoveCart())
                  navigate('/')
                }}
              >
                <AiOutlineClose size={20} />
              </div>

          <div className="container" ref={componentRef}>
            {optionTick[0]?.name == "Credit Notes" ? (
              <CreditRiceaptModal
                CreditValid={CreditValid}
                TotalReturn={TotalReturn}
                CrediNotesresp={CrediNotesresp}
              />
            ) : (
              <ReturnRiceaptModal
                Tender={Tender}
                TotalAmount={TotalAmount}
                barcodeData={barcodeData}
              />
            )}
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="bg-dark-subtle mt-10 p-2"
              ref={inputRef}
              onClick={() => {
                dispatch(RemoveReturn([]));
                SetShowPdf(false);
                // dispatch(RemoveCart())

                navigate("/");
              }}
            >
              Close (C)
            </button>
            <button className="bg-dark-subtle mt-10 p-2" onClick={handlePrint}>
              PRINT ( P )
            </button>
            {/* {
                  <div style={{ height: 200, width: 200 }}>
                    {qr && (
                      <img
                        style={{ height: "100%", width: "100%" }}
                        src={`${BASE_Url}/transaction/pdf-qr/${qr}`}
                        alt="qr code"
                      />
                    )}
                  </div>
                } */}
          </div>
        </Box>
      </Modal>
    </div></>}</>
  );
}

export default ReturnTransection;
