import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Col from "react-bootstrap/Col";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASEURL } from "./services/http-common";
import Swal from "sweetalert2";
import CustomNavbar from "./UiComponents/CustomNavbar";
export const Enrollform = () => {
  const [isMaleActive, setIsMaleActive] = useState(false);
  const [isFemaleActive, setIsFemaleActive] = useState(false);
  const inputFocus = useRef();

  useEffect(() => {
    inputFocus.current.focus();
  }, []);
  const naviagte = useNavigate();
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.keyCode === 112) {
        e.preventDefault();
        naviagte(-1);
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleMaleClick = () => {
    setIsMaleActive(true);
    setIsFemaleActive(false);
    customerData.gender = "male";
  };

  const handleFemaleClick = () => {
    setIsMaleActive(false);
    setIsFemaleActive(true);
    customerData.gender = "female";
  };
  const [modalShow, setModalShow] = React.useState(false);
  const [customerData, setCustomerData] = useState({
    address_line1: "",
    address_line2: "",
    anniversary_date: "",
    base_currency: "INR",
    business_created_date: "",
    city: "",
    client_id: 8,
    country: "",
    customer_name: "",
    dob: "",
    email_id: "",
    gender: "",
    language: "",
    location: "",
    marital_status: "",
    mobile_number: "",
    nationality: "",
    pincode: "",
    preferred_address: "",
    source_channel: "Web",
    suffix: "",
  });

  const {
    client_id,
    base_currency,
    mobile_number,
    customer_name,
    email_id,
    source_channel,
    business_created_date,
    nationality,
    language,
    dob,
    gender,
    suffix,
    marital_status,
    anniversary_date,
    preferred_address,
    location,
    address_line1,
    address_line2,
    city,
    country,
    pincode,
  } = customerData;

  const subhandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASEURL.LOYALTY_URL}/loyalty/customer`,
        customerData
      );
      console.log("API response:", response.data);
      if (response.data.status) {
        setModalShow(true);
        naviagte("/");
        Swal.fire({
          title: response.data.message,
          icon: "success",
          timer: 2000,
        });
      } else {
        Swal.fire({
          title: response.data.message,
          icon: "error",
       
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const changeHandler = (e) => {
    setCustomerData({ ...customerData, [e.target.name]: e.target.value });
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Row>
          <Col className="p-2">
            <Col>
              <center>
                <AiOutlineCheckCircle
                  style={{ color: "green", width: "70px", height: "70px" }}
                />
              </center>
            </Col>
            <Modal.Body>
              <Col className="d-flex justify-content-center ">
                <h4> Awesome !!</h4>
              </Col>
              <Col>
                <center>
                  <p className="my-3"> You have enrolled as a Loyalty </p>
                </center>
              </Col>
              <Col>
                <center>
                  <p className="">member successfully </p>
                </center>
              </Col>
            </Modal.Body>
          </Col>
        </Row>
      </Modal>
    );
  }
  return (
    <div style={{ height: "0px", width: "1300px" }}>
      <CustomNavbar />
      <hr />
      <Link to={"/"} style={{ color: "black", margin: "20px 0px 0px 50px" }}>
        {" "}
        <AiOutlineArrowLeft style={{ width: "30px", height: "20px" }} />
      </Link>
      <h5 className="d-flex justify-content-center">Welcome to Loyalty</h5>
      <p className="d-flex justify-content-center">
        {" "}
        Fill in the form below to enroll as Loyalty member
      </p>
      <div
      className="d-flex justify-content-center"
        style={{
          height: "450px",
          // width: "700px",
          // margin: "20px 0px 0px 350px",
        }}
      >
        <Form onSubmit={(e) => subhandler(e)}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your full name"
              value={customer_name}
              name="customer_name"
              onChange={(e) => changeHandler(e)}
              ref={inputFocus}
            />
            <Row style={{ marginTop: "15px" }}>
              <Col>
                <Form.Label>Mobile number</Form.Label>
                <InputGroup className="mb-3">
                  <DropdownButton
                    variant="outline-secondary"
                    title="+964"
                    id="input-group-dropdown-1"
                  >
                    <Dropdown.Item>+964</Dropdown.Item>
                  </DropdownButton>
                  <Form.Control
                    aria-label="Text input with dropdown button"
                    placeholder="Enter mobile number"
                    value={mobile_number}
                    name="mobile_number"
                    onChange={(e) => changeHandler(e)}
                  />
                </InputGroup>
              </Col>
              <Col>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email "
                  value={email_id}
                  name="email_id"
                  onChange={(e) => changeHandler(e)}
                />
              </Col>
            </Row>
          </Form.Group>
          <Row style={{ marginTop: "-15px" }}>
            <Col>
              <Form.Label>Gender</Form.Label>

              <InputGroup>
                <Button
                  variant="none"
                  style={{
                    border: "solid lightgrey 1px",
                    width: "168px",
                    backgroundColor: isMaleActive ? "blue" : "white",
                  }}
                  onClick={handleMaleClick}
                >
                  Male
                </Button>
                <Button
                  variant="none"
                  style={{
                    border: "solid lightgrey 1px",
                    width: "168px",
                    backgroundColor: isFemaleActive ? "blue" : "white",
                  }}
                  onClick={handleFemaleClick}
                >
                  Female
                </Button>
              </InputGroup>
            </Col>
            <Col>
              <Form.Label>Date Of Birth</Form.Label>
              <Form.Control
                type="date"
                value={dob}
                name="dob"
                onChange={(e) => changeHandler(e)}
              ></Form.Control>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col>
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                aria-label="country"
                placeholder="Enter your country"
                value={country}
                name="country"
                onChange={(e) => changeHandler(e)}
              ></Form.Control>
            </Col>
            <Col>
              <Form.Label>City</Form.Label>
              <Form.Control
                type="city"
                aria-label="Enter your city"
                placeholder="Enter your city "
                value={city}
                name="city"
                onChange={(e) => changeHandler(e)}
              ></Form.Control>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            {" "}
            <Col>
              {/* <Button type="text" placeholder="ENROLL" onClick={(e) => {setModalShow(true)}} style={{ background: "blue", width: "700px" }} >ENROLL</Button>   </Col>

                    </Row>  */}

              <Button
                style={{ width: "700px", backgroundColor: "#2E45AF" }}
                type="submit"
              >
                Enroll
              </Button>
            </Col>
          </Row>
        </Form>
        <>
          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </>
      </div>
    </div>
  );
};
