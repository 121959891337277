import React, { useState, useEffect } from "react";
import axios from 'axios';
// import { BASEURL, host } from "../../../../URL";
import { BASEURL } from '../services/http-common';
import moment from "moment";
import Export from "react-data-table-component"
import Swal from "sweetalert2";
// import Table from 'react-bootstrap/Table';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Table,
    Badge,
    Button
  } from "reactstrap";
import { MdDelete, MdEdit, MdPlaylistAdd } from "react-icons/md";
import DataTable, { createTheme } from 'react-data-table-component';
import { AiOutlineRight } from "react-icons/ai"
import HsnModal from "./StoreinsightsModals/HsnModal";
// import HsnModal from "./HsnModal";

const HSNMaster = ({Hsnmaster}) => {

    const [modalShow, setModalShow] = useState(false);
  const handleShow = () => setModalShow(true);




// data table
const columns = [
  {
      name: 'Code',
      selector: row => row.code,
  },
  {
      name: 'Description',
      selector: row => row.description,
  },
  {
      name: 'Action',
      cell: row => {
          return (<>
              <AiOutlineRight />
          </>)
      }
  }
];

const Data = [
  {
      id: 1,
      code: '851672',
      description: 'toasters'
  },
  {
      id: 2,
      code: '851672',
      description: 'toasters'
  }
]

const downloadCSV = (Data) => {
  // Check if data is not null and has at least one row
  if (Data && Data.length > 0) {
    const csvData = Data.map((row) => Object.values(row).join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "GSTR1.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } else {
    // Provide feedback or handle the case where there is no data to download
    Swal.fire({
      title:"No data available for download.",
      icon:"info",
      timer:1500
    })
    // You can also show a message to the user if needed.
  }
};
const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(Data)} />, []);
const style={
  boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
  borderRadius:"4px",
  background:"white",
  color:"black",
  border:"0px"
}
        return (
          <>
           <div  className=" d-block profile mt-3 py-2 px-2
      " style={{ height:"fit-content" }}>
        <div className="container">
        <Container fluid>
          <Row className="justify-content-center">
            <Col >
                  <div className="table-responsive mt-3 p-2"  style={style}>
            <Button className="float-end" onClick={()=>downloadCSV(Hsnmaster)}>Export</Button>
                <h6>GSTR1 Report</h6>
                  <Table>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">total_value</th>
                        <th scope="col">total_discount</th>
                        <th scope="col">tax_value</th>
                        <th scope="col">additional_cess</th>
                        <th scope="col">flood_cess</th>
                        <th scope="col">Action</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {Hsnmaster &&
                        Hsnmaster.map((item, index) => (
                          <tr key={item._id}>
                            <th>
                              {index + 1}
                            </th>

                            <td>
                            {item.total_value}
                            
                            </td>
                            <td>
                            {item.total_discount}
                            
                            </td>
                            <td>
                          {item.tax_value}
                              </td>
                            <td>
                            {item.additional_cess}
                            
                            </td>
                            
                            <td>
                            {item.flood_cess}
                            
                            </td>
                            <td>
                            <MdPlaylistAdd
                  size={22}
                  color="green"
                  className="mouse-pointer"
                  onClick={() => {
                    // setAddUpdateItemModalIsOpen(!addUpdateItemModalIsOpen);
                    handleShow((state) => !state);
                  }}
                />
                            
                            </td>
                          
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                      
                  </div>
             
             
            </Col>
          </Row>
        </Container></div>
     {/* <div className="container mt-3">
                            <DataTable
                                columns={columns}
                                data={Data}
                                title="Select HSN/SAC Code"
                                theme="solarized"
                                className='table-bordered'
                            />
                  </div> */}
      </div>
 <HsnModal 
          show={modalShow}
          onHide={() => setModalShow(false)}/>
      
    </>
  );
};

export default HSNMaster;
