import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { MdDelete,MdEdit, MdPlaylistAdd } from "react-icons/md";
import DataTable from "react-data-table-component";
import ClassupdateModal from './MangeAllModal/ClassupdateModal';
import { selectClasses } from '@mui/material';
import AddClassModal from './MangeAllModal/AddClassModal';
import DataService from '../../services/requestApi'
import Swal from 'sweetalert2';
function ClassMaster({allclass, GetAllClass}) {
    const [classModal, setclassModal] = useState(false)
    const [AddclassModal, setAddclassModal] = useState(false)
    const [slectedClass, setSelectedClass] = useState('')

    const DeleteClass=async(id)=>{
      try {
        const response = await DataService.DeleteClass(id)
         if(response.data.status){
          GetAllClass()
          Swal.fire({
            title:"Class Deleted Successfully",
            icon:"success",
            timer:2000
          })
         }
      } catch (error) {
        console.log(error)
      }
    }
    const style={
        boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        borderRadius:"4px",
        background:"white",
        color:"black",
        border:"0px"
      }
      const columns = [
        {
          name: 'Department Code',
          center: true,
          selector: row => row.dept_id,
      },
          {
            name: 'Class Code',
            center: true,
            selector: row => row.class_code,
        },
        {
          name: "Class Name",
          // center: true,
          selector: (row) => row.class_name,
          cell: (row) => {
            return (
              <>
                <div>{row.class_name}</div>
              </>
            );
          },
        },

     
      


        // {
        //   name: "Concept Id",
        //   center: true,
        //   selector: (row) => row.conc_id,
        // },
      
     
        // {
        //   name: "Price",
        //   center: true,
        //   selector: (row) => row.price,
        // },
        // {
        //   name: "Class Code",
        //   center: true,
        //   selector: (row) => row.hsn_code,
        // },
        // {
        //   name: "Tax",
        //   center: true,
        //   selector: (row) => row.tax,
        // },
        // {
        //     name: 'Tax Code',
        //     center: true,
        //     selector: row => row.tax_code,
        // },
        // {
        //     name: 'Tax Percent',
        //     center: true,
        //     selector: row => row.tax_percent,
        // },
        // {
        //     name: 'Tax Rate',
        //     center: true,
        //     selector: row => row.tax_rate,
        // },
        {
          name: "Action",
          center: true,
          selector: (row) => {
            // const [addUpdateItemModalIsOpen, setAddUpdateItemModalIsOpen] =
            //   useState(false);
            // const handleDelete = async () => {
            //   const response = await fetch(
            //     `${host}item/inactive-item/${row.item_id}/${saasId}`,
            //     {
            //       method: "PUT",
            //     }
            //   );
            //   const jsonData = await response.json();
            //   if (jsonData) {
            //     if (jsonData.status === true) {
            //       toast.success(jsonData.message);
            //       setFlag(!flag);
            //       return;
            //     }
            //     /* toast.error(jsonData.message);
            //       setFlag(!flag); */
            //   } /*  else {
            //       toast.error("Something went wrong server side");
            //     } */
            // }; /* catch (err) {
            //     toast.error(err.message);
            //   } */
    
    
            return (
              <>
                <div className="d-flex">
                <div style={{cursor:'pointer'}}>
                    <MdPlaylistAdd
                      size={22}
                      color="green"
                      className="mouse-pointer"
                      onClick={() => setAddclassModal(true)}
                    />
                  </div>
    
                  <div style={{cursor:'pointer'}}>
                    <MdDelete
                      size={22}
                      color="red"
                      className="mouse-pointer"
                      onClick={() => DeleteClass(row.class_code)}
                    />
                  </div>
    
                  <div style={{cursor:'pointer'}}>
                    <MdEdit
                      size={22}
                      color="var(--primary1)"
                      className="mouse-pointer"
                      onClick={() => {
                        // setAddUpdateItemModalIsOpen(!addUpdateItemModalIsOpen);
                        setclassModal((state) => !state);
                        setSelectedClass(row)
                        // dispatch(handelGetCategoryRequest());
                      }}
                    />
                  </div>
                </div>
    
                {/* <AddItem
                  addUpdateItemModalIsOpen={addUpdateItemModalIsOpen}
                  setAddUpdateItemModalIsOpen={setAddUpdateItemModalIsOpen}
                  row={row}
                  setFlag={setFlag}
                  flag={flag}
                /> */}
              </>
            );
          },
        },
      ];
  return (
    <div>  <Container>
    <DataTable
        columns={columns}
        responsive={true}
        fixedHeader={true}
        // fixedHeaderScrollHeight="300px"

        data={allclass}
        // progressPending={loading}
        pagination
        paginationServer
        // paginationTotalRows={item_master_list ? item_master_list.totalCount : 1}
        // onChangeRowsPerPage={10}
        // onChangePage={handlePageChange}
      />

      <ClassupdateModal open={classModal} row={slectedClass} GetAllClass={GetAllClass} setclassModal={setclassModal}/>
      <AddClassModal open={AddclassModal}  GetAllClass={GetAllClass} setAddclassModal={setAddclassModal}/>
  </Container></div>
  )
}

export default ClassMaster