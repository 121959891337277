import React from "react";
import { Container, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Table from "react-bootstrap/Table";

export const ModelOnEndOfDay = ({
  show,
  setOpenModal,
  registerData,
  tillData,
  storeData
}) => {
  return (
    <Modal
      show={show}
      onHide={() => setOpenModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Register ID & Till ID</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Cashier ID</th>
                <th>Register ID</th>
                <th>Register Status</th>
              </tr>
            </thead>
            <tbody>
              {registerData?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.cashier_id}</td>
                  <td>{item.register_id}</td>
                  <td>{item.register_status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Till ID</th>
                <th>Register ID</th>
                <th>Till Status</th>
              </tr>
            </thead>
            <tbody>
              {tillData?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.till_id}</td>
                  <td>{item.register_id}</td>
                  <td>{item.till_status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Store ID</th>
               
                <th>Store Status</th>
              </tr>
            </thead>
            <tbody>
              {storeData?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.store_id}</td>
                  <td>{item.store_open_close_status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
