import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import barcode from '../Images/image 34.png';
import { Form } from 'react-bootstrap';

const ReturnWithoutRecipte = () => {
    return (
        <Container>
            <h2 className='text-center'>Return Without Receipt</h2>
            <Col style={{ height: "fit-content", width: "100%" }} className='my-5 py-5'>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Enter Item Barcode</Form.Label>
                            <Form.Control type="test" placeholder="Enter Barcode Number" />
                        </Form.Group>
                    </Form>
                </Col>
                <Col style={{ height: "fit-content", width: "100%"}} className='my-5 py-5'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <h6 style={{ color: "grey" }}>Scan Your Invoice</h6>
                    </div>
                    <div className='d-flex align-items-center justify-content-center my-4'>
                        <img height="120px" width="230px" src={barcode} />
                    </div>
                    {/* <hr /> */}
                </Col>
            
           </Container>
    )
}

export default ReturnWithoutRecipte