import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {  Col } from "react-bootstrap";
import { BsArrowLeft } from 'react-icons/bs';
import { TextField } from '@mui/material';
import DataService from '../../../services/requestApi'
import Swal from 'sweetalert2';
import { useEffect } from 'react';
const SubClassModal = ({open,setOpenUpdate,row,GetSubReport,setSelectedRow}) => {
    const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"))
    const [ConName, setConName] = useState({
      saas_id: saasId,
    store_id: storeId,
        class_code:"",
        dept_code:"",
        con_code:"",
        sub_class_name:"",
        sub_class_id:""
    })
   
  useEffect(() => {
    if(row){
      setConName({
        class_code:row.class_code,
        dept_code:row.dept_code,
        con_code:row.con_code,
        sub_class_name:row.sub_class_name,
        sub_class_id:row.sub_class_id
      })

    }
  }, [row])
  
//api call for update name 
  const UpadteConcept=async()=>{
  try {
  
    if(ConName.sub_class_name && ConName.class_code && ConName.con_code && ConName.dept_code && ConName.sub_class_id){
      const response = await DataService.UpdateSubClass(row.sub_class_code, ConName)
      console.log("new respons", response.data)
      if (response.data.status) {
         GetSubReport()
         setOpenUpdate(false)
         setSelectedRow()
         Swal.fire({
          title:"SubClass Update Successfully",
          icon:"success",
          timer:2000
      })
      }
    }else{
      Swal.fire({
        title:"Please fill All field",
        icon:"error",
        timer:1000
      })
    }
  } catch (error) {
    console.log("this is catch", error)
  }
  }

  const HandleChange =(e)=>{
   setConName({...ConName,[e.target.name]: e.target.value })
  }
  return (
    <div><Modal
    isOpen={open}
    toggle={() => setOpenUpdate(!open)}
  >
    <ModalHeader>
      <BsArrowLeft
        onClick={() =>
            setOpenUpdate(!open)
        }
        className="mouse-pointer"
      />{" "}
      Update Sub Class *
    </ModalHeader>
    <ModalBody>
      <div className="row d-flex justify-content-center">
        <div className="">
          <form
            className="form-box"
            // onSubmit={handleAddItem}
            // encType="multipart/form-data"
            encType="Content-Type"
          >
            <div
              className="d-flex flex-col"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Col md={12}>
              
              </Col>
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={ConName.class_code}
                name="class_code"
                onChange={HandleChange}
                label="Class code"
                multiline
                required
                rows={1}
              />
                 <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={ConName.dept_code}
                name="dept_code"
                onChange={HandleChange}
                label="Dept Code"
                multiline
                required
                rows={1}
              />
                <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={ConName.con_code}
                name="con_code"
                onChange={HandleChange}
                label="Con code"
                multiline
                required
                rows={1}
              />
                <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={ConName.sub_class_name}
                name="sub_class_name"
                onChange={HandleChange}
                label="Sub ClassName"
                multiline
                required
                rows={1}
              />
                <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={ConName.sub_class_idsub_class_id}
                name="sub_class_id"
                onChange={HandleChange}
                label="Sub Class Id"
                multiline
                required
                rows={1}
              />
              {/* <TextField
                size="small"
                type="text"
                className="form-control my-2"
                // value={itemPrice}
                // required
                // onChange={(e) => setItemPrice(e.target.value)}
                label="Item Price"
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                // value={hsnCode && "0"}
                // required
                // onChange={(e) => setHsnCode(e.target.value)}
                label="HSN Code"
              />

              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                // value={taxPercentage}
                // required
                // onChange={(e) => setTaxPercentage(e.target.value)}
                label="Tax Percentage"
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                // value={purchasePrice}
                // required
                // onChange={(e) => setPurchasePrice(e.target.value)}
                label="Purchase Price"
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                // value={openingQuantity}
                // required
                // onChange={(e) => setOpeningQuantity(e.target.value)}
                label="Opening Quantity"
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                // value={receivedQuantity}
                // required
                // onChange={(e) => setReceivedQuantity(e.target.value)}
                label="Received Quantity"
              />
             
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                // value={pcs_price}
                // onChange={(e) => setPcs_Price(e.target.value)}
                label="Enter Price Per Pice"
              /> */}
            </div>

            <div className="">
              <button
                type="button"
                style={{
                  backgroundColor: "rgb(46, 69, 175)",
                  outline: "none",
                  border: "none",
                  fontSize: "20px",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  color: "#fff",
                }}
                onClick={UpadteConcept}
              >
                Update
              </button>

          
              <span
                // to="/retailer-dashboard"
                onClick={() => {
                    setOpenUpdate(!open);
                }}
                className="btn btn-primary mb-2"
                style={{
                  backgroundColor: "grey",
                  outline: "none",
                  border: "none",
                  marginLeft: "20px",
                  fontSize: "20px",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  color: "#fff",
                }}
              >
                Close
              </span>
            </div>
          </form>
          {/* )} */}
        </div>
      </div>
    </ModalBody>
    <ModalFooter>{/* <h1>FOOTER</h1> */}</ModalFooter>
  </Modal></div>
  )
}

export default SubClassModal