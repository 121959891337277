import React, { useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { BiSearchAlt2 } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function AddRemarkModal(props) {
  const { setRemark1,setRemark2 , setRemark3,setRemark} = props

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <Row>
          <Col>
            <div className="d-flex">
              <AiOutlineArrowLeft
                className="fs-4"
                onClick={() => props.onHide()}
              />
              <h4 className="mx-3">Add Remarks</h4>
            </div>

            <Row>
              <Col>
                <Form className="d-flex">
                  <Row style={{ margin: "20px 0px 0px -10px" }}>
                    <Form.Control autoFocus type="text" onChange={(e)=>{setRemark1(e.target.value)}}></Form.Control>
                    <Form.Control type="text" className="mt-2" onChange={(e)=>{setRemark2(e.target.value)}}></Form.Control>
                    <Form.Control type="text" className="mt-2" onChange={(e)=>{setRemark3(e.target.value)}}></Form.Control>
                    <Form.Control type="text" className="mt-2" onChange={(e)=>{setRemark(e.target.value)}}></Form.Control>

                    <Button className="mt-2" onClick={() => props.onHide()} type="button" variant="primary">
                      Add Remark
                    </Button>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default AddRemarkModal;
