import axios from "axios";

export const isDev = false;

export const BASEURL = {
  ENDPOINT_URL: isDev
    ? "https:vastretailfoapi.photonsoftwares.com/test/api/v1"
    : "https://vastretailprdapi.photonsoftwares.com/prod/api/v1",
  LOYALTY_URL:  "https://loyaltyprdapi.photonsoftwares.com/prod/api/v1",
  //    ENDPOINT_URL: "http://192.168.1.14:9000/",
  //    ENDPOINT_URL: "http://localhost:5000/",
};

export const authToken = localStorage.getItem("token");

export default axios.create({
  baseURL: `${BASEURL.ENDPOINT_URL}`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    // "authtoken": `${authToken}`
  },
  maxContentLength: Infinity, // Allow large content
  maxBodyLength: Infinity, 
});
