import React, { useState, useEffect } from "react";
import { Container, Navbar, Button, Form } from "react-bootstrap";

import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { PiCrownBold } from "react-icons/pi";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import InputGroup from "react-bootstrap/InputGroup";
import { BiSearchAlt2 } from "react-icons/bi";
import { BsCircle } from "react-icons/bs";

import { CiBarcode } from "react-icons/ci";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PerMotionrule, provideData } from "./Redux/Reducer";
import { useNavigate } from "react-router";
import crown from "./Images/Group 19.png";
import barcode from "./Images/image 34.png";
import { Fourth } from "./Scan";
import { BASEURL } from "./services/http-common";
import DataService from "./services/requestApi";
import Swal from "sweetalert2";
import CustomNavbar from "./UiComponents/CustomNavbar";
import { ClearDisplay, sendCommand } from "./Constant/Util";
export const WelcomeSearch = () => {
  const dispatch = useDispatch();
  const { DisplaPort } = useSelector((state) => state.Reducer);
  const [customerData, setCustomerData] = useState(null);
  const [bal, setBal] = useState();
  const { saasId, storeId } = JSON.parse(
    localStorage.getItem("User_data")
  );
  const [inputMobileNum, setInputMobileNum] = useState({
    mobile_number: "",
    base_currency: "",
    client_id: "",
  });
  const { mobile_number, base_currency, client_id } = inputMobileNum;
  const navigate = useNavigate();

  const changeHandler = (e) => {
    setInputMobileNum({
      [e.target.name]: e.target.value,
      base_currency: "INR",
      client_id: "8",
    });
  };
  // const dispatchh = () => {
  //     dispatch(provideData([customerData]))

  //  }

  const subhandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASEURL.LOYALTY_URL}/loyalty/customer-details`,
        inputMobileNum
      );
      if(response.status == 200){
        const getPeromotionRule = await DataService.GetPermotionRule(saasId,storeId)
        console.log(getPeromotionRule.data.data)
        dispatch(PerMotionrule(getPeromotionRule.data.data))
        dispatch(provideData(response.data));
        navigate("/scan");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        title: error?.response?.data?.error_message,
        icon: "error",
      });
    }
  };
  // <=---------------------------------------------------------Display message On Port --------------------=>
  const [selectedPort, setSelectedPort] = useState(null);

  // useEffect(() => {
  //   const connectToSerialPort = async () => {
  //     try {
  //       const availablePorts = await navigator.serial.getPorts();

  //       if (availablePorts.length === 0) {
  //         console.error('No serial ports available.');
  //         Swal.fire({
  //           title: 'No serial ports available.',
  //           icon: 'error',
  //           timer: '2000',
  //         });
  //         return;
  //       }

  //       console.log('All Ports:', availablePorts);

  //       setPorts(availablePorts);
  //     } catch (error) {
  //       console.error('Error fetching serial ports:', error.message);
  //     }
  //   };

  //   connectToSerialPort();
  // }, []);

  useEffect(() => {
    if (DisplaPort.length > 0 && !selectedPort) {
      // For simplicity, selecting the first available port
      const firstPort = DisplaPort[0];
      console.log("Using port:", firstPort);
      setSelectedPort(firstPort);

      // Open the selected port
      openSelectedPort(firstPort);
    }
  }, [DisplaPort]);

  const openSelectedPort = async (port) => {
    try {
      await port.open({ baudRate: 9600 });
      console.log("Serial port opened successfully.");
    } catch (error) {
      console.error("Error opening serial port:", error.message);
    }
  };

  // const sendCommand = async (command) => {
  //   console.error(command);

  //   if (selectedPort) {
  //     try {
  //       const writer = selectedPort.writable.getWriter();
  //       const encoder = new TextEncoder();

  //       await writer.write(encoder.encode(command));
  //       console.log('Command sent successfully:', command);

  //       // Release the writer after writing
  //       writer.releaseLock();

  //     } catch (error) {
  //       console.error('Error writing to serial port:', error);
  //     }
  //   } else {
  //     console.error('Serial port is not open.');
  //   }
  // };

  useEffect(() => {
    // sendCommand("",selectedPort); // Replace 'clear' with the command to clear the screen
    ClearDisplay(selectedPort);
    setTimeout(() => {
      sendCommand("Provide Your Mobile No", selectedPort);
    }, 10000);
  }, [selectedPort]);

  // key functionlity
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "B" || e.key === "b") {
        // B for pay
        e.preventDefault();
        console.log("this B");
        navigate(-1);
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div>
      <CustomNavbar />
      <hr />

      <Link to={"/"} style={{ color: "black" }}>
        <AiOutlineArrowLeft style={{ width: "30px", height: "20px" }} />
      </Link>
      <Container>
        <div className="d-flex align-items-center justify-content-center my-4">
          <img height="60px" width="60px" src={crown} />
        </div>
        <h5 style={{ textAlign: "center" }}>WELCOME TO LOYALTY</h5>
        <Row className="p-5">
          <Col md={5}>
            <div className="float-end">
              <h6 style={{ color: "grey" }} className="mx-5 py-3">
                Scan Loyalty Card
              </h6>
              <Link to={"/scan"}>
                <img style={{}} height="120px" width="230px" src={barcode} />
              </Link>
            </div>
          </Col>
          <Col md={2}>
            <div className="mx-5 ">
              <center>
                <div
                  className="vr"
                  style={{ height: " 150px", width: "1px" }}
                ></div>
                <p>OR</p>
                <div
                  className="vr"
                  style={{ height: " 150px", width: "0.8px" }}
                ></div>
              </center>
            </div>
          </Col>
          <Col md={5}>
            <h6 style={{ color: "grey" }} className="mx-5 py-3">
              Search using Mobile Number
            </h6>
            <Form onSubmit={(e) => subhandler(e)} className="py-3">
              <InputGroup
                className="mb-3"
                style={{ width: "400px", height: "20px" }}
              >
                <DropdownButton
                  variant="outline-secondary"
                  title="+964"
                  id="input-group-dropdown-1"
                >
                  <Dropdown.Item>+964</Dropdown.Item>
                  <Dropdown.Divider />
                </DropdownButton>
                <Form.Control
                  aria-label="Text input with dropdown button"
                  placeholder="Search Mobile No."
                  value={mobile_number}
                  name="mobile_number"
                  onChange={(e) => changeHandler(e)}
                />
                <Button type="Submit" variant="primary">
                  <BiSearchAlt2 style={{ height: "2rem", width: "2rem" }} />
                </Button>
                {/* {()=>dispatch(provideData([customerData]))} */}
              </InputGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
