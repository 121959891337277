import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Col } from "react-bootstrap";
import { BsArrowLeft } from 'react-icons/bs';
import { TextField } from '@mui/material';
import DataService from '../../../services/requestApi';
import Swal from 'sweetalert2';

const AddDeptModal = ({ open, setAddDept, GetDeptMaster }) => {
  const [data, setData] = useState({
    dept_name:"",
    conc_id:"",
    dept_code:""
  })

  // API call for update department
  const AddDepartment = async () => {
    try {
      if (data.conc_id && data.dept_code && data.dept_name) {
        
        const response = await DataService.AddDeptMaster(data);
        if (response.data.status) {
          GetDeptMaster();
          setAddDept(false);
          setData('')
          Swal.fire({
              title:"Department Add Successfully",
              icon:"success",
              timer:2000
          })
      }
      }else{
        Swal.fire({
          title:"Please Fill All Field",
          icon:"info",
          timer:1000
        })
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  


  const handleonchange=(e)=>{
    setData({...data,[e.target.name]:e.target.value})
   }
  return (
    <div>
      <Modal isOpen={open} toggle={() => setAddDept(!open)}>
        <ModalHeader>
          <BsArrowLeft
            onClick={() => setAddDept(!open)}
            className="mouse-pointer"
          />
          Add Department *
        </ModalHeader>
        <ModalBody>
          <div className="row d-flex justify-content-center">
            <div className="">
              <form className="form-box" encType="Content-Type">
                <div className="d-flex flex-col" style={{ display: "flex", flexDirection: "column" }}>
                  <Col md={12}></Col>
                  <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={data?.dept_name}
                name='dept_name'
                onChange={handleonchange }
                label="Department Name"
                required
                rows={1}
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={data?.conc_id}
                name='conc_id'
                onChange={handleonchange }
                label="Concept Id"
                required
                rows={1}
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={data?.dept_code}
                name='dept_code'
                onChange={handleonchange }
                label="Department Code"
                required
                rows={1}
              />
                </div>
                <div className="mt-2">
                  <button
                    className=''
                    type="button"
                    style={{
                      backgroundColor: "rgb(46, 69, 175)",
                      outline: "none",
                      border: "none",
                      fontSize: "20px",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                    onClick={AddDepartment}
                  >
                    Add Dept
                  </button>
                  <span
                    onClick={() => setAddDept(!open)}
                    className="btn btn-primary mb-2"
                    style={{
                      backgroundColor: "grey",
                      outline: "none",
                      border: "none",
                      marginLeft: "20px",
                      fontSize: "20px",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                  >
                    Close
                  </span>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>{/* <h1>FOOTER</h1> */}</ModalFooter>
      </Modal>
    </div>
  );
};

export default AddDeptModal;
