import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { BiSearchAlt2 } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ModalPriceCheck(props) {
  return (
    <Modal
    {...props}

    aria-labelledby="contained-modal-title-vcenter"
    centered
>

    <Modal.Body>
        {/* <Link to={'/'} style={{ color: "black" }}> < AiOutlineArrowLeft style={{ width: "30px", height: "20px" }} /></Link> <h4 style={{ margin: "-25px 0px 0px 30px" }}> Price Check</h4>
        <p style={{ margin: "30px 0px 0px 10px " }}>Item Barcode</p>
        <Form className="d-flex" style={{ margin: "10px 0px 0px 10px", width: "370px" }} >
            <FormControl type="search" placeholder="Enter item barcode" className="mr-2" aria-label="Search" />
            <Button type="Submit" variant="primary"><BiSearchAlt2 style={{ height: "2rem", width: "2rem" }} /></Button>
        </Form> */}

        <Row>
            <Col>
                <div className='d-flex'>
                    <Link to={'/'} className='text-dark'> < AiOutlineArrowLeft className='fs-4' /></Link><h4 className='mx-3'>Item Lookup</h4>
                </div>
                <Row className='my-4'>
                    <Col>
                        <p>Item Barcode</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form className='d-flex'>
                            <FormControl type="search" placeholder="Enter item barcode" className="mr-2" aria-label="Search" />
                            <Button type="Submit" variant="primary"><BiSearchAlt2 style={{ height: "2rem", width: "2rem" }} /></Button>
                        </Form>
                    </Col>
                </Row>

            </Col>
        </Row>



    </Modal.Body>
</Modal>
  );
}

export default ModalPriceCheck;
