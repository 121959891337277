import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { AddItemDiscount } from "../Redux/Reducer";
import Swal from "sweetalert2";
import DataService from ".././services/requestApi"

function AmountOfDiscountModal(props) {
  const { show, onHide, updatepric, setUpdatePrice, getFocus,DisID ,setDisID, sendTransection} = props;
  const [Userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [pricemodal, setPricemoadl] = useState(false);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const ManagerFocus = useRef(null);
  const [isitemActive, setIsItemActive] = useState(true);
  const displayData = useSelector((state) => state.Reducer.displayCart);
  const [istransactionActive, setIsTransactionActive] = useState(false);
  const [isSelected, setIselected]= useState("percentage")
  const { tnc} =
    JSON.parse(localStorage.getItem("Store_data"));
  const handelprice = (e) => {
    e.preventDefault();
    if (updatepric.DiscountType == "percentage") {
      if (e.target.value <= 100) {
        setUpdatePrice({
          ...updatepric,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      const index = displayData.findIndex(
        (val) => val.item_id === DisID
      );
      console.log(displayData)
      if (e.target.value <= displayData[index]?.price) {
        setUpdatePrice({
          ...updatepric,
          [e.target.name]: e.target.value,
        });
      }
    }
  };
  //  console.log("this props", updatepric)
  const handleItemClick = () => {
    setIsItemActive(true);
    setIsTransactionActive(false);
  };

  const handleTransactionClick = () => {
    setIsItemActive(false);
    setIsTransactionActive(true);
  };

  const handleDiscountTypeChange = (discountType) => {
    setIselected(discountType)
    updatepric["DiscountType"] = discountType;
    console.log("this called",updatepric)
    setUpdatePrice(updatepric);
    
  };

  const handleKeyPress = (e) => {
    if (e.key && e.key.toUpperCase) {
      if (e.key.toUpperCase() === "P"&& show) {
        // handleDiscountTypeChange("percentage");
        updatepric["DiscountType"] = 'percentage';
    console.log("this called",updatepric)
    setUpdatePrice(updatepric);
    setIselected("percentage")
      } else if (e.key.toUpperCase() === "A" && show) {
        // handleDiscountTypeChange("Amount");
        updatepric["DiscountType"] = 'Amount';
    console.log("this called",updatepric)
    setUpdatePrice(updatepric);
    setIselected("Amount")
        
      }
    }
  };

  // ...

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [show]);

  useEffect(() => {
    if (show) {
      console.log("this called", show);
      setTimeout(() => {
        inputRef.current.focus();
      }, 300);
    }
  }, [show]);
  
  const data ={
  price: updatepric.price,
  itemId: DisID,
  DiscountType: updatepric.DiscountType,
  }
  const HadleSubmit = (e) => {
    e.preventDefault();

     if(tnc == "False"){
       if(updatepric.DiscountType=="percentage"&& updatepric.price>20){
         onHide()
         setPricemoadl(true) 
         
         return
       }

     }
    const FindItem = displayData?.filter((el) => el.item_id === data.itemId);
    console.log(FindItem[0].price)
    const getAmount = updatepric.price * 100/ FindItem[0].price
    console.log("percentage",getAmount)
    if(tnc == "False"){
      if(updatepric.DiscountType=="Amount"&& getAmount>20){
        onHide()
        setPricemoadl(true) 
        
        return
      }
    }
    dispatch(AddItemDiscount(data));
    const initialUpdatePricState = {
    price: "",
    DiscountType: "percentage",
    };
    setUpdatePrice(initialUpdatePricState);
    setIselected("percentage")
    setDisID()
    onHide();
    setTimeout(() => {
      getFocus();
    }, 500);
  };
  
  useEffect(() => {
    if(pricemodal){
      setTimeout(() => {
        ManagerFocus.current.focus()
      }, 500);
    }
  }, [pricemodal])
  
 
  const CheckManger = async () => {
    try {
      console.log("itame id", updatepric);
      const response = await DataService.CheckManger(Userid, password);
      console.log("check manger", response);
      if (response.data.status) {
        if(response.data.data.user_type == "MANAGER"){
          const FindItem = displayData?.filter((el) => el.item_id === data.itemId);
          sendTransection(`Discount`,FindItem[0]);
          dispatch(AddItemDiscount(data));
          setPricemoadl(false)  
          const initialUpdatePricState = {
            price: "",
            DiscountType: "percentage",
            };
            setUpdatePrice(initialUpdatePricState);
            setIselected("percentage")
            setDisID()
            onHide();
            setTimeout(() => {
              getFocus();
            }, 500);
        }else{
          Swal.fire({
            title:"You are Not Allow",
            icon:"error",
            timer:1500
          })
        }
      }
    } catch (error) {
      console.log("Catch Error", error);
    }
  };

  return (
    <>
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div
          className="p-2 m-1 font-bold"
          style={{ border: "solid grey 1px " }}
        >
          {/* <button style={{ color: "black", margin: "20px 0px 0px 00px" }}>  */}
          <AiOutlineArrowLeft style={{ width: "30px", height: "20px" }} />
          {/* </button> */}
          <h5 style={{ margin: "-24px 0px 0px 40px" }}>Select an Option</h5>
          <div
            style={{
              margin: "20px 0px 0px 00px",
              height: "500px",
              width: "447px",
            }}
          >
            <Form onSubmit={(e) => HadleSubmit(e)}>
              <Button
                variant="none"
                style={{
                  border: "solid lightgrey 1px",
                  width: "220px",
                  backgroundColor: isitemActive ? "#2E45AF" : "white",
                  color: isitemActive ? "white" : "black",
                }}
                onClick={handleItemClick}
              >
                Item Wise
              </Button>
              {/* <Button variant="none" style={{ border: "solid lightgrey 1px", width: "220px" ,backgroundColor: istransactionActive ? '#2E45AF' : 'white', color: istransactionActive ? 'white' : 'black' }}
        onClick={handleTransactionClick}>Transaction Wise</Button> */}
              <Row style={{ margin: "40px 0px 0px -10px" }}>
                <Col
                  sm={3}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDiscountTypeChange("percentage")}
                >
                  <input
                    type="radio"
                    checked={isSelected == "percentage"}
                    style={{ height: "25px", width: "25px" }}
                    onClick={() => {
                      updatepric["price"] = "";
                      setUpdatePrice(updatepric);
                    }}
                  />
                  <div
                    className="fw-bold"
                    style={{
                      height: "50px",
                      width: "50px",
                      margin: "-30px 0px 0px 40px",
                    }}
                  >
                    %(P)
                  </div>
                </Col>
                <Col
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDiscountTypeChange("Amount")}
                >
                  <input
                    type="radio"
                    checked={isSelected == "Amount"}
                    style={{ height: "25px", width: "25px" }}
                    onClick={() => {
                      updatepric["price"] = "";
                      setUpdatePrice(updatepric);
                    }}
                  />
                  <div
                    className="fw-bold"
                    style={{
                      height: "50px",
                      width: "50px",
                      margin: "-30px 0px 0px 40px",
                    }}
                  >
                    Amount(A)
                  </div>
                </Col>
              </Row>

              <Form.Label>Discount</Form.Label>
              <Form.Control
                ref={inputRef}
                name="price"
                value={updatepric.price}
                onChange={handelprice}
                style={{ width: "88%" }}
                placeholder="0"
              ></Form.Control>
            </Form>
            <Button
              className="text-dark"
              onClick={() => {
                onHide();
              }}
              variant="none"
              style={{
                height: "45px",
                width: "218px",
                border: "solid grey 1px",
                margin: "245px 0px 0px  0px",
              }}
            >
              Cancel{" "}
              <Button
                className="text-dark"
                style={{
                  border: "solid grey 1px",
                  margin: " 0px 0px 0px 90px ",
                  height: "30px",
                  width: "50px",
                }}
                variant="none"
              >
                {" "}
                <Link
                  to={"/scan"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {" "}
                  Esc
                </Link>
              </Button>
            </Button>
            <Button
              className="text-White"
              onClick={(e) => {
                HadleSubmit(e);
              }}
              style={{
                backgroundColor: "#2E45AF",
                height: "45px",
                width: "212px",
                border: "solid grey 1px",
                margin: "245px 0px 0px  17px",
              }}
            >
              Next{" "}
              <Button
                className="text-white"
                style={{
                  backgroundColor: "#2E45AF",
                  border: "solid grey 1px",
                  height: "30px",
                  width: "60px",
                }}
              >
                <Link
                  to={"/scan"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Enter{" "}
                </Link>
              </Button>
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    {/* <----------------PRICE OVERRIDE MODAL--------------------> */}
    <Modal show={pricemodal} onHide={() => setPricemoadl(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Check Manger</Modal.Title>
        </Modal.Header>
            <Modal.Body>
              <Form>
                <Row style={{ margin: "40px 0px 0px -10px" }}>
                  <Col>
                    <Form.Control
                      ref={ManagerFocus}
                      type="text"
                      onChange={(e) => setUserid(e.target.value)}
                      style={{ width: "88%" }}
                      placeholder="Enter User Id"
                      autoComplete="off"
                    ></Form.Control>
                  </Col>
                  <Col>
                    <Form.Control
                      autoComplete="off"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      style={{ width: "88%" }}
                      placeholder="Enter Password"
                    ></Form.Control>
                  </Col>
                </Row>

                {/* <Form.Label>Discount(%)</Form.Label>
                  <Form.Control style={{width:"88%"}} placeholder="0"></Form.Control> */}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setPricemoadl(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={CheckManger}>
                Save Changes
              </Button>
            </Modal.Footer>
        
      </Modal>
    </>
  );
}

export default AmountOfDiscountModal;
