import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { BiSearchAlt2 } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataService from "../../services/requestApi";
import moment from "moment";
function LoylityCardIssueReport(props) {

  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const {show} = props;
  const [loylityissue, setLoylityissue]= useState('')
  const GetLoylitiIssue=async()=>{
    try {
      const response =await DataService.GetLoyalityIssuesReport(saasId,storeId,date)
      console.log("Get loylity issue", response.data)
      setLoylityissue(response.data)
  
  
    } catch (error) {
      console.log("error", error)
    }
  
  }

  useEffect(() => {
    if(date && show){
      GetLoylitiIssue()
    }
  }, [show, date])



    const columns = [
        {
          name: "MBR Date",
          selector: (row) => row.createdAt,
          sortable: true
        },
        {
          name: "LC NUMBER", 
          selector:(row)=>row.cardNumber,
          sortable: true
        },
        {
          name: "CUST NAME",
          selector:(row)=>row.name,
          sortable: true,
        },
        {
          name: 'GENDER',
          selector: (row) => row.gender ,
          sortable: true
        },
        {
          name:  'MOBILE NO',
          selector: (row) =>row.mobileNumber,
          sortable: true
        },
        {
          name:  'EMAIL',
          selector: (row) =>row.email,
          sortable: true
        },
        {
          name:  'DOB',
          selector: (row) => row.dob,
          sortable: true
        },
        {
            name:  'CITY',
            selector: (row) =>row.address1,
            sortable: true
          },
          {
            name:  'AREA',
            selector: (row) => row.address2,
            sortable: true
          },
          {
            name:  'STAFF ID',
            selector: (row) =>row.address3,
            sortable: true
          },
          {
            name:  'STAFF NAME',
            selector: (row) => row.salesManName,
            sortable: true
          },
      ];
  return (
    <Modal
      {...props}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ paddingLeft: "15px" }}
      centered
    >
          <Modal.Header className="container" closeButton>
        <Modal.Title>Loylity Card Issue Report</Modal.Title>
      </Modal.Header>
                    <Modal.Body>
                
                    <Form.Control
          type="date"
          className='w-50'
          id="exampleColorInput"
          defaultValue="#563d7c"
          title="Choose your color"
          value={date}
          onChange={(e)=>{setDate(e.target.value)}}
        />

                    <DataTable
        
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          columns={columns}
          responsive={true}
          fixedHeader={true}
       
          data={loylityissue}
          // progressPending={loading}
          pagination
          paginationServer
    
        />

                 </Modal.Body>
    </Modal>
  );
}

export default LoylityCardIssueReport;
