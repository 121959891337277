import moment from 'moment';
import DataService from '../services/requestApi'
const sendCommand = async (command, selectedPort) => {
  console.error(command);

  if (selectedPort) {
    let writer;
    
    try {
      writer = await selectedPort.writable.getWriter();
      const encoder = new TextEncoder();

      await writer.write(encoder.encode(command));
      console.log('Command sent successfully:', command);

    } catch (error) {
      console.error('Error writing to serial port:', error);
    } finally {
      if (writer) {
        writer.releaseLock();
      }
    }
  } else {
    console.error('Serial port is not open.');
  }
};

const ClearDisplay = async (port) => {
  try {
    if (port && port.writable) {
      const writer = port.writable.getWriter();

      if (writer) {
        const encoder = new TextEncoder();
        await writer.write(encoder.encode('\x0C')); // ASCII control character for clear screen (Form Feed)
        console.log('Display cleared successfully.');

        // Release the writer after writing
        writer.releaseLock();
      } else {
        console.error('Writer is null or undefined.');
      }
    } else {
      console.error('Port or port.writable is null or undefined.');
    }
  } catch (error) {
    console.error('Error clearing display:', error);
  }
};

const CreateCoupon =async (total,id,noOfDays)=>{
  try {
    const { saasId, storeId } = JSON.parse(
      localStorage.getItem("User_data")
    );
    
    const Data = {
        "coupon_name": "Spring Sale",
        "store_id": storeId,
        "saas_id": saasId,
        "effective_from": moment(new Date()).format("YYYY-MM-DD"),
        "description": "Get amazing discounts on all products during our Spring Sale.",
        "active": true,
        "max_discount": 100.00,
        "min_purchased_amount": Math.round(total/10),
        "loyalty_id":id
    }
    const Response = await DataService.CreatCouponByThreshold(total,Data)
    if(Response.data.status){
      return Response.data.data
    }

  } catch (error) {
    console.log(error)
  }
}




  export {sendCommand,ClearDisplay,CreateCoupon}