import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Col from "react-bootstrap/Col";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Form, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import DataService from "./services/requestApi";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { useReactToPrint } from "react-to-print";
import { ModelOnEndOfDay } from "./UiComponents/ModelOnEndOfDay";
// import Table from 'react-bootstrap/Table';
export const Endofday = () => {
  const [enableNextButton, setEnableNextButton] = useState(false);
  const [registerData, setRegisterData] = useState();
  const [tillData, setTillData] = useState();
  const [storeData, setStoreData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [data, setdata] = useState("");
  const [discount, setDiscount] = useState("");
  const [netSales, setNetsales] = useState("");
  const navigate = useNavigate();
  const { saasId, storeId, userType ,userName,storeName,businessName} = JSON.parse(
    localStorage.getItem("User_data")
  );
  const { haderLine1 ,haderLine2} =
    JSON.parse(localStorage.getItem("Store_data"));
  const business_date =
    JSON.parse(localStorage.getItem("business_date"));
  const [date, setDate] = useState(business_date?business_date:moment(new Date()).format("YYYY-MM-DD"));
  const GetTodayData = async () => {
    try {
      const response = await DataService.EndOftheDay(storeId, saasId, date);
      console.log(response.data.data)
      setdata(response.data.data);
      const discount = response.data.data?.gross - response.data.data?.net_sales 
      setDiscount(discount)
      const Cal = response.data.data.total_retuns + discount
      const NetSales = response.data.data?.gross - Cal
      setNetsales(NetSales)
    } catch (error) {
      console.log(error)
    }
  };
  
  
  const NextRef = useRef()

    
  const handleGetRegisterIdAndTillId = async () => {
    try {
      const response = await DataService.GetAllOpenRegisterAndtill(saasId,storeId,date)
      console.log(response.data.data)
      if (
        response.data.data.register_response?.length>0 ||
        response.data.data.till_response?.length>0
      ) {
        console.log("first",response.data.data.register_response?.length>0)
        setEnableNextButton(true);
      } else {
        console.log("Second" ,response.data.data.register_response?.length>0)
        setEnableNextButton(false);
      }
      setRegisterData(response.data.data?.register_response);
      setTillData(response.data.data?.till_response);
      setStoreData(response.data.data?.store_status)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetTodayData();
    handleGetRegisterIdAndTillId()
  }, [date]);

  const handleNextButton = () => {
    if (enableNextButton) {
      Swal.fire({
        title: "Some Till Id and Register id is Still Open",
        icon: "info",
      });
    } else {
      EndforTheDay();
    }
  };

  const EndforTheDay = async () => {
    try {
      const response = await DataService.EndFotheday(date, storeId, saasId);
      console.log("end of the day", response);
      if (response.data.status) {
       handlePrint()
      }
    } catch (error) {
      console.log("error in catch", error);
      Swal.fire({
        title: error.response.data.message,
        icon: "error",
        
      });
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      // Add any logic you need before printing
      console.log("printing Start");
    },
    onAfterPrint: () => {
      // Add any logic you need after printing
      console.log("printing End");
      Swal.fire({
        text: "Store Closed Successfully!",
        icon: "success", 
        confirmButtonText: "Ok",
      }).then(async (result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            navigate("/login");
          
          }})
    },
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if(event.key === "Escape"){
        navigate("/")
      }
      if (event.key === "p" || event.key === "P") {
        handleNextButton();
      }
      // if(event.key === "n" || event.key === "N"){
      //   handleNextButton()
      // }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handlePrint]);
  useEffect(() => {
    if (userType !== "MANAGER") {
      navigate("/");
      Swal.fire({
        title: "Only Manger Can Open Store",
        icon: "info",
        timer: 1000,
      });
    }
  }, [userType]);

  const handelOnchange = (e) => {
    setDate(e.target.value);
  };

  useEffect(() => {
    NextRef.current.focus()
  }, [])
  const customLineStyle = {
    border: '1px solid black',
    margin: '8px 0',
  };

  return (
    <div>
      <Navbar className="justify-content-Between">
        <Container>
          <Navbar.Collapse className="justify-content-center">
            <Navbar.Brand>Photon Softwares</Navbar.Brand>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <hr />

      <Container>
        <div className="py-3 d-flex">
          <Link to={"/"} className="text-dark">
            <AiOutlineArrowLeft className="fs-4" />
          </Link>
          <h5 className="mx-5">End of day</h5>
          <Button onClick={() => setOpenModal(!openModal)}>Check Regiesters</Button>
        </div>

        <h6 className="my-4">Business Date</h6>
        <Form.Control
          onChange={handelOnchange}
          value={date}
          type="date"
        ></Form.Control>
        <Row>
          <Col md="12" ref={componentRef}>
            <div>
              <div className='d-flex justify-content-center fw-bold'>{storeName}</div>
              <div className='d-flex justify-content-center fw-bold'>{haderLine1}</div>
              <div className='d-flex justify-content-center fw-bold'>{haderLine2}</div>
              <div className='d-flex justify-content-center fw-bold mt-1'>
               <span className="px-3">Store ID:  {storeId}</span><span className="px-3"> Business date: {date}</span></div>
               <div className='d-flex justify-content-center mt-1  fw-bold' >Manager ID:{userName}</div>
               <div className='d-flex justify-content-center mt-1  fw-bold' >Name:{businessName}</div>
               <div className='d-flex justify-content-center mt-1  fw-bold' >DAY END SUMMARY REPORT
</div>
<div className='fw-bold mt-3' style={customLineStyle}></div>

              <div className='container  d-flex justify-content-between fw-bold text-nowrap px-4'>
  <p>Gross Sales</p>
  <p>{data?.gross}</p>
</div>
<div className='container d-flex justify-content-between fw-bold text-nowrap px-4'>
  <p>Discounts</p>
  <p>{discount}</p>
</div>
{/* <div className='container d-flex justify-content-around fw-bold text-nowrap'>
  <p>Total Sales</p>
  <p>{data?.gross}</p>
</div> */}
  <div  className='container d-flex justify-content-between fw-bold text-nowrap px-4'>
    <p>Net Return</p>
    <p>{data?.total_retuns}</p>
  </div>
  <div  className='container d-flex justify-content-between fw-bold text-nowrap px-4'>
    <p>Net Sales</p>
    <p>{netSales}</p>
  </div>


            {/* {data?.net_sales &&<div className='d-flex justify-content-around fw-bold'>
            <p>Net Sales </p>
            <p>{data?.net_sales}</p>
          </div>} */}
            {/* <div className='container d-flex justify-content-around fw-bold'>
            <p>PHYSICAL CASH</p>
          </div>
          {data.amount_in_iqd?.map((el) => {
      return (
        <>
        <span className=' px-2 d-flex justify-content-between fw-bold'>
        <p>IQD 250</p>
          <p>x</p>
          <p>{el?.iqd_250}</p><span>= {data?.denomination_iqd[0].iqd_250}</span>
        </span>
        <span className=' px-2 d-flex justify-content-between fw-bold'>
        <p>IQD 500</p>
          <p>x</p>
          <p>{el?.iqd_500}</p><span>= {data?.denomination_iqd[0].iqd_500}</span>
        </span>
        <span className=' px-2 d-flex justify-content-between fw-bold'>
        <p>IQD 1000</p>
          <p>x</p>
          <p>{el?.iqd_1000}</p><span>= {data?.denomination_iqd[0].iqd_1000}</span>
        </span>
        <span className=' px-2 d-flex justify-content-between fw-bold'>
        <p>IQD 5000</p>
          <p>x</p>
          <p>{el?.iqd_5000}</p><span>= {data?.denomination_iqd[0].iqd_5000}</span>
        </span>
        <span className=' px-2 d-flex justify-content-between fw-bold'>
        <p>IQD 25000</p>
          <p>x</p>
          <p>{el?.iqd_25000}</p><span>= {data?.denomination_iqd[0].iqd_25000}</span>
        </span>
          </>
      );
    })} */}
          {/* <div className='container  d-flex justify-content-between fw-bold px-4'>
            <p>US DOLLAR </p>
            <p>{data?.total_usd}</p>
          </div> */}
          <div className='container d-flex justify-content-center fw-bold px-4'>
            <p>SETTLEMENT DETAILS</p>
          </div>
          {data.settlement_details?.map((el) => {
      return (
        <div  className='container d-flex justify-content-between fw-bold px-4' key={el.tender_name}>
          <p>{el.tender_name}</p>
          <p >{el?.sum_amount}</p>
        </div>
      );
    })}
    {/* <div className='d-flex justify-content-around fw-bold'>
            <p>Net Settlement</p>
            {/* <p>{data?.net_sales}</p> */}
          {/* </div> } */}
    {/* <div className='d-flex justify-content-around fw-bold fs-3'>
            <p>Item Retrun List</p>
          </div>

          <Table striped bordered hover>

      <thead>
        <tr>
          <th>Item Qty</th>
          <th>Name</th>
          <th>Discount</th>
        </tr>
      </thead>
      <tbody>
        {data?.list_of_returns?.map((el)=>{return(
          <tr>
          <td>{el.total_item_qty}</td>
          <td>{el.item_name}</td>
          <td>{el.item_discount}</td>
        </tr>
        )})}
       
      </tbody>
    </Table> */}

    {/* <div className='d-flex justify-content-around fw-bold fs-3'>
            <p>Item Discount List</p>
          </div>

          <Table striped bordered hover>

      <thead>
        <tr>
          <th>Item Id</th>
          <th>Name</th>
          <th>Discount</th>
        </tr>
      </thead>
      <tbody>
        {data?.list_of_discounts?.map((el)=>{return(
          <tr>
          <td>{el.item_id}</td>
          <td>{el.item_name}</td>
          <td>{el.item_discount}</td>
        </tr>
        )})}
       
      </tbody>
    </Table> */}
 
           




            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <center>
              <div className="my-5">
                <Button
                  className="text-White  border  mx-2"
                  variant="none"
                  style={{ width: "250px" }}
                >
                  <span className="float-start my-2">Cancel</span>{" "}
                  <Button
                    className=" border float-end text-dark"
                    variant="none"
                  >
                    Esc
                  </Button>
                </Button>
                {/* <Button
                  className="text-White  border  mx-2"
                  variant="none"
                  style={{ width: "250px" }}
                >
                  <span className="float-start my-2">Undo</span>{" "}
                  <Button
                    className=" border float-end text-dark"
                    variant="none"
                  >
                    Ctlr+z
                  </Button>
                </Button> */}
                {/* <Button
                 ref={NextRef}
                  onClick={handleNextButton}
                  className="text-White  border  mx-2"
                  variant="primary"
                  style={{ backgroundColor: "#2E45AF", width: "250px" }}
                >
                  <span className="float-start my-2">Next ( N )</span>{" "}
                  <Button
                    className=" border float-end text-white"
                    style={{ backgroundColor: "#2E45AF" }}
                  >
                    <Link
                      to={"/"}
                      style={{
                        backgroundColor: "#2E45AF",
                        textDecoration: "none",
                        color: "white",
                      }}
                    >
                      Enter
                    </Link>
                  </Button>
                </Button> */}
                <Button
                  onClick={handleNextButton}
                  ref={NextRef}
                  className="text-White  border  mx-2"
                  variant="primary"
                  style={{ backgroundColor: "#2E45AF", width: "250px" }}
                >
                  <span className="float-start my-2">Print ( p )</span>{" "}
                  {/* <Button
                    className=" border float-end text-white"
                    style={{ backgroundColor: "#2E45AF" }}
                  >
                    <Link
                      to={""}
                      style={{
                        backgroundColor: "#2E45AF",
                        textDecoration: "none",
                        color: "white",
                      }}
                    >
                      Enter
                    </Link>
                  </Button> */}
                </Button>
              </div>
            </center>
          </Col>
        </Row>
      </Container>
      <ModelOnEndOfDay
        show={openModal}
        setOpenModal={setOpenModal}
        registerData={registerData}
        tillData={tillData}
        storeData={storeData}
      />
    </div>
  );
};
