import React, { useState, useEffect } from 'react'


import { AiOutlineArrowLeft } from 'react-icons/ai'

import { PiCrownBold } from "react-icons/pi"
import { Link } from 'react-router-dom'
import CustomNavbar from './UiComponents/CustomNavbar';

export const Accountverify = () => {
    return (

        <div style={{ height: "fit-content", width:"100%"}}>
       <CustomNavbar/>
            <hr />
            <Link to={'/enrollment'} style={{ color: "black", margin: "20px 0px 0px 50px" }}> < AiOutlineArrowLeft style={{ width: "30px", height: "20px" }} /></Link>
            <div className='d-flex justify-content-center' style={{marginLeft: "-90px"}}>
            <div className='p-1'>
                <PiCrownBold style={{ margin: "0px 0px 0px 270px", height: "40px", width: "40px" }} />
                <h5 style={{ margin: "30px 0px 0px 210px" }}>Verify your account</h5>
                <p style={{ margin: "20px 0px 0px 140px" }}> Enter the verication code we've sent to ********43</p>
                <div style={{ border: "solid grey 1px", borderRadius: "5px", height: "50px", width: "45px", margin: "30px 0px 0px 180px" }}>

                </div>
                <div style={{ border: "solid grey 1px", borderRadius: "5px", height: "50px", width: "45px", margin: "-50px 0px 0px 260px" }}>

                </div>
                <div style={{ border: "solid grey 1px", borderRadius: "5px", height: "50px", width: "45px", margin: "-50px 0px 0px 340px" }}>

                </div>
                <div style={{ border: "solid grey 1px", borderRadius: "5px", height: "50px", width: "45px", margin: "-50px 0px 0px 420px" }}>

                </div>

                <p style={{  margin: "30px 0px 0px 165px" }}>Didn't receive the code yet? <Link to={'/enrollform'} style={{textDecoration:"none"}}> Resend the code</Link> </p>

            </div>
            </div>
        </div>
    )
}