import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { MdDelete,MdEdit, MdPlaylistAdd } from "react-icons/md";
import DataTable from "react-data-table-component";
import DataService from '../../services/requestApi'
import DepartMentUpdateModal from './MangeAllModal/DepartMentUpdateModal';
import AddDeptModal from './MangeAllModal/AddDeptModal';
import Swal from 'sweetalert2';
function Department({data, GetDeptMaster}) {
  
   //Update Concept Functionlity
   const [OpenUpdate, setOpenUpdate] = useState(false)
   const [AddDept, setAddDept] = useState(false)
   const [SelectedRow, setSelectedRow] = useState("")
  
   const DeleteDept =async(id)=>{
    try {
      const response = await DataService.DeleteDepartment(id)
      if(response.data.status){
        GetDeptMaster()
        Swal.fire({
          title:"Department Delted Successfully",
          icon:"success",
          timer:2000
        })
      }
    } catch (error) {
      console.log(error)
    }
   }

    const style={
        boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        borderRadius:"4px",
        background:"white",
        color:"black",
        border:"0px"
      }
      const columns = [
        {
          name: "Concept code",
          center: true,
          selector: (row) => row.dept_code,
        },
        {
          name: "Concept Name",
          // center: true,
          selector: (row) => row.conc_id,
          cell: (row) => {
            return (
              <>
                <div >{row.conc_id}</div>
              </>
            );
          },
        },
        {
          name: "Department code",
          center: true,
          selector: (row) => row.dept_code,
        },
     
        
        {
          name: "Department Name",
          center: true,
          selector: (row) => row.dept_name,
        },
     
      
        // {
        //     name: 'Department Id',
        //     center: true,
        //     selector: row => row.dept_id,
        // },
        // {
        //   name: "Price",
        //   center: true,
        //   selector: (row) => row.price,
        // },
     
        // {
        //   name: "Tax",
        //   center: true,
        //   selector: (row) => row.tax,
        // },
        // {
        //     name: 'Tax Code',
        //     center: true,
        //     selector: row => row.tax_code,
        // },
        // {
        //     name: 'Tax Percent',
        //     center: true,
        //     selector: row => row.tax_percent,
        // },
        // {
        //     name: 'Tax Rate',
        //     center: true,
        //     selector: row => row.tax_rate,
        // },
        {
          name: "Action",
          center: true,
          selector: (row) => {
            // const [addUpdateItemModalIsOpen, setAddUpdateItemModalIsOpen] =
            //   useState(false);
            // const handleDelete = async () => {
            //   const response = await fetch(
            //     `${host}item/inactive-item/${row.item_id}/${saasId}`,
            //     {
            //       method: "PUT",
            //     }
            //   );
            //   const jsonData = await response.json();
            //   if (jsonData) {
            //     if (jsonData.status === true) {
            //       toast.success(jsonData.message);
            //       setFlag(!flag);
            //       return;
            //     }
            //     /* toast.error(jsonData.message);
            //       setFlag(!flag); */
            //   } /*  else {
            //       toast.error("Something went wrong server side");
            //     } */
            // }; /* catch (err) {
            //     toast.error(err.message);
            //   } */
    
    
            return (
              <>
                <div className="d-flex">
                <div style={{cursor:'pointer'}}>
                    <MdPlaylistAdd
                      size={22}
                      color="green"
                      className="mouse-pointer"
                      onClick={() => setAddDept(true)}
                    />
                  </div>
    
                  <div style={{cursor:'pointer'}}>
                    <MdDelete
                      size={22}
                      color="red"
                      className="mouse-pointer"
                      onClick={() => DeleteDept(row.dept_id)}
                    />
                  </div>
    
                  <div style={{cursor:'pointer'}}>
                    <MdEdit
                      size={22}
                      color="var(--primary1)"
                      className="mouse-pointer"
                      onClick={() => {
                        setOpenUpdate(true)
                        setSelectedRow(row)
                      }}
                    />
                  </div>
                </div>
    
                {/* <AddItem
                  addUpdateItemModalIsOpen={addUpdateItemModalIsOpen}
                  setAddUpdateItemModalIsOpen={setAddUpdateItemModalIsOpen}
                  row={row}
                  setFlag={setFlag}
                  flag={flag}
                /> */}
              </>
            );
          },
        },
      ];
  return (
    <div>  <Container>
      <DepartMentUpdateModal open={OpenUpdate} GetDeptMaster={GetDeptMaster} setOpenUpdate={setOpenUpdate} row ={SelectedRow}/>
      <AddDeptModal open={AddDept} GetDeptMaster={GetDeptMaster} setAddDept={setAddDept} />

    {/* <Row>
      <Col>
      <div>
        <img
        className=' rounded-4'
         style={{
            background:"lightgray 50% / cover no-repeat",
            boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
            width: "95%",
            height: "350px",
        }}  src={`https://s3-alpha-sig.figma.com/img/6703/97f4/abad1d7c6343c6be003f2afc5465173a?Expires=1702857600&Signature=SPTu3SZinUd0xAxQTtpn46tsNMe1Q0VC-g2Hkg6Dmw1HQjp2V6rWZOZKF3xuttvbI2wwd2vFqmmnsxMw8KT~MPlWphOlGWAUWMayR2FeJJPYmGthh8U7Rb590Of9m-O-~1rbOoEoaB1OhOYcS6fJXXHZgbdiL4Tc4ReOdX6llFN-1oi9co2nr0xOjVk8xmYTxkyvtdtX52xD-SPgLH1QHJaDNM19QSC3kn0h~wzjZ-1y1Tq5XGcgEbyaSNZ0pNffM3pzvdz2l9R43RUpqwtlxxv5PaDu5HQwZ8ZJ6y5s9ffY7Fv071DAFaej5JWCOCIkRbeOYXpa-0d5WHmROuo1HA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4`} alt="" />
      </div>
      </Col>
      <Col>
    
      <Button
      style={style}
      className='w-100' variant="success" id="dropdown-basic">
       Concept Code
      </Button>
      <Button
      style={style}
      className='w-100 mt-3' variant="success" id="dropdown-basic">
        Dept. Code
      </Button>
      <Button
      style={style}
      className='w-100 mt-3' variant="success" id="dropdown-basic">
       Class Code
      </Button>
      <Button
      style={style}
      className='w-100 mt-3' variant="success" id="dropdown-basic">
       Subclass Code
      </Button>
      <Button
      style={style}
      className='w-100 mt-3' variant="success" id="dropdown-basic">
       Item Cod
      </Button>
    </Col>
    </Row> */}
    <DataTable
        columns={columns}
        responsive={true}
        fixedHeader={true}
        // fixedHeaderScrollHeight="300px"

        data={data}
        // progressPending={loading}
        pagination
        paginationServer
        // paginationTotalRows={item_master_list ? item_master_list.totalCount : 1}
        // onChangeRowsPerPage={10}
        // onChangePage={handlePageChange}
      />
  </Container></div>
  )
}

export default Department