import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import DataService from "../services/requestApi";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { HandelSalesman } from "../Redux/Reducer";

function LinkSalesmanModal({ SalesMOdal, setSalesMOdal,getFocus }) {
  const dispatch = useDispatch();
  const [salsemanId, setSalsemanId] = useState("");
  const [data, setData] = useState("");
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const inputRef = useRef();

  useEffect(() => {
    if (SalesMOdal) {
      inputRef.current.focus();
    }
  }, [SalesMOdal]);

  const GetSalesman = async (e) => {
    e.preventDefault();
    try {
      const response = await DataService.GetSalesmane(
        saasId,
        storeId,
        salsemanId
      );
      console.log("this salse mans", response.data.data);
      setData(response.data.data);
      dispatch(HandelSalesman(response.data.data));
      setSalesMOdal(false);
      setTimeout(() => {
        getFocus()
      }, 500);
    } catch (error) {
      console.log("this catch error", error);
      inputRef.current.focus();
      Swal.fire({
        title: "Sales Man Not Found",
        icon: "error",
      });
    }
  };

  return (
    <>
      <Modal show={SalesMOdal} onHide={() => setSalesMOdal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Link Salesman</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0.3rem" }}>
          <div>
            <Form
              onSubmit={(e) => {
                GetSalesman(e);
              }}
            >
              <Form.Control
                onChange={(e) => {
                  setSalsemanId(e.target.value);
                }}
                className="mt-1"
                type="number"
                placeholder="Enter Sales Man Id"
                ref={inputRef}
              />
            </Form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "1em",
                fontWeight: "bold",
                padding: "1rem",
              }}
            >
              <span>{data.sales_man_name}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setSalesMOdal(false);
            }}
          >
            Close
          </Button>
          <Button
            onClick={(e) => {
              GetSalesman(e);
            }}
            variant="primary"
            className="mt-2"
          >
            Link Salesman
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LinkSalesmanModal;
