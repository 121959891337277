import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { AiOutlineMenu } from "react-icons/ai";
import SidebarModal from "./SidebarModal";
import { useLocation, useNavigate } from "react-router-dom";
import CheckPriceModal from "./CheckPriceModal";
import { Col } from "react-bootstrap";
import { IoIosLogOut } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { ApplyDiscountOnTotal, HandelLinkCustomer, RemoveCart, RemoveInovice, RemoveLoyltiyuser, openPriceCheck, openPriceCheckModel } from "../Redux/Reducer";
const CustomNavbar = ({ onMenuButtonClick ,customermodalisOpen}) => {
  const [showModal, setShowModal] = useState(false);
  const { openPriceCheckModel } = useSelector((state) => state.Reducer);

  const [modalRegister, setModalRegister] = React.useState(false);
  const [registerClose, setRegisterClose] = React.useState(false);
  const [modalCheckPrice, setModalCheckPrice] = useState(false);
  let location = useLocation();
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const { storeName ,storeId,address} = JSON.parse(localStorage.getItem("Store_data"));
  const business_date = JSON.parse(localStorage.getItem("business_date"));
  const { userName} = JSON.parse(localStorage.getItem("User_data"));

  const handleLogout = () => {
    localStorage.clear();
    naviagte("/login");
              dispatch(RemoveCart());
              dispatch(ApplyDiscountOnTotal(0));
              dispatch(RemoveLoyltiyuser());
              dispatch(HandelLinkCustomer([]));
              dispatch(RemoveInovice());
  };

  const handleKeyPress = (e) => {
    if(customermodalisOpen){
      return
    }
    if (e.keyCode === 80 && e.shiftKey) {
      // 80 is the key code for p
      if (location.pathname == "/" || location.pathname == "/scan") {
        e.preventDefault();
        dispatch(openPriceCheck(true));
      }
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [customermodalisOpen]);

  return (
    <>
      <Navbar
        style={{
          height: "3.5rem",
        }}
      >
        <Navbar.Brand>NanoPos {storeId} - {userName}</Navbar.Brand>
        <Navbar.Collapse className="justify-content-center">
        <div className="justify-content-center my-2 py-3">
          <h4 className="text-center">{storeName}</h4> 
          <h6>{address}- {business_date}</h6>
        </div>
        </Navbar.Collapse>

        {location.pathname == "/" && (
          <>
            <span
              className="fs-3"
              style={{ cursor: "pointer" }}
              onClick={handleLogout}
            >
              <IoIosLogOut />
            </span>

            <Col md={2} className="float-end my-3">
              <center>
                <div>
                  <Button
                    onClick={() => dispatch(openPriceCheck(true))}
                    className="text-dark"
                    variant="none"
                    style={{
                      height: "50px",
                      width: "180px",
                      border: "solid grey 1px",
                    }}
                  >
                    Price Check (shift+P)
                    {/* <Button className="text-dark" style={{ border: "solid grey 1px" }} variant="none"> </Button> */}
                  </Button>
                </div>
              </center>
            </Col>
            <Button
              style={{ backgroundColor: "#2E45AF" }}
              variant="primary"
              onClick={onMenuButtonClick}
              className="text-white"
            >
              <AiOutlineMenu />
              Daily Operations
            </Button>
          </>
        )}
      </Navbar>
      <SidebarModal
        showModal={showModal}
        toggleModal={toggleModal}
        setModalRegister={setModalRegister}
        setRegisterClose={() => setRegisterClose(true)}
      />
      <CheckPriceModal
        show={openPriceCheckModel}
        onHide={() => dispatch(openPriceCheck(false))}
      />
    </>
  );
};

export default CustomNavbar;
